import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useContext } from "react";
import { tenderManagementError } from "../../../apiRequests/errorMessages";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { endpoint } from "../../../endpoint/endpoints";
import { sentechDarkGrey } from "../../../theme/colors";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: "90%",
      margin: "auto",
      textAlign: "left",
      paddingTop: "30px",
      // paddingLeft: "210px;",
      //   paddingLeft: "210px;",
      // height: "120px",
      justifyContent: "center",
    },
    typography: {
      textAlign: "left",
      paddingBottom: "20px",
      color: sentechDarkGrey,
    },
    paperbox: {
      margin: "auto",
      textAlign: "center",
      justifyContent: "center",
      paddingBottom: "20px",
    },
  })
);

export default function BSECResponses({ messages, onUpdate }) {
  const classes = useStyles();

  const { setMessage, openSnackbar, setSeverity } =
    useContext(ApplicationContext);

  const handleMessageUpdate = async (id, status) => {
    try {
      const response = await axios.post(
        endpoint.UPDATE_MESSAGE, //endpoint
        { message_id: id, status: status },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          onUpdate(id, status);
          window.location.reload(true);
          // popMyTenders(data);
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
        }
      } else {
      }
      // console.log(response);
    } catch (error) {}
  };

  return (
    <Box className={classes.box}>
      {messages.length > 0 ? (
        <Typography variant="body1" className={classes.typography}>
          BSEC member's responses
        </Typography>
      ) : null}

      {messages.map((itm) => (
        <Paper className={classes.paperbox} key={itm.id}>
          <ul style={{ color: sentechDarkGrey }}>
            {itm.message}.
            <div style={{ paddingBottom: "5px" }} />
            <CustomButtonOutlined
              type={"text"}
              text="Decline"
              onClick={() => {
                handleMessageUpdate(itm.id, "declined");
              }}
            />
            <CustomButtonSolid
              type={"text"}
              text="Accept"
              onClick={() => {
                handleMessageUpdate(itm.id, "accepted");
              }}
            />
          </ul>
        </Paper>
      ))}
    </Box>
  );
}
