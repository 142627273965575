import { createStyles, List, makeStyles } from "@material-ui/core";
import FileCard from "./fileCard";
import { useEffect, useState } from "react";

// style box
const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: "94%",
      margin: "auto",
      textAlign: "left",
      paddingTop: "30px",
      paddingLeft: "210px;",
    },
  })
);

export default function FileList({
  fileList,
  uploadList,
  deletable,
  onDelete,
  closed
}) {
  const classes = useStyles();

  const [uploadListState, setUploadListState] = useState(uploadList);

  useEffect(() => {
    setUploadListState(uploadList);
  }, [uploadList]);

  if (!fileList && !uploadList) return;

  // create a set of all file keys in fileList
  const fileKeys = new Set(fileList?.map((item) => item.key));

  return (
    <List className={classes.box}>
      {fileList?.map((item) => {
        return (
          <FileCard
            key={item.key}
            fileKey={item.key}
            filename={item.key.split("-").pop()}
            filetype={item.type}
            deletable={deletable ?? item.bucket ? true : false}
            closed={closed}
            onDelete={() => {
              onDelete(item);
            }}
            isNew={false} // set isNew to false for existing files
            // fileSize={item.size}
            // loadingState={item.loadingState}
          />
        );
      })}
      {uploadList?.map((item) => {
        // const isNew = item.file && !fileKeys.has(item.file.name);
        if (!item || !item.file) {
          return null;
        }
        const isNew = !fileKeys.has(item.file.name); 
        return (
          <FileCard
            key={item.file.name}
            fileKey={item.file.name}
            filename={item.file.name}
            filetype={item.file.type}
            fileSize={item.file.size}
            loadingState={item.loadingState}
            isNew={isNew} // pass isNew prop to indicate new file
          />
        );
      })}
    </List>
  );
}
