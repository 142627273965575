import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import { endpoint } from "../../../endpoint/endpoints";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import TenderTable from "../../tenderTables/tenderTable";
import CustomPagination from "../../layout/pagination/pagination";
import { tenderErorr } from "../../../apiRequests/errorMessages";
import { Link } from "react-router-dom";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    table: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    notendersbox: {
      justifyContent: "center",
    },

    notendersheading: {
      paddingTop: "2%",
      textAlign: "center",
      color: sentechBlue,
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechBlue,
    },
  })
);

function NominatedTenderPage() {
  // material ui
  const classes = useStyles();

  const {
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    privateTenders,
    popPrivateTenders,
    categories,
    popCategories,
    setSeverity,
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    setLoading(false);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}

    try {
      const response = await axios.get(
        endpoint.LIST_PRIVATE_ENDPOINT, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message.resp_items;
          popPrivateTenders(
            data.filter((itm) => itm.close_datetime > Date.now() / 1000)
          );
          setLoading(false);
        } else {
          popPrivateTenders([]);
          setMessage(tenderErorr(response.data.errorcode));
          response.data.errorcode === 5
            ? setSeverity("info")
            : setSeverity("error");

          setLoading(false);
          openSnackbar();
        }
      } else {
        popPrivateTenders([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Nominated tenders";
  }, []);

  React.useEffect(() => {
    const refresh = setInterval(() =>
    handleOnPageLoad() 
    ,2820
    );
    return () => {
      clearInterval(refresh)
    };
  }, []);

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(privateTenders.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  function getTenders() {
    if (privateTenders.length) {
      return (
        <Box>
          <Box className={classes.table}>
            <TenderTable
              tableData={privateTenders}
              page={page}
              perPage={perPage}
              useCase={"bidderNominatedTenderList"}
            />
          </Box>
          <Box className={classes.paginations}>
            <CustomPagination onNewPage={onNewPage} count={maxPage} />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box className={classes.notendersbox}>
          <Typography className={classes.notendersheading}>
            You have not been invited to any Nominated Tenders
          </Typography>
        </Box>
      );
    }
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>NOMINATED TENDERS</Typography>
      </Box>
      {getTenders()}
    </Box>
  );
}

export default NominatedTenderPage;
