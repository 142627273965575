import React, { useState, useContext } from "react";
import { useNavigate } from "react-router";
import { Box, Typography, Button } from "@material-ui/core";
import Sidebar from "../../layout/sysadminSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import SentechUsersTable from "../../tenderTables/sysAdminsentechUsersTable";
import CustomPagination from "../../layout/pagination/pagination";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { userManagementError } from "../../../apiRequests/errorMessages";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    addbuttonbox: {
      paddingRight: "7.5%",
      display: "flex",
      justifyContent: "flex-end",
    },

    sentechuserstable: {
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
  })
);

function SentechUsers() {
  // material ui
  const classes = useStyles();

  const {
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    biddersList,
    popBiddersList,
    sentechUsersList,
    popSentechUsersList,
    setSeverity
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    // if (sentechUsersList.length) return;
    setLoading(true);
    try {
      const response = await axios.get(
        endpoint.ADMIN_LIST_USER_ENDPOINT, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message; //update
          popBiddersList(
            message.bidders.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              companyName: user.company_name,
              companyPhone: user.company_phone,
              companyMobile: user.company_mobile_phone ?? "",
              reg: user.reg_number,
              csd: user.csd_number,
              id: user.id,
              role: user.role[0],
            }))
          );
          popSentechUsersList(
            message.sentech_users.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              employeeRef: user.employee_ref,
              department: user.department,
              designation: user.designaion,
              role: user.role,
              id: user.id,
            }))
          );
          setLoading(false);
        } else {
          setMessage(userManagementError(response.data.errorCode));
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Sentech user";
  }, []);
  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // navigation
  const navigate = useNavigate();

  function handleClick() {
    navigate("/SENTECHUSERS/ADDSENTECHUSER");
  }

  // users table data
  const sentechUsersTableData = [
    {
      id: 1,
      employeeRef: "SENT0001234",
      firstName: "John",
      lastName: "Doe",
      email: "jdoe@gmail.com",
      department: "Admin",
      designation: "Manager",
      role: ["Sys Admin"],
    },
  ];

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(sentechUsersList.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>SENTECH USERS</Typography>
      </Box>
      <Box className={classes.addbuttonbox}>
        <CustomButtonSolid onClick={(e) => handleClick()} text="+ Add User" />
      </Box>
      <Box className={classes.sentechuserstable}>
        <SentechUsersTable
          tableData={sentechUsersList}
          page={page}
          perPage={perPage}
        />
      </Box>
      <Box className={classes.paginations}>
        <CustomPagination onNewPage={onNewPage} count={maxPage} />
      </Box>
    </Box>
  );
}

export default SentechUsers;
