import React from "react";
import Link from "../bsecSidebar/sideBarLink";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../../theme/colors";

// styling 
const useStyles = makeStyles((theme) =>
    createStyles({
      navbar: {
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "35%",
        paddingRight: "20%",
        paddingBottom: "2%",
        backgroundColor: "white",
        borderBottom: sentechDarkGrey,
        // "2px solid"
        webkitBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
        mozBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
        boxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
      },

      sidebar: {
        // width: "200px",
        // padding: "20px 20px",
        // margin: "10px auto 0 auto",
        // float: "left",
        // borderRadius: "20px",
      },

      profile: {
        textDecoration: "none", 
        color: sentechLightGrey, 
        paddingTop: "1%", 
        "&:hover": {
          textDecoration: "none",
          color: sentechBlue,
        },
      }
    })
);

function Sidebar(){

  // material ui 
  const classes = useStyles();

  return(
    <div className={classes.sidebar}>
        <nav className={classes.navbar}>
        <Link text="AVAILABLE TENDERS" path={"/AVAILABLETENDERS"}/>
        <Link text="CLOSED TENDERS" path={"/CLOSEDTENDERS"}/>
        {/* <Link text="MY PROFILE" path={"/PROFILE"}/> */}
        <a className={classes.profile} href="/PROFILE">MY PROFILE</a>
        </nav>
    </div>
  );
}

export default Sidebar;