import React, { useState, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import Sidebar from "../../layout/sysadminSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import CustomPagination from "../../layout/pagination/pagination";
import BiddersListTable from "../../tenderTables/sysAdminbiddersListTable";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechDarkGrey } from "../../../theme/colors";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { userManagementError } from "../../../apiRequests/errorMessages";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    bidderslisttable: {
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    notendersbox: {
      paddingLeft: "7.5%",
      display: "flex",
      justifyContent: "flex-start",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
  })
);

function BiddersList() {
  // material ui
  const classes = useStyles();

  const {
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    biddersList,
    popBiddersList,
    sentechUsersList,
    popSentechUsersList,
    setSeverity,
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    // if (biddersList.length) return;
    setLoading(true);
    try {
      const response = await axios.get(
        endpoint.ADMIN_LIST_USER_ENDPOINT, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message; //update
          popBiddersList(
            message.bidders.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              companyName: user.company_name,
              companyPhone: user.company_phone,
              companyMobile: user.company_mobile_phone ?? "",
              reg: user.reg_number,
              csd: user.csd_number,
              id: user.id,
              role: user.role[0],
            }))
          );
          popSentechUsersList(
            message.sentech_users.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              employeeRef: user.employee_ref,
              department: user.department,
              designation: user.designaion,
              role: user.role,
              id: user.id,
            }))
          );
          setLoading(false);
        } else {
          setMessage(userManagementError(response.data.errorCode));
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Bidders";
  }, []);
  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // data for table

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(biddersList.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  // function for empty bidders table
  function getBidders() {
    if (biddersList.length) {
      return (
        <Box>
          <Box className={classes.bidderslisttable}>
            <BiddersListTable
              tableData={biddersList}
              page={page}
              perPage={perPage}
            />
          </Box>
          <Box className={classes.paginations}>
            <CustomPagination onNewPage={onNewPage} count={maxPage} />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box className={classes.notendersbox}>
          <Typography style={{ color: sentechDarkGrey }}>
            There are no bidders registered.
          </Typography>
        </Box>
      );
    }
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>BIDDERS</Typography>
      </Box>

      <Box className="table">{getBidders()}</Box>
    </Box>
  );
}

export default BiddersList;
