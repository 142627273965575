import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Paper,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: "0%"
    },

    rowheadingtext: {
      // fontSize: "12px",
      color: "white",
      backgroundColor: sentechBlue,
      borderBottom: "none",
    },

    rowtext: {
      // fontSize: "10px",
      color: sentechDarkGrey.at,
      borderBottom: "none",
    },

    rowtextDark: {
      // fontSize: "10px",
      color: sentechDarkGrey,
      "&.MuiTableRow-root": { backgroundColor: "rgba(112, 111, 111, 0.06  )" },
      borderBottom: "none",
    },
  })
);

export default function BiddersListTable({ tableData, page, perPage }) {
  // material ui
  const classes = useStyles();

  // function for bidders table
  function currentData() {
    const begin = (page - 1) * perPage;
    const end = begin + perPage;
    return tableData.slice(begin, end);
  }
  const navigate = useNavigate();

  const handleClick = (info) => {
    navigate("/BIDDERS/VIEWBIDDER", { state: { ...info } });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: "max-content" }} aria-label="simple table">
          {/* table headings */}
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowheadingtext}>Sr.No.</TableCell>
              <TableCell className={classes.rowheadingtext}>Name</TableCell>
              <TableCell className={classes.rowheadingtext}>Surname</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Name
              </TableCell>
              <TableCell className={classes.rowheadingtext}>Email</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Tel. No.
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Mobile No.
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Reg. No.
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company CSD No.
              </TableCell>
            </TableRow>
          </TableHead>
          {/* table data */}
          <TableBody>
            {currentData().map((row,index) => (
              <TableRow
                className={index % 2 ? classes.rowtextDark : classes.rowtext}
                key={row["id"]}
                sx={{ "&:last-chld td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.rowtext}>{index + 1 + (page - 1) * perPage}</TableCell>
                <TableCell className={classes.rowtext}>{row.firstName}</TableCell>
                <TableCell className={classes.rowtext}>{row.lastName}</TableCell>
                <TableCell className={classes.rowtext}>
                  {row.companyName}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {" "}
                  <Link
                    style={{ color: sentechBlue }}
                    onClick={() => {
                      handleClick(row);
                    }}
                  >
                    {row.email}
                  </Link>{" "}
                </TableCell>
                {/* <TableCell className="rowtext">{row.email}</TableCell> */}
                <TableCell className={classes.rowtext}>
                  {row.companyPhone}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.companyMobile}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.reg}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.csd}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
