import { Box, Button, Paper, Typography } from "@material-ui/core";
import { MessageLeft, MessageRight } from "./Messages";
import { TextInput } from "./TextInput";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { textAlign } from "@mui/system";
import { sentechDarkGrey } from "../../../theme/colors";

// styles box and typography
const useStyles = makeStyles((theme) =>
  createStyles({
    box: { width: "94%", margin: "auto", textAlign: "left", paddingTop: "30px",
    paddingLeft: "210px;"
 },
    typography: {
      textAlign: "left",
      paddingBottom: "20px",
      color: sentechDarkGrey
    },
  })
);

function popMessages(messages) {
  // console.log(messages);

  return messages.map((el) => {
    // console.log(el);
    if (el.user === 1) {
      return <MessageRight messageKey={el.id} message={el.message} />;
    }
    return <MessageLeft key={el.id} message={el.message} />;
  });
}

export default function ViewTenderForum({ messages }) {

  const classes = useStyles();
  // tender discussion forum responses
  return (
    <Box className={classes.box}>
      <Typography 
      // variant="h5" 
      className={classes.typography}>
        TENDER DISCUSSION FORUM
      </Typography>
      <Typography variant="body1" className={classes.typography}>
        View and approve answers by BSEC members or type your own reply to questions posted by bidders.
      </Typography>
      <Paper style={{ overflowY: "scroll", height: "250px" }}>
        <ul>{popMessages(messages)}</ul>
      </Paper>
      <TextInput />
      <br/>
      <Typography variant="body1" className={classes.typography}>
        BSEC member's responses
      </Typography>
      <Paper style={{ height: "120px", textAlign: "left" }}>
        <ul style={{color: sentechDarkGrey}}>John Doe <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        </ul>
      </Paper>
      <br/>
      <Paper style={{ height: "120px", textAlign: "left" }}>
        <ul style={{color: sentechDarkGrey}}>John Doe <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        </ul>
      </Paper>
      <br/>
      <Paper style={{ height: "120px", textAlign: "left" }}>
        <ul style={{color: sentechDarkGrey}}>John Doe <br/> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
        </ul>
      </Paper>
    </Box>
  );
}
