import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Typography, Button } from "@material-ui/core";
import Sidebar from "../../layout/bsecSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import TenderTable from "../../tenderTables/tenderTable";
import BsecTenderForum from "../../forum/bsecForum/Forum";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import { endpoint } from "../../../endpoint/endpoints";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { useContext } from "react";
import {
  tenderErorr,
  tenderManagementError,
} from "../../../apiRequests/errorMessages";
import axios from "axios";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    table: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    tenderforum: {
      width: "93%",
      display: "flex",
      justifyContent: "flex-start",
    },

    backbuttonbox: {
      paddingTop: "3%",
      paddingBottom: "5%",
      display: "flex",
      justifyContent: "center",
    },
  })
);

function TenderDetails() {
  // material ui
  const classes = useStyles();

  const { setMessage, openSnackbar, setLoading, setSeverity } =
    useContext(ApplicationContext);

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Tender details";
  }, []);
  const webSocket = React.useRef(null);

  const [webSocketState, setwebSocketState] = useState(false);

  const handleOnPageLoad = async (event) => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.GET_TENDER_ENDPOINT, //endpoint
        { tender_id: state.tender_id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          setLoading(false);
          const messages = response.data.message.messages.sort(compare);
          const mappedMessages = messages.map((itm) => ({
            id: itm.id,
            message: itm.message,
            user: itm.role.includes("bidder") ? 0 : 1,
            status: itm.status,
            timestamp: itm.createdAt,
          }));
          setMessages(mappedMessages.filter((itm) => itm.status !== "pending"));
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      // console.log(response);
    } catch (error) {
      setLoading(false);
    }
  };

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleOnPageReLoad = async (event) => {
    setLoading(true);
    await delay(1500)
    try {
      const response = await axios.post(
        endpoint.GET_TENDER_ENDPOINT, //endpoint
        { tender_id: state.tender_id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          setLoading(false);
          const messages = response.data.message.messages.sort(compare);
          const mappedMessages = messages.map((itm) => ({
            id: itm.id,
            message: itm.message,
            user: itm.role.includes("bidder") ? 0 : 1,
            status: itm.status,
            timestamp: itm.createdAt,
          }));
          setMessages(mappedMessages.filter((itm) => itm.status !== "pending"));
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      // console.log(response);
    } catch (error) {
      setLoading(false);
    }
  };

  // navigate
  const navigate = useNavigate();
  const { state } = useLocation();

  function handleCancel() {
    navigate("/AVAILABLETENDERS");
  }

  const connectWebsocket = () => {
    const authToken = localStorage.getItem("authorization");
    const userToken = localStorage.getItem("user_token");

    webSocket.current = new WebSocket(
      `${endpoint.WEBSOCKET}?Authorization=${authToken}&user-token=${userToken}`
    );
    webSocket.current.onopen = () => {
      console.log("connected")
      // setwebSocketState(true)
    };
    webSocket.current.onclose = () => {
      console.log("disconnected")
      // setwebSocketState(false)
    };
    webSocket.current.onerror = (e) => console.log(e);
    webSocket.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      switch (data.action) {
        case "message":
          setMessage(
            "Your response has been sent to the SCM offical for review"
          );
          setSeverity("success");
          openSnackbar();
          break;
        default:
          break;
      }
    };
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  React.useEffect(() => {
    if (webSocket.current) return;
    connectWebsocket();
    return () => {
      if (webSocket.current) {
        webSocket.current.close();
        webSocket.current = null;
      }
    };
  }, [webSocket]);
  // function for table

  // tender discission forum messages
  const [messages, setMessages] = useState([
    // {
    //   id: "m1",
    //   message: "answer",
    //   user: 1,
    // },
    // {
    //   id: "m2",
    //   message: "question",
    //   user: 0,
    // },
  ]);

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>
          {state.tender_number}
        </Typography>
      </Box>
      <Box className={classes.table}>
        <TenderTable
          tableData={[state]}
          page={1}
          perPage={1}
          useCase={"bsecTenderDetailsList"}
        />
      </Box>
      <Box className={classes.tenderforum}>
        <BsecTenderForum
          messages={messages}
          handleOnPageReLoad={handleOnPageReLoad}
          onNewMessage={(newMessage) => {
            console.log(newMessage);
            webSocket.current.send(
              JSON.stringify({
                action: "message",
                message: { message: newMessage, tender_id: state.tender_id },
              })
            );
          }}
        />
      </Box>
      <Box className={classes.backbuttonbox}>
        <CustomButtonOutlined onClick={handleCancel} text="Back" />
      </Box>
    </Box>
  );
}

export default TenderDetails;

function compare(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
