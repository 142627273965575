// email regex
export function ValidateEmail(input) {
  let validRegex = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/
  );

  if (!validRegex.test(input))
    return { error: true, errorMessage: "Please enter a valid email address" };

  return { error: false, errorMessage: "" };
}

// name regex
export function ValidateName(input) {

  // let validRegex = new RegExp("^[A-Za-z0-9]*$");
  let validRegex = new RegExp("^[A-Za-z0-9 '-.]+$");
 
  if (!validRegex.test(input))
    return { error: true, errorMessage: "Please enter valid characters" };

  return { error: false, errorMessage: "" };
}

export function ValidateCompanyName(input) {
  // let validRegex = new RegExp(/^[a-zA-Z0-9]+(?:[a-zA-Z0-9]+)*$/);

  // if (!validRegex.test(input))
  //   return { error: true, errorMessage: "Please enter valid characters" };

  return { error: false, errorMessage: "" };
}

// password regex
export function ValidatePassword(input) {
  let validRegex = new RegExp(
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
  );
  //   /^[a-zA-Z]+(?:[a-zA-Z]+)*$/
  // );

  if (input.length < 8)
    return {
      error: true,
      errorMessage: "Password must be at least 8 characters",
    };

  if (!new RegExp("[0-9]").test(input))
    return {
      error: true,
      errorMessage: "Password must contain at least one number",
    };

  if (!new RegExp("[a-z]").test(input))
    return {
      error: true,
      errorMessage: "Password must contain at least one lowercase letter",
    };

  if (!new RegExp("[A-Z]").test(input))
    return {
      error: true,
      errorMessage: "Password must contain at least one uppercase letter",
    };

  // if (new RegExp("[^A-Za-z0-9]").test(input))
  //   return "Password must contain at least one valid special character";
  // if (!validRegex.test(input))
  //   return { error: true, errorMessage: "Please enter valid characters" };

  return { error: false, errorMessage: "" };
}

// reg number regex
export function ValidateRegistrationNumber(input) {
  // let validRegex = new RegExp(
  //   /^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4}$/
  //   );

  // if (!validRegex.test(input))
  //   return { error: true, errorMessage: "Please enter valid characters" };

  return { error: false, errorMessage: "" };
}

// phone number regex
export function ValidateNumber(input) {
  // let validRegex = new RegExp(/^[+0-9]+(?:[+0-9]+)*$/);
  let validRegex = new RegExp("^[0-9]{0,15}$");

  if (!validRegex.test(input))
    return { error: true, errorMessage: "Telephone/mobile number cannot be more than 15 digits" };

  if (input.startsWith("0"))
    return {
      error: true,
      errorMessage:
        "Please enter a number with a country code",
    };

  return { error: false, errorMessage: "" };
  // let validRegex = new RegExp(
  //   /^\s*(?:\+?(\d{1,3}))?[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d)[\W\D\s]*(\d[\W\D\s]*?\d[\D\W\s]*?\d[\W\D\s]*?\d)(?: *x(\d+))?\s*$/
  // );

  // if (!validRegex.test(input))
  //   return { error: true, errorMessage: "Please enter valid characters" };
}

// export function ValidateCSDNumber(input) {

//   // let validRegex = new RegExp("^[0-9]{0,11}$");
  
//   let validRegex = new RegExp("^[A-Za-z0-9]{0,11}$");

//   if (!validRegex.test(input))
//     return { error: false, errorMessage: "CSD number must be 11 characters" };

//     if (!new RegExp("^[A-Za-z0-9]{11}$").test(input))
//     return { error: false, errorMessage: "CSD number must be 11 characters" };

//   return { error: false, errorMessage: "" };
// }

export function ValidateCSDNumber(input) {
  if (!input) {
    return { error: false, errorMessage: "" }; // Field is optional, no error or message
  }

  if (input.length !== 11) {
    if (input.trim().length === 0) {
      return { error: false, errorMessage: "" }; // Ignore error if input becomes empty
    }
    return { error: true, errorMessage: "CSD number must be 11 characters" };
  }

  return { error: false, errorMessage: "" };
}

  // if (!input) return { error: false, errorMessage: "" };
  // if (input.length < 11)
  //   return {
  //     error: true,
  //     errorMessage: "CSD number must be at least 11 characters",
  //   };

  // return { error: false, errorMessage: "" };
// }

// otp regex
export function ValidateOTP(input) {
  let validRegex = new RegExp("^[0-9]{1,6}$");

  if (!validRegex.test(input))
    return { error: true, errorMessage: "Please enter valid characters" };

  return { error: false, errorMessage: "" };
}

export function ValidateEmployeeReferenceNumber(input) {

  let validRegex = new RegExp("^[0-9]{0,4}$");

  if (!validRegex.test(input))
    return { error: true, errorMessage: "Employee reference number must be 4 numbers only" };
  return { error: false, errorMessage: "" };
}