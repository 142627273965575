import React, { useEffect } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue } from "../../theme/colors";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    typographyboxheading: {
      display: "flex",
      justifyContent: "flex-start",
      paddingTop: "5%",
      paddingBottom: "3%",
      paddingLeft: "7.5%",
    },

    typographyboxtext: {
      paddingLeft: "7.5%",
      paddingRight: "7.5%",
    },

    buttonboxback: {
      paddingTop: "7%",
      marginLeft: "45%",
      paddingBottom: "5%",
    },
  })
);

function TnCs() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "TnC's";
  }, []);

  // navigate
  const navigate = useNavigate();

  function handleClick() {
    navigate("/signUp");
  }

  return (
    <Box style={{ justifyContent: "center" }}>
      <Box>
        <Typography className={classes.typographyboxheading}>
          Terms and Conditions of Use:
        </Typography>
      </Box>
      <Box >
        <ol className={classes.typographyboxtext}>
          <li style={{ textAlign: "justify"}}>
            By signing up on the e-Tender Portal and submitting a bid, the
            Bidder voluntarily authorizes SENTECH to process its’ personal
            information (including, without limitation, <em>inter alia</em>,
            its’ name, credit card and banking details, physical address,
            telephone numbers, reference letters and any other information it
            has provided to SENTECH) for the purposes of tendering and
            contracting and consents to the transfer of such personal
            information to third parties.
          </li>
          <br />
          <li style={{ textAlign: "justify"}}>
            The Bidder agrees to be contacted by authorized persons during the
            evaluation processes to provide clarity in respect of its’ bid or
            to negotiate prices offered.
          </li>
          <br />
          <li style={{ textAlign: "justify"}}>
          It is the responsibility of the bidders to ensure that their responses 
          are submitted on time, SENTECH will not be liable for any system or technical 
          challenges experienced by bidders resulting to non-submission.
          </li>
        </ol>
      </Box>
      <Box className={classes.buttonboxback}>
        <CustomButtonSolid onClick={handleClick} text="Back" />
      </Box>
    </Box>
  );
}

export default TnCs;
