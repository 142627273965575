import React from "react";
import { Box, Typography} from "@material-ui/core";
import { useNavigate } from "react-router";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    signout: {
      textAlign: "center",
    },

    signouttext: {
      paddingTop: "10%",
      paddingBottom: "3%",
    },

    signoutbackbutton: {
      paddingBottom: "5%",
    },
  })
);

function SignOut() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Sign out";
  }, []);

  const navigate = useNavigate();

  function handleClick() {
    navigate("/login");
  }

  return (
    <Box className={classes.signout}>
      <Box className={classes.signouttext}>
        <Typography>You have successfully signed out of the portal.</Typography>
      </Box>
      <Box className={classes.signoutbackbutton}>
        <CustomButtonSolid onClick={handleClick} text="LOGIN" />
      </Box>
    </Box>
  );
}

export default SignOut;
