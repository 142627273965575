import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@material-ui/core";
import Logo from "../../../assets/SENTECH-LOGO-JPG.jpg";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechDarkGrey } from "../../../theme/colors";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import BidderSidebar from "../biddersSidebar/sideBar";
import SentechSidebar from "../sentechSidebar/sideBar";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    navbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100px",
      backgroundColor: "white",
      // borderBottom: sentechDarkGrey,
      // // "2px solid"
      // webkitBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
      // mozBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
      // boxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
    },

    // loginlink: {
    //   // display: "inline-block",
    //   // display: "flex",
    //   // justifycontent: "flex-end",
    //   // marginRight: "2%",
    //   liststyle: "none",
    //   // width: "150px",
    //   // minWidth: "max-content",
    //   textDecoration: "none",
    //   fontSize: "14px",
    //   padding: "10px 20px",
    //   margin: "0 10px",
    //   cursor: "pointer",
    //   color: "#000",
    // },

    // signuplink: {
      // display: "inline-block",
      // display: "flex",
      // justifycontent: "flex-end",
      // marginRight: "5%",
      // marginLeft: "-720px",
      // liststyle: "none",
      // width: "150px",
      // minWidth: "max-content",
      // textDecoration: "none",
      // fontSize: "14px",
      // padding: "10px 20px",
      // margin: "0 10px",
      // cursor: "pointer",
      // color: "#000",
    // },

    navbarlogo: {
      // width: "18%",
      // padding: "10px 10px",
      // margin: "0 10px",
      // margin: "0",
      // padding: "5px 10px",
      cursor: "pointer",
      width: "24%",
      marginLeft: "6%",
      marginBottom: "4%",
      marginTop: "4%",
    },
  })
);

function Navbar() {
  // material ui
  const classes = useStyles();

  const { authState, popAuthState, clearState } =
    React.useContext(ApplicationContext);

  const navigate = useNavigate();

  function handleClick() {
    navigate("/login");
  }

  const signout = async () => {
    const response = await axios.post(
      endpoint.SIGNOUT, //endpoint
      {},
      {
        headers: {
          "user-token": localStorage.getItem("user_token"),
          authorization: localStorage.getItem("authorization"),
        },
      }
    );
    localStorage.clear();
    clearState();
  };

  function handleClic() {
    signout();
    popAuthState({
      isLoggedIn: false,
    });
    navigate("signOut");
  }

  const signoutButton = (
    <Button
      style={{
        marginRight: "10.5%",
        marginTop: "auto",
        marginBottom: "auto",
        color: sentechDarkGrey,
      }}
      // className={classes.navlinks}
      onClick={(e) => handleClic()}
    >
      SIGN OUT
    </Button>
  );

  return (
    <div>
      <nav className={classes.navbar}>
        <img
          src={Logo}
          alt="navbarlogo"
          className={classes.navbarlogo}
          onClick={() => {
            navigate("/");
          }}
        />
        {authState.isLoggedIn || localStorage.getItem("isLoggedIn") ? (
          <>
            {localStorage.getItem("bidderRole") ? (
              <BidderSidebar child={signoutButton} />
            ) : (
              <SentechSidebar child={signoutButton} />
            )}
          </>
        ) : (
          <div className="btn-group">
           <Button 
            style={{ color: sentechDarkGrey }}
            className={classes.loginlink}
            onClick={(e) => handleClick()}
          >
            LOGIN
          </Button> 
          <Button 
          style={{ color: sentechDarkGrey, marginRight: "70px" }}
          className={classes.signuplink}
          onClick={() => {
            navigate("/signUp");}}
        >
          SIGN UP
        </Button>
        </div>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
