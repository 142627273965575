import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link
} from "@material-ui/core";
import Sidebar from "../layout/scmSidebar/sideBar";
import BreadCrumb from "../layout/breadcrumbs/breadcrumbs";
import CustomInput from "../inputFields/Inputfields";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import CustomButtonSolid from "../layout/buttons/solidButton";
import CustomButtonOutlined from "../layout/buttons/outlinedButton";
import { ApplicationContext } from "../../context/ApplicationContext";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { userError } from "../../apiRequests/errorMessages";
import { useNavigate } from "react-router";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },

    profileinput: {
      paddingLeft: "6%",
    },

    roles: {
      paddingLeft: "6%",
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
    },

    rolecheckboxes: {
      width: "20%",
      color: sentechDarkGrey,
    },

    editbutton: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "5%",
    },

    profilebuttonbox: {
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "40%",
      paddingRight: "35%",
      display: "flex",
      justifyContent: "space-between",
    },
    checkbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: sentechBlue,
      },
    },

    resetpasswordlink: {
      paddingTop: "3%",
      paddingLeft: "47%",
    },
  })
);

function SentechUserProfile() {
  // material ui
  const classes = useStyles();

  const {
    popAuthState,
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    setSeverity,
  } = useContext(ApplicationContext);

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Profile";
  }, []);

  // set form width
  const labelWidth = "200px";

  const navigate = useNavigate();

  const handleOnPageLoad = async (event) => {
    if (authState.id) return;
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.GET_USER_ENDPOINT,
        { id: localStorage.getItem("id") }, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          popAuthState({
            isLoggedIn: true,
            id: message.id,
            email: message.email,
            firstName: message.first_name,
            lastName: message.last_name,
            employeeRef: message.employee_ref,
            designation: message.designaion,
            department: message.department,
            role: message.role,
            //fil other sentech user details
          });
          setEmail(message.email);
          setName(message.first_name);
          setSurname(message.last_name);
          setEmployeeReference(message.employee_ref);
          setDepartment(message.department);
          setDesignation(message.designaion);
          setRole({
            admin: message.role?.includes("admin"),
            bsec: message?.role?.includes("bsec"),
            scm: message?.role?.includes("scm"),
          });
          setLoading(false);
        } else {
          setMessage(userError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // state
  const [name, setName] = useState(authState.firstName??"");
  const [surname, setSurname] = useState(authState.lastName??"");
  const [employeeRef, setEmployeeReference] = useState(authState.employeeRef??"");
  const [email, setEmail] = useState(authState.email??"");
  const [department, setDepartment] = useState(authState.department??"");
  const [designation, setDesignation] = useState(authState.designation??"");
  const [role, setRole] = useState({
    admin: localStorage.getItem("adminRole") ? true : false,
    bsec: localStorage.getItem("scmRole") ? true : false,
    scm: localStorage.getItem("bsecRole") ? true : false,
  });

  const [disabled, setDisabled] = useState(true);

  function handleDisabled(event) {
    // event.preventDefault();
    setDisabled(!disabled);
  }

  function handleResetClick() {
    navigate("/forgotPassword");
  }

  function handleCancel() {
    setDisabled(!disabled);
    setName(authState.firstName);
    setSurname(authState.lastName);
    setEmployeeReference(authState.employeeRef);
    setDepartment(authState.department);
    setDesignation(authState.designation);
  }

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;

      case "surname":
        setSurname(event.target.value);
        break;

      case "employeeReference":
        setEmployeeReference(event.target.value);
        break;

      case "email":
        setEmail(event.target.value);
        break;

      case "department":
        setDepartment(event.target.value);
        break;

      case "designation":
        setDesignation(event.target.value);
        break;

      // case "role":
      //     setRole(event.target.value);
      //     break;

      default:
        break;
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    handleDisabled();
    // setDisabled(!disabled);
    try {
      const response = await axios.post(
        endpoint.EDIT_USER_ENDPOINT, //endpoint - update
        {
          id: localStorage.getItem("id"),
          first_name: name,
          last_name: surname,
          Employee_ref: employeeRef,
          Department: department,
          Designation: designation,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          popAuthState({
            isLoggedIn: true,
            id: authState.id,
            email: message.email,
            firstName: message.first_name,
            lastName: message.last_name,
            employeeRef: message.employeeReference,
            designation: message.designation,
            department: message.department,
            role: message.role,
            //fil other sentech user details
          });
          setMessage("Profile Updated Successfully!");
          setSeverity("success");
          openSnackbar();
        } else {
          setMessage(userError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>PROFILE</Typography>
      </Box>
      <Box>
        <form onSubmit={handleOnSubmit}>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={name}
              onChange={handleInputChange}
              label="Name:"
              required
              name="name"
              type="text"
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={surname}
              focused={true}
              onChange={handleInputChange}
              label="Surname:"
              required
              name="surname"
              type="text"
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={employeeRef}
              onChange={handleInputChange}
              label="Employee Reference:"
              required
              name="employeeReference"
              type="text"
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={email}
              onChange={handleInputChange}
              label="Email:"
              required
              name="email"
              type="text"
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              label="Department:"
              name="department"
              required
              type="text"
              value={department}
              onChange={handleInputChange}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={designation}
              onChange={handleInputChange}
              label="Designation:"
              required
              name="designation"
              type="text"
            />
          </Box>
          <Box className={classes.roles}>
            <Typography style={{ color: sentechDarkGrey, width: labelWidth }}>
              Role Assigned:
            </Typography>
            <Box style={{ width: "20%" }} />
          </Box>
          {/* role checkboxes */}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "6%",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            <Box style={{ width: labelWidth }} />
            <Box className={classes.rolecheckboxes}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      defaultChecked={
                        localStorage.getItem("adminRole") ? true : false
                      }
                      disabled
                    />
                  }
                  label="Sys Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      disabled
                      defaultChecked={
                        localStorage.getItem("scmRole") ? true : false
                      }
                    />
                  }
                  label="SCM Official"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      disabled
                      defaultChecked={
                        localStorage.getItem("bsecRole") ? true : false
                      }
                    />
                  }
                  label="BSEC Member"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box>
          {!disabled && (
            <Box className={classes.resetpasswordlink}>
              <Link
                sx={{
                  color: sentechBlue,
                }}
                onClick={handleResetClick}
              >
                Reset password
              </Link>
            </Box>
            )}
            {disabled && (
              <Box className={classes.editbutton}>
                <CustomButtonSolid
                  onClick={handleDisabled}
                  text="Edit Profile"
                />
              </Box>
            )}
            {!disabled && (
              <Box className={classes.profilebuttonbox}>
                <CustomButtonOutlined onClick={handleCancel} text="Cancel" />
                <CustomButtonSolid
                  onClick={handleOnSubmit}
                  text="Save Changes"
                  disabled={
                    !name ||
                    !surname ||
                    !employeeRef ||
                    !email ||
                    !department ||
                    !designation
                  }
                />
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default SentechUserProfile;
