import React, { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Box, Typography, Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useRef } from "react";
import { sentechDarkGrey } from "../../theme/colors";
import { ApplicationContext } from "../../context/ApplicationContext";
import { endpoint } from "../../endpoint/endpoints";
import { tenderErorr } from "../../apiRequests/errorMessages";
import TenderTable from "../tenderTables/tenderTable";
import CustomPagination from "../layout/pagination/pagination";
import BreadCrumb from "../layout/breadcrumbs/breadcrumbs";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading1: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    addtenderslistbuttonbox: {
      paddingRight: "7.5%",
      display: "flex",
      justifyContent: "flex-end",
    },

    tenderslisttable: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    paginations1: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
  })
);

function AvailableTenders() {
  // material ui
  const classes = useStyles();

  const {
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    openTenders,
    popOpenTenders,
    closedTenders,
    popCategories,
    popClosedTenders,
    setSeverity,
  } = useContext(ApplicationContext);

  const Categories = useRef([{ lablel: "test", id: "test" }]);
  const mounted = React.useRef(true);

  const handleOnPageLoad = async (event) => {
    if (mounted.current) {
      mounted.current = false;
      setLoading(true);
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}
      // if (openTenders.length) {
      //   setLoading(false);
      //   return;
      // }

      try {
        const response = await axios.get(
          endpoint.LIST_MYTENDER_ENDPOINT, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        //   const response = apiResponse;
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message;
            const now = Math.floor(Date.now() / 1000);
            // data.forEach((element) => {
            //   if (element.tender_number) {
            //     if (element.close_datetime <= now) popClosedTenders([element]);
            //     else popOpenTenders([element]);
            //   }
            // });
            popClosedTenders(data.filter((itm) => itm.close_datetime <= now));
            popOpenTenders(data.filter((itm) => itm.close_datetime > now));
            setLoading(false);
          } else {
            setMessage(tenderErorr(response.data.errorcode));
            response.data.errorcode === 5
            ? setSeverity("info")
            : setSeverity("error");
            setLoading(false);
            openSnackbar();
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Tenders";
  }, []);

  React.useEffect(() => {
    const refresh = setInterval(() =>
    handleOnPageLoad() 
    ,2820
    );
    return () => {
      clearInterval(refresh)
    };
  }, []);

  // navigate
  const navigate = useNavigate();

  function handleClick() {
    navigate("/AVAILABLETENDERS/ADDTENDER", {
      state: { ...Categories.current },
    });
  }

  const SCMTenders = openTenders.filter((itm) => itm.role === "scm");
  const BSECTenders = openTenders.filter((itm) => itm.role === "bsec");

  // function for pagination
  const perPage = 5;
  const maxSCMPage = Math.ceil(SCMTenders.length / perPage);
  const maxBSECPage = Math.ceil(BSECTenders.length / perPage);

  const [SCMpage, setSCMPage] = useState(1);
  const [BSECpage, setBSECPage] = useState(1);

  function onNewSCMPage(newPage) {
    setSCMPage(newPage);
  }

  function onNewBSECPage(newPage) {
    setBSECPage(newPage);
  }

  // function for empty tenders table
  function getTendersList() {
    if (openTenders.length) {
      return (
        <>
          {getSCM()}
          {getBSEC()}
        </>
      );
    } else {
      return (
        <Box
          style={{
            color: sentechDarkGrey,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>There are no tenders created.</Typography>
        </Box>
      );
    }
  }

  function getSCM() {
    if (localStorage.getItem("scmRole"))
      return (
        <>
          <Box className={classes.heading1}>
            <Typography style={{ fontSize: "24px" }}>
              AVAILABLE TENDERS - SCM
            </Typography>
          </Box>
          <Box className={classes.addtenderslistbuttonbox}>
            <CustomButtonSolid onClick={handleClick} text="+ Add Tender" />
          </Box>{" "}
          {SCMTenders.length ? (
            <>
              <Box className={classes.tenderslisttable}>
                <TenderTable
                  tableData={SCMTenders}
                  page={SCMpage}
                  perPage={perPage}
                  useCase={"scmList"}
                />{" "}
              </Box>
              <Box className={classes.paginations1}>
                <CustomPagination onNewPage={onNewSCMPage} count={maxSCMPage} />
              </Box>
            </>
          ) : (
            <Box
              style={{
                color: sentechDarkGrey,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography>You have not added any Tenders.</Typography>
            </Box>
          )}
        </>
      );
  }

  function getBSEC() {
    if (localStorage.getItem("bsecRole"))
      return (
        <>
          <Box className={classes.heading1}>
            <Typography style={{ fontSize: "24px" }}>
              AVAILABLE TENDERS - BSEC
            </Typography>
          </Box>
          {BSECTenders.length ? (
            <>
              <Box className={classes.tenderslisttable}>
                <TenderTable
                  tableData={BSECTenders}
                  page={BSECpage}
                  perPage={perPage}
                  useCase={"bsecAvailableTendersList"}
                />
              </Box>
              <Box className={classes.paginations1}>
                <CustomPagination onNewPage={onNewBSECPage} count={maxBSECPage} />
              </Box>
            </>
          ) : (
            <Box
              style={{
                color: sentechDarkGrey,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography>
                You have not been assigned to any Tenders.
              </Typography>
            </Box>
          )}
        </>
      );
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      {getSCM()}
      {getBSEC()}
    </Box>
  );
}

export default AvailableTenders;
