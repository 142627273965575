import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Sidebar from "../../layout/scmSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import { useLocation, useNavigate } from "react-router";
import CustomInput from "../../inputFields/Inputfields";
// toast message imports
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CustomDropDown from "../../inputFields/DropdownField";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../../theme/colors";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import { ApplicationContext } from "../../../context/ApplicationContext";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import FileUpload from "../../dragDropInput/fileUpload";
import FileList from "../../files/fileList";
import CustomAutoComplete from "../../inputFields/Autocomplete";
import { Public } from "@material-ui/icons";
import { useRef } from "react";
import { tenderManagementError } from "../../../apiRequests/errorMessages";
import categoryCompare from "../../../utilities/categoryCompare";
import convertUnixToDateTime from "../../../utilities/unixToMUIDateTime";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },

    tendertype: {
      paddingRight: "28%",
      paddingBottom: "0%",
      paddingLeft: "52%",
      color: sentechDarkGrey,
    },

    typedropdown: {
      paddingLeft: "75%",
      paddingTop: "0%",
      paddingBottom: "1%",
      width: "31%",
    },

    viewtenderinput: {
      // paddingLeft: "6%",
    },

    tenderdescription: {
      paddingLeft: "75%",
      paddingTop: "0%",
      paddingBottom: "1%",
    },

    datetimeheading: {
      paddingLeft: "52%",
      paddingBottom: "0%",
      color: sentechDarkGrey,
    },

    datetime: {
      paddingLeft: "75%",
      paddingTop: "0%",
      paddingBottom: "1%",
    },

    closetimeheading: {
      paddingLeft: "52%",
      paddingBottom: "0%",
      color: sentechDarkGrey,
    },

    issuedateheading: {
      paddingLeft: "52%",
      paddingBottom: "0%",
      color: sentechDarkGrey,
    },

    fileinfotext: {
      display: "flex",
      justifyContent: "center",
      color: sentechLightGrey,
    },

    dragdropbox: {
      width: "100%",
      paddingTop: "2%",
      paddingBottom: "4%",
      display: "flex",
      justifyContent: "center",
    },

    buttonboxes: {
      paddingTop: "3%",
      paddingBottom: "5%",
      display: "flex",
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "center",
    },

    filelist: {
      width: "1000px",
      paddingLeft: "30px",
    },
  })
);

function AddTender() {
  // material ui
  const classes = useStyles();

  //Context state items
  const {
    setMessage,
    openSnackbar,
    setLoading,
    biddersList,
    popBiddersList,
    sentechUsersList,
    popSentechUsersList,
    categories,
    popCategories,
    setSeverity,
  } = useContext(ApplicationContext);

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Add tender";
  }, []);

  // set form width
  const labelWidth = "200px";

  // navigate
  const navigate = useNavigate();
  const { state } = useLocation();

  // state
  const [category, setCategory] = useState("");
  const [tenderNumber, setTenderNumber] = useState("");
  const [tenderType, setTenderType] = useState("");
  const [tenderDescription, setTenderDescription] = useState("");
  const [briefingSession, setBriefingSession] = useState("");
  const [closing, setClosing] = useState("");
  const [issueDate, setIssueDate] = useState(
    convertUnixToDateTime(Date.now() / 1000)
  );
  const [bsecMembers, setBsecMembers] = useState([]);
  const [scmOfficials, setScmOfficials] = useState([]);
  const [bidders, setBidders] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [SCMlist, setSCMList] = useState([]);
  const [BSECList, setBSECList] = useState([]);

  //event handlers for textfields
  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "category":
        setCategory(event.target.value);
        break;

      case "tenderNumber":
        setTenderNumber(event.target.value);
        break;

      case "tenderType":
        setTenderType(event.target.value);
        break;

      case "tenderDescription":
        setTenderDescription(event.target.value);
        break;

      case "briefingSession":
        if (
          Date.now() / 1000 >=
          new Date(event.target.value).valueOf() / 1000
        ) {
          setMessage("Briefing Session must be a future date-time");
          setSeverity("error");
          openSnackbar();
          break;
        }
        let briefingSessionDate = new Date(event.target.value).valueOf() / 1000;
        let closingDate = new Date(closing).valueOf() / 1000;
        if (
          briefingSessionDate >= closingDate ||
          briefingSessionDate <= Date.now() / 1000 ||
          briefingSessionDate === closingDate
        ) {
          setMessage(
            "Briefing Session must be a date-time before Closing Date and not the same as Closing Date or a date-time after Closing Date"
          );
          setSeverity("error");
          openSnackbar();
          break;
        }
        setBriefingSession(event.target.value);
        break;

      case "closing":
        if (
          new Date(briefingSession).valueOf() / 1000 >=
            new Date(event.target.value).valueOf() / 1000 ||
          !briefingSession
        ) {
          setMessage("Closing Date must be after Briefing Session");
          setSeverity("error");
          openSnackbar();
          break;
        }
        setClosing(event.target.value);
        break;

      case "issueDate":
        setIssueDate(event.target.value);
        break;

      case "bsecMembers":
        setBsecMembers(event.target.value);
        break;

      case "scmOfficials":
        setScmOfficials(event.target.value);
        break;

      default:
        break;
    }
  };

  // dropdown function
  const TenderType = [
    { label: "Open", id: "public" },
    { label: "Nominated", id: "private" },
  ];

  const handleCancel = () => {
    navigate("/AVAILABLETENDERS");
  };

  //On page load fetch categories and available Sentech Users/Bidders
  const handleOnPageLoad = async (event) => {
    setLoading(true);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}
    try {
      if (sentechUsersList.length) {
        // const sentechUsers = sentechUsersList.map((user) => ({
        //   firstName: user.first_name,
        //   lastName: user.last_name,
        //   email: user.email,
        //   employeeRef: user.employee_ref,
        //   department: user.department,
        //   designation: user.designaion,
        //   role: user.role,
        //   id: user.id,
        // }));
        // popSentechUsersList(sentechUsers);
        // setSCMList(sentechUsers?.filter((user) => user.role.includes("scm")));
        // setBSECList(sentechUsers?.filter((user) => user.role.includes("bsec")));
        // setLoading(false);
        // return;
      }
      const response = await axios.get(
        endpoint.ADMIN_LIST_USER_ENDPOINT, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message; //update
          popBiddersList(
            message.bidders.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              companyName: user.company_name,
              companyPhone: user.company_phone,
              companyMobile: user.company_mobile_phone ?? "",
              reg: user.reg_number,
              csd: user.csd_number,
              id: user.id,
              role: user.role[0],
            }))
          );
          const sentechUsers = message.sentech_users.map((user) => ({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            employeeRef: user.employee_ref,
            department: user.department,
            designation: user.designaion,
            role: user.role,
            id: user.id,
          }));
          popSentechUsersList(sentechUsers);
          const filteredUsers = sentechUsers.filter(
            (user) => user.id !== localStorage.getItem("id")
          );
          setSCMList(filteredUsers.filter((user) => user.role.includes("scm")));
          setBSECList(
            filteredUsers.filter((user) => user.role.includes("bsec"))
          );
          setLoading(false);
        } else {
          setMessage("Failed to get bidders and Sentech Users");
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // const handleOnSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       endpoint.ADD_TENDER, //endpoint
  //       {
  //         category_id: category,
  //         tender_number: tenderNumber,
  //         tender_type: tenderType,
  //         tender_description: tenderDescription,
  //         brief_session: new Date(briefingSession).valueOf() / 1000,
  //         close_datetime: new Date(closing).valueOf() / 1000,
  //         issue_date: new Date(issueDate).valueOf() / 1000,
  //         filename: uploadFiles[0].file.name,
  //       },
  //       {
  //         headers: {
  //           "user-token": localStorage.getItem("user_token"),
  //           authorization: localStorage.getItem("authorization"),
  //         },
  //       }
  //     );
  //     //   //   const response = apiResponse;
  //     if (response.status === 200) {
  //       if (response.data.error === false) {
  //         const message = response.data.message;
  //         const uploadURL = message.urlLink;
  //         const tender_id = message.id;
  //         const file = uploadFiles[0].file;
  //         // setLoading(false);

  //         try {
  //           const uploadResult = await axios.put(uploadURL, file, {
  //             headers: {
  //               "Content-Type": "",
  //             },
  //             onUploadProgress: (progressEvent) => {
  //               let percentComplete =
  //                 progressEvent.loaded / progressEvent.total;
  //               percentComplete = parseInt(percentComplete * 100);
  //               setUploadFiles((prevState) => {
  //                 return prevState.map((file) => ({
  //                   file: file.file,
  //                   loadingState: percentComplete,
  //                 }));
  //               });
  //             },
  //           });
  //         } catch (error) {}
  //         const filteredBSECmembers = bsecMembers.filter((itm) => {
  //           return scmOfficials.every((item) => {
  //             return itm.id !== item.id;
  //           });
  //         });
  //         filteredBSECmembers.forEach(async (user) => {
  //           try {
  //             const assignResult = await axios.post(
  //               endpoint.ASSIGN_TENDER,
  //               {
  //                 tender_id: tender_id,
  //                 user_id: user.id,
  //                 role: "bsec",
  //               },
  //               {
  //                 headers: {
  //                   "user-token": localStorage.getItem("user_token"),
  //                   authorization: localStorage.getItem("authorization"),
  //                 },
  //               }
  //             );
  //           } catch (error) {}
  //         });
  //         scmOfficials.forEach(async (user) => {
  //           try {
  //             const assignResult = await axios.post(
  //               endpoint.ASSIGN_TENDER,
  //               { tender_id: tender_id, user_id: user.id, role: "scm" },
  //               {
  //                 headers: {
  //                   "user-token": localStorage.getItem("user_token"),
  //                   authorization: localStorage.getItem("authorization"),
  //                 },
  //               }
  //             );
  //           } catch (error) {}
  //         });
  //         try {
  //           if (tenderType === "private") {
  //             bidders.forEach(async (user) => {
  //               const assignResult = await axios.post(
  //                 endpoint.INVITE_BIDDER,
  //                 { tender_id: tender_id, user_id: user.id },
  //                 {
  //                   headers: {
  //                     "user-token": localStorage.getItem("user_token"),
  //                     authorization: localStorage.getItem("authorization"),
  //                   },
  //                 }
  //               );
  //             });
  //           }
  //         } catch (error) {}
  //         navigate("/AVAILABLETENDERS");
  //         setMessage("Tender Succesfully created");
  //         setSeverity("success");
  //         openSnackbar();
  //         setLoading(false);
  //       } else {
  //         setMessage(tenderManagementError(response.data.errorCode));
  //         setSeverity("error");
  //         setLoading(false);
  //         openSnackbar();
  //       }
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {}
  //   setLoading(false);
  // };


  const handleOnSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    try {
      const response = await axios.post(
        endpoint.ADD_TENDER,
        {
          category_id: category,
          tender_number: tenderNumber,
          tender_type: tenderType,
          tender_description: tenderDescription,
          brief_session: new Date(briefingSession).valueOf() / 1000,
          close_datetime: new Date(closing).valueOf() / 1000,
          issue_date: new Date(issueDate).valueOf() / 1000,
          filename: uploadFiles[0].file.name,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
  
      if (response.status === 200 && response.data.error === false) {
        const message = response.data.message;
        const uploadURL = message.urlLink;
        const tender_id = message.id;
        const file = uploadFiles[0].file;
  
        try {
          const uploadResult = await axios.put(uploadURL, file, {
            headers: {
              "Content-Type": "",
            },
            onUploadProgress: (progressEvent) => {
              let percentComplete =
                (progressEvent.loaded / progressEvent.total) * 100;
              percentComplete = parseInt(percentComplete);
              setUploadFiles((prevState) => {
                return prevState.map((file) => ({
                  file: file.file,
                  loadingState: percentComplete,
                }));
              });
            },
          });
  
          if (uploadResult.status === 200) {
            // Handle successful upload
            setMessage("File uploaded successfully");
            setSeverity("success");
            openSnackbar();
          } else {
            // Handle upload failure
            setMessage("Failed to upload file");
            setSeverity("error");
            openSnackbar();
          }
  
          const filteredBSECmembers = bsecMembers.filter((itm) => {
            return scmOfficials.every((item) => {
              return itm.id !== item.id;
            });
          });
  
          await Promise.all(
            filteredBSECmembers.map(async (user) => {
              try {
                await axios.post(
                  endpoint.ASSIGN_TENDER,
                  {
                    tender_id: tender_id,
                    user_id: user.id,
                    role: "bsec",
                  },
                  {
                    headers: {
                      "user-token": localStorage.getItem("user_token"),
                      authorization: localStorage.getItem("authorization"),
                    },
                  }
                );
              } catch (error) {
                // Handle error during BSEC assignment
              }
            })
          );
  
          await Promise.all(
            scmOfficials.map(async (user) => {
              try {
                await axios.post(
                  endpoint.ASSIGN_TENDER,
                  { tender_id: tender_id, user_id: user.id, role: "scm" },
                  {
                    headers: {
                      "user-token": localStorage.getItem("user_token"),
                      authorization: localStorage.getItem("authorization"),
                    },
                  }
                );
              } catch (error) {
                // Handle error during SCM assignment
              }
            })
          );
  
          if (tenderType === "private") {
            await Promise.all(
              bidders.map(async (user) => {
                try {
                  await axios.post(
                    endpoint.INVITE_BIDDER,
                    { tender_id: tender_id, user_id: user.id },
                    {
                      headers: {
                        "user-token": localStorage.getItem("user_token"),
                        authorization: localStorage.getItem("authorization"),
                      },
                    }
                  );
                } catch (error) {
                  // Handle error during bidder invitation
                }
              })
            );
          }
  
          navigate("/AVAILABLETENDERS");
          setMessage("Tender Successfully created");
          setSeverity("success");
          openSnackbar();
          setLoading(false);
        } catch (error) {
          // Handle error during file upload
          setMessage("Failed to upload file");
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        // Handle error in response
        setMessage(tenderManagementError(response.data.errorCode));
        setSeverity("error");
        openSnackbar();
        setLoading(false);
      }
    } catch (error) {
      // Handle error in request
      setMessage("Failed to create tender");
      setSeverity("error");
      openSnackbar();
      setLoading(false);
    }
  };
  

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>ADD NEW TENDER</Typography>
      </Box>
      <Box>
        <form
        // onSubmit={handleOnSubmit}
        >
          <Box className={classes.viewtenderinput}>
            <CustomDropDown
              options={categories.sort(categoryCompare)}
              width={labelWidth}
              value={category}
              onChange={handleInputChange}
              label="Category:"
              required
              name="category"
              type="text"
            />
          </Box>
          <Box className={classes.viewtenderinput}>
            <CustomInput
              width={labelWidth}
              value={tenderNumber}
              onChange={handleInputChange}
              label="Tender number:"
              required
              name="tenderNumber"
              type="text"
            />
          </Box>
          <Box className={classes.viewtenderinput}>
            {/* tender type dropdown */}
            <CustomDropDown
              options={TenderType}
              width={labelWidth}
              value={tenderType}
              onChange={handleInputChange}
              label="Tender Type:"
              required
              name="tenderType"
              type="text"
            />
          </Box>
          <Box className={classes.viewtenderinput}>
            <CustomInput
              width={labelWidth}
              value={tenderDescription}
              onChange={handleInputChange}
              label="Tender Description:"
              required
              name="tenderDescription"
              type="text"
              multiline={true}
            />
          </Box>
          {/* <Box className={classes.tenderdescription}>
                    <h6>Maximum length: 255 characters</h6>
                    </Box> */}
          <Box>
            <Box className={classes.viewtenderinput}>
              <CustomInput
                width={labelWidth}
                disabled={true}
                value={issueDate}
                onChange={handleInputChange}
                label="Issue date and time:"
                required
                name="issueDate"
                type="datetime-local"
              />
            </Box>
          </Box>
          <Box>
            <Box className={classes.viewtenderinput}>
              <CustomInput
                width={labelWidth}
                value={briefingSession}
                onChange={handleInputChange}
                label="Briefing session date and time:"
                required
                name="briefingSession"
                type="datetime-local"
              />
            </Box>
          </Box>
          <Box>
            <Box className={classes.viewtenderinput}>
              {/* shows calendar and time */}
              <CustomInput
                width={labelWidth}
                value={closing}
                onChange={handleInputChange}
                label="Closing date and time:"
                required
                name="closing"
                type="datetime-local"
              />
            </Box>
          </Box>
          <Box className={classes.viewtenderinput}>
            <CustomAutoComplete
              width={labelWidth}
              value={bsecMembers}
              label="BSEC Members"
              options={BSECList}
              onChange={(event, newValue) => setBsecMembers(newValue)}
            />
          </Box>
          <Box className={classes.viewtenderinput}>
            <CustomAutoComplete
              width={labelWidth}
              value={scmOfficials}
              label="SCM Officials"
              onChange={(event, newValue) => setScmOfficials(newValue)}
              options={SCMlist}
            />
          </Box>
          {tenderType === "private" && (
            <Box className={classes.viewtenderinput}>
              <CustomAutoComplete
                width={labelWidth}
                value={bidders}
                label="Bidders"
                onChange={(event, newValue) => setBidders(newValue)}
                options={biddersList}
              />
            </Box>
          )}
          <Box className={classes.filelist}>
            <FileList uploadList={uploadFiles} />
          </Box>
          <Box className={classes.fileinfotext}>
            <Typography style={{ color: sentechBlue, fontSize: "14px" }}>Please upload only 1 <strong>.zip</strong> file. If the incorrect file was uploaded, simply replace it by uploading the correct file and it will override the incorrect file.</Typography>
          </Box>
          <Box className={classes.dragdropbox}>
            <FileUpload
              fileList={(files) => {
                setUploadFiles(files.map((file) => ({ file })));
              }}
              accepted={{ "application/zip": [".zip"] }}
              maxFiles={1}
              error={(e) => {
                setMessage(e);
                setSeverity("error");
                openSnackbar();
              }}
            />
          </Box>
          <Box className={classes.fileinfotext}>
            <Typography style={{ color: sentechBlue, fontSize: "14px" }}>All fields are required to add a tender.</Typography>
          </Box>
          <Box className={classes.buttonboxes}>
            <CustomButtonOutlined onClick={handleCancel} text="Cancel" />
            <CustomButtonSolid
              onClick={handleOnSubmit}
              text="Add New Tender"
              disabled={
                !category ||
                !tenderDescription ||
                !tenderNumber ||
                !tenderType ||
                !briefingSession ||
                !issueDate ||
                !closing ||
                !uploadFiles.length
              }
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default AddTender;
