import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Link,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import axios from "axios";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { endpoint } from "../../../endpoint/endpoints";
import TenderTable from "../../tenderTables/tenderTable";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import { tenderErorr } from "../../../apiRequests/errorMessages";
import CustomPagination from "../../layout/pagination/pagination";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    table: {
      // paddingLeft: "4%",
      // paddingLeft: "15%",
      // paddingRight: "14.5%",
      // display: "flex",
      // justifyContent: "center",
      // paddingTop: "2%",
      // margin: "1%",
      marginLeft: "16%",
      marginRight: "17%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "1%",
      margin: "1%",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "1%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },

    notendersbox: {
      justifyContent: "center",
    },

    notendersheading: {
      paddingLeft: "7.5%",
      paddingTop: "2%",
    },

    notenderslink: {
      paddingTop: "4%",
      paddingBottom: "5%",
      display: "flex",
      justifyContent: "center",
    },
  })
);

function MyTendersPage() {
  // material ui
  const classes = useStyles();

  const {
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    popMyTenders,
    myTenders,
    categories,
    popCategories,
    setSeverity,
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    setLoading(false);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}

    try {
      const response = await axios.get(
        endpoint.LIST_MYTENDER_ENDPOINT, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message;
          popMyTenders(data);
          setLoading(false);
        } else {
          setMessage(tenderErorr(response.data.errorcode));
          response.data.errorcode === 5
            ? setSeverity("info")
            : setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  //page title seen on browser
  React.useEffect(() => {
    document.title = "My tenders";
  }, []);

  React.useEffect(() => {
    const refresh = setInterval(() =>
    handleOnPageLoad() 
    ,2820
    );
    return () => {
      clearInterval(refresh)
    };
  }, []);

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(myTenders.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  // function for empty tenders page
  function getTenders() {
    if (myTenders.length) {
      return (
        <Box>
          <Box className={classes.table}>
            <TenderTable
              tableData={myTenders.filter((itm) => itm.state !== "invited")}
              page={page}
              perPage={perPage}
              useCase={"bidderMyTendersList"}
            />
          </Box>
          <Box className={classes.paginations}>
            <CustomPagination onNewPage={onNewPage} count={maxPage} />
          </Box>
        </Box>
      );
    } else {
      return (
        <Box className={classes.notendersbox}>
          <Typography className={classes.notendersheading}>
            There are no tenders added to your list. You can add a tender to
            your profile by choosing to respond to the tender.
          </Typography>
          <Typography className={classes.notenderslink}>
            <Link style={{ color: sentechBlue }} href={"./OPENTENDERS"}>
              Back to Open Tenders
            </Link>
          </Typography>
        </Box>
      );
    }
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>MY TENDERS</Typography>
      </Box>
      <Box className={classes.table}>{getTenders()}</Box>
    </Box>
  );
}

export default MyTendersPage;
