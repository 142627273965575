const tenderResponseError = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //Could not delete file to s3
    case 1:
      return "The file was not able to be deleted. Please try again.";

    //Could not write to users table
    case 2:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Unable to filter key file
    case 3:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query table
    case 4:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not get s3 signed url
    case 5:
      return "An error occurred. Please try refreshinng the page.";

    //Could not delete user to users table
    case 6:
      return "User was not be able to be deleted. Please try again.";

    //Could not delete file
    case 7:
      return "File was not be able to be deleted. Please try again.";

    //User is not authorized to perform this action
    case 9:
      return "You are not authorized to perform this action.";

    //Could not upload file to s3
    case 10:
      return "The file was not able to be uploaded at this time. Please try again later.";

    //Invalid token
    case 11:
      return "An error occurred. Please try again.";

    //Invalid user
    case 12:
      return "Please ensure that you upload files before you submit.";

    //Invalid object
    case 13:
      return "An error occurred. Please try again.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const userError = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //User is not found
    case 1:
      return "User was not able to be found. Please try again.";

    //Could not structure response
    case 2:
      return "An error occurred. Please try again.";

    //Could not query users table
    case 3:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not put to users table
    case 4:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not delete user
    case 5:
      return "User was not able to be deleted at this time. Please try again later.";

    //Could not query sentech user table
    case 6:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query bidders table
    case 7:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query extended table
    case 8:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not put to bidders table
    case 9:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not put to Sentech user table
    case 10:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not put to delete table
    case 11:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not delete bidder
    case 12:
      return "Bidder was not able to be deleted at this time. Please try again later.";

    //Could not delete Sentech user
    case 13:
      return "User was not able to be deleted at this time. Please try again later.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const tenderErorr = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //Invalid user
    case 1:
      return "The user appears invalid. Please try again.";

    //Could not get signed url
    case 2:
      return "An error occurred. Please try refreshinng the page.";

    //Could not scan tenders table
    case 3:
      return "An error occurred. Please try again.";

    //Could not query myTenders table
    case 4:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not batchGet TendersTable
    case 5:
      return "No previous entries were found";

    //Could not query tenders table
    case 6:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query TendersForumTable
    case 7:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query submissionTable
    case 8:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const userManagementError = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //Email already in use
    case 1:
      return "This email has already been registered.";

    //Could not write user to users table
    case 2:
      return "An error occurred. Please try again.";

    //Could not query users table
    case 4:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not scan users table
    case 5:
      return "An error occurred. Please try again.";

    //Could not delete user to users table
    case 6:
      return "User was not able to be deleted at this time. Please try again later.";

    //User is not authorized to perform this action
    case 9:
      return "You are not authorized to perform this action.";

    //Could not hash password
    case 10:
      return "An error occurred.";

    //Invalid token
    case 11:
      return "An error occurred. Please try again.";

    //Invalid user
    case 12:
      return "The user appears invalid. Please try again.";

    //Unable to send password
    case 13:
      return "Password was not able to be sent. Please try again.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const tenderManagementError = (errorCode) => {
  switch (errorCode) {
    //Requires additional input parameters
    case 0:
      return "An error occurred.";

    //missing input fields
    case 1:
      return "Please fill in all input fields.";

    //Could not query tenders table
    case 2:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query mytenders table
    case 4:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not write to tenders table
    case 5:
      return "An error occurred.";

    //Could not write to mytenders table
    case 6:
      return "An error occurred.";

    //Failed to delete tender
    case 9:
      return "The Tender was not able to be deleted. Please try again.";

    //Expired JWT token
    case 10:
      return "An error occurred.";

    //Failed to Delete tender
    case 11:
      return "The Tender was not able to be deleted. Please try again.";

    //Failed to Put tender
    case 12:
      return "An error occurred.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const submissionReviewError = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //Unauthorized
    case 1:
      return "You are not authorized to perform this action.";

    //Could not query tender submission table
    case 2:
      return "No submissions found";

    //Could not query mytenders table
    case 3:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query tenders table
    case 4:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query tender submission notes table
    case 5:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not get s3 pre-signed url
    case 6:
      return "An error occurred. Please try refreshinng the page.";

    //Could not put to Audit table
    case 7:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const reportError = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //Could not delete file to s3
    case 1:
      return "The file was not able to be deleted. Please try again.";

    //Could not write to users table
    case 2:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query table
    case 4:
      return "No logs were found for this Tender.";

    //Could not get s3 signed url
    case 5:
      return "An error occurred. Please try refreshinng the page.";

    //Could not delete user to users table
    case 6:
      return "The user was not able to be deleted. Please try again.";

    //Could not delete file
    case 7:
      return "The file was not able to be deleted. Please try again.";

    //User is not authorized to perform this action
    case 9:
      return "You are not authorized to perform this action.";

    //Could not upload file to s3
    case 10:
      return "The file was not able to be uploaded. Please try again.";

    //Invalid token
    case 11:
      return "An error occurred. Please sign out and login again";

    //Invalid user
    case 12:
      return "An error occurred.";

    //Invalid object
    case 13:
      return "An error occurred.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

const authError = (errorCode) => {
  switch (errorCode) {
    //missing input fields
    case 0:
      return "Please fill in all input fields.";

    //General error
    case 1:
      return "An error occurred.";

    //Resource does not exist
    case 2:
      return "This email is already registered.";

    //Unable to query bidder table
    case 3:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //phone number already in use
    case 4:
      return "The phone number is already registered. Please try again or use a different number.";

    //Unable to hash password
    case 5:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Could not query admin users table
    case 6:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Unable to write user to bidders table
    case 7:
      return "An error occurred. Please try again later or try signing out and loging in again.";

    //Unable to write user registration challenge
    case 8:
      return "An error occurred. Please try again later ";

    //User is not confirmed
    case 9:
      return "The confirmation email was not able to be sent. Please try again.";

    //Invalid Verification code
    case 10:
      return "The verification code is not valid. Please try again.";

    //Code does not match username or is expired
    case 11:
      return "The verification code is not valid or has expired. Please try again.";

    //The username or password is incorrect
    case 12:
      return "The username or password is incorrect. Please try again.";

    default:
      return "An unexpected error occured, please try again later or contact support";
  }
};

export {
  tenderResponseError,
  userError,
  tenderErorr,
  userManagementError,
  tenderManagementError,
  submissionReviewError,
  reportError,
  authError,
};
