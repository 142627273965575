import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import CustomButtonOutlined from "../../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../../layout/buttons/solidButton";

export default function SubmitDialog({ open, handleClosed, onSubmit }) {
  const handleClick = () => {
    onSubmit(true);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClosed}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you ready to submit your tender response?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButtonOutlined onClick={handleClosed} text="Cancel" />
        <CustomButtonSolid onClick={handleClick} text="Submit your response" />
      </DialogActions>
    </Dialog>
  );
}
