import { Button, createStyles, makeStyles } from "@material-ui/core";
import { sentechBlue } from "../../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginRight:"20px",
      marginLeft:"20px",
      textTransform: "none",
      display: "inline-block",
      "&.MuiButton-outlined": {
        border: `1px solid ${sentechBlue}`,
        textTransform: "none",
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
          boxShadow: "none",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      },
    },
  })
);

export default function CustomButtonOutlined({
  type,
  text,
  disabled,
  onClick,
}) {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      type={type}
      variant="outlined"
      disabled={disabled}
      size="large"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
