import { NavLink } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../../theme/colors";

// styling 
const useStyles = makeStyles((theme) =>
    createStyles({
      sidebarlink: {
        display: "flex",
        cursor: "pointer",
        // borderRight: "2px solid",
        // borderColor: sentechBlue,
        justifyContent: "center",
        textDecoration: "none",
        '&:hover': {
          color: sentechBlue,
          },  
    },
          
    // sidebarlink > p: {
    //   fontSize: "16px",
    //   marginRight: "20px",
    // }
    })
);

function SidebarLink({ text, path }) {

  // material ui 
  const classes = useStyles();

  return(
    <NavLink
      className={classes.sidebarlink}
      to={path}
      style={({isActive}) => ({
      color: isActive ? sentechBlue : sentechLightGrey,
      })}
    >
      {text}
    </NavLink>
  );
}

export default SidebarLink;