import React, { useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {
  Card,
  createStyles,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { CancelOutlined, DeleteOutlineOutlined } from "@material-ui/icons";
import { LinearProgress } from "@mui/material";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import capitalizeFirstLetter from "../../utilities/capitaliseFirstLetter";
import fileSizeDisplay from "../../utilities/filesizeDisplay";

// style for text, progress bar and stacks
const useStyles = makeStyles((theme) =>
  createStyles({
    text1: {
      "& .MuiTypography-body1": { textAlign: "left", color: sentechDarkGrey },
      maxWidth: "33%",
    },
    text2: {
      "& .MuiTypography-body1": {
        textAlign: "right",
        color: sentechDarkGrey,
      },
      marginLeft: "10%",
    },
    text3: {
      "& .MuiTypography-body1": { textAlign: "right", color: sentechDarkGrey },
      marginRight: "5%",
    },
    progressBar: {
      width: "100%",
      marginRight: "5%",
    },
    stack: {
      justifyContent: "space-between",
    },
  })
);

export default function UploadFileCard({
  file,
  type,
  deletable,
  onUpload,
  tender_id,
}) {
  const classes = useStyles();
  // function deleteOnClick() {}
  function cancelOnClick() {}

  const handleOnClick = () => {};

  const [progress, setProgress] = useState(0);
  const mounted = useRef(true);

  useEffect(() => {
    const upload = async () => {
      console.log({ tender_id: tender_id, filename: file.name });
      if (mounted.current) {
        mounted.current = false;
        try {
          const response = await axios.post(
            endpoint.UPLOAD_SUBMISSION_FILE_ENDPOINT, //endpoint
            { tender_id: tender_id, filename: file.name, type: type },
            {
              headers: {
                "user-token": localStorage.getItem("user_token"),
                authorization: localStorage.getItem("authorization"),
              },
            }
          );
          //   const response = apiResponse;
          if (response.status === 200) {
            if (response.data.error === false) {
              // console.log(response.data.message);
              const uploadURL = response.data.message.urlLink;
              try {
                const uploadResult = await axios.put(uploadURL, file, {
                  headers: {
                    "Content-Type": "",
                  },
                  onUploadProgress: (progressEvent) => {
                    let percentComplete =
                      progressEvent.loaded / progressEvent.total;
                    percentComplete = parseInt(percentComplete * 100);
                    console.log(percentComplete);
                    setProgress(percentComplete);
                  },
                });
                if (uploadResult.status === 200) {
                  onUpload(file.name, type);
                }
              } catch (error) {
              }
            } else {
              // setMessage(tenderResponseError(response.data.errorCode));
              // openSnackbar();
            }
          } else {
          }
        } catch (error) {}
      }
    };

    upload();
  }, []);

  return (
    <Card>
      <ListItem key={file.name}>
        <ListItemText className={classes.text1} primary={file.name} />
        <ListItemText
          className={classes.text2}
          primary={capitalizeFirstLetter(type)}
        />
        <ListItemText
          className={classes.text3}
          primary={fileSizeDisplay(file.size)}
        />
      </ListItem>

      <ListItem key={file.name + "prog"} className={classes.stack}>
        <LinearProgress
          className={classes.progressBar}
          value={progress}
          variant="determinate"
        />
        {/* <ListItemIcon onClick={cancelOnClick} style={{ cursor: "pointer" }}>
            <CancelOutlined />
          </ListItemIcon> */}
      </ListItem>

      <Divider />
    </Card>
  );
}
