import React from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles } from "@material-ui/core/styles";
// toast message imports
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { sentechBlue } from "../../../../theme/colors";
import CustomButtonSolid from "../../../layout/buttons/solidButton";
import { useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      width: "100%",
      marginTop: theme.spacing(1),
    },
    wrapText: {
      width: "100%",
      textAlign: "left",
      "& .MuiInputBase-input": {
        textAlign: "left",
      },
    },
    button: {
      margin: theme.spacing(1),
      maxHeight: "4%",
    },
  })
);

export const TextInput = ({ submit }) => {
  const classes = useStyles();

  const [text, setText] = useState("");

  // send button toast message

  const handleClic = () => {
    submit(text);
    setText("");
    // window.location.reload(true);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  return (
    <>
      <form className={classes.wrapForm} noValidate autoComplete="off">
        <TextField
          placeholder="Type your note here"
          className={classes.wrapText}
          variant="outlined"
          multiline
          onChange={handleTextChange}
          value={text}
          //margin="normal"
        />
        {/* <Button variant="contained" color="primary" className={classes.button}>
          <Typography>Send</Typography>
        </Button> */}
        {/* <div > */}
        {/* send button with toast message */}
        <CustomButtonSolid onClick={handleClic} text="Send" />
      </form>
    </>
  );
};
