import { Box, Link } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    bodytext: {
      // paddingLeft: "7.5%",
      paddingTop: "5%",
      justifyContent: "center",
      color: sentechDarkGrey,
      textAlign:"center"
    },

    backtotendersbutton: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
  })
);

function TenderSubmittedPage() {

  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Submitted";
  }, []);

  // navigate
  const navigate = useNavigate();
  const { state } = useLocation();

  function handleClick() {
    navigate("/MYTENDERS");
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>{state.tender_number}</Typography>
      </Box>
      <Box className={classes.bodytext}>
        <Typography>
          Your submission for tender {state.tender_number} has been successfully
          submitted.
        </Typography>
      </Box>
      <Box className={classes.backtotendersbutton}>
        <Link
          sx={{
            color: sentechBlue,
          }}
          onClick={handleClick}
        >
          Back to My Tenders
        </Link>
      </Box>
    </Box>
  );
}

export default TenderSubmittedPage;
