import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageRight: {
      position: "relative",
      marginLeft: "2%",
      marginBottom: "1%",
      padding: "1%",
      maxWidth: "60%",
      //   width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
    },
    messageLeft: {
      position: "relative",
      marginRight: "2%",
      marginBottom: "1%",
      padding: "1%",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
    },

    messageContent: {
      padding: 0,
      margin: 0,
      textAlign: "left",
      color: sentechDarkGrey
    },
    messageContentRight: {
      padding: 0,
      margin: 0,
      textAlign: "left",
      color: sentechBlue,
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "1%",
      bottom: "-3%",
      right: "5%",
    },

    displayName: {
      marginLeft: "2%",
    },
  })
);

export function MessageLeft(props) {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const user = props.user ? props.user : "";
  const prefix = `${user.toUpperCase()} (${timestamp}) `
  const classes = useStyles();
  return (
    <ul key={props.id}>
      <div className={classes.messageRow}>
        <div className={classes.messageLeft}>
          <div>
            <p className={classes.messageContent}>
              <b>
                {prefix}
              </b>
              {message}
            </p>
          </div>
        </div>
      </div>
    </ul>
  );
}

export function MessageRight(props) {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <ul key={props.messageKey}>
      <div className={classes.messageRowRight}>
        <div className={classes.messageRight}>
          <p className={classes.messageContentRight}>{message}</p>
          <div className={classes.messageTimeStampRight}>{timestamp}</div>
        </div>
      </div>
    </ul>
  );
}
