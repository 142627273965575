import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { MenuItem, TextField, Typography } from "@material-ui/core";

import Box from "@material-ui/core/Box";
import { createStyles } from "@mui/material";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      textAlign: "left",
      color: sentechDarkGrey,
      position: "relative",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow:"ellipsis"
    },

    wrapBox: {
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
      color: sentechDarkGrey,
    },

    wrapText: {
      width: "20%",
      textAlign: "left",
      color: sentechDarkGrey,
      position: "relative",

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: sentechDarkGrey,
          border: "0.5px solid",
        },
        "&.Mui-focused fieldset": {
          border: "2px solid",
          borderColor: sentechBlue,
        },
      },

      // "& .MuiInputBase-root": {
      //   maxHeight: "35px"
      // },
      // "& .MuiInputBase-input": {
      //   textAlign: "left",
      //   maxHeight: "35px" ,
      //   // height:"1.2em",
      //   margin:"10px"
      // },
      // "& .MuiOutlinedInput-input":{
      //  padding:"8px"
      // }
    },

    button: {
      margin: theme.spacing(1),
      maxHeight: "40px",
    },
  })
);

// const TenderType = [
//     { label: 'Public' },
//     { label: 'Private' },
// ]

function CustomDropDown({
  options,
  width,
  disabled,
  label,
  name,
  required,
  inputProps,
  type,
  value,
  onChange,
  autoFocus,
  onFocus,
  readOnly,
  error,
  errorMessage,
  defaultValue,
  params,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.wrapBox}>
      <Typography className={classes.label} style={{ width: width }}>
        {label}
      </Typography>
      <TextField
        className={classes.wrapText}
        size="small"
        variant="outlined"
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        id={name}
        required={required}
        InputProps={inputProps}
        select
        value={value}
        onChange={onChange}
        name={name}
        onFocus={onFocus}
        disabled={disabled}
        error={error}
        helperText={errorMessage}
      >
        {options.map((option) => (
          <MenuItem value={option.id} key={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

export default CustomDropDown;
