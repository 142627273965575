// import React, { useState } from "react";
// import {
//   Link,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@material-ui/core";
// import { createStyles, makeStyles } from "@material-ui/core/styles";
// import PDFViewer from "../PDFViewer/PDFViewer";
// import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
// import axios from "axios";
// import { endpoint } from "../../endpoint/endpoints";
// import { useContext } from "react";
// import { ApplicationContext } from "../../context/ApplicationContext";
// import { useRef } from "react";

// // styling
// const useStyles = makeStyles((theme) =>
//   createStyles({
//     container: {
//       border: "0%",
//     },

//     rowheadingtext: {
//       // fontSize: "12px",
//       color: "white",
//       backgroundColor: sentechBlue,
//       borderBottom: "none",
//     },

//     rowtext: {
//       // fontSize: "10px",
//       color: sentechDarkGrey.at,
//       borderBottom: "none",
//     },

//     rowtextDark: {
//       // fontSize: "10px",
//       color: sentechDarkGrey,
//       "&.MuiTableRow-root": { backgroundColor: "rgba(112, 111, 111, 0.06  )" },
//       borderBottom: "none",
//     },
//   })
// );

// export default function ComplianceFilesTable({
//   tableData,
//   page,
//   perPage,
//   useCase,
//   submissionId,
// }) {
//   // const [file, setFile] = useState(
//   //   "https://arxiv.org/pdf/quant-ph/0410102.pdf"
//   // );
//   // const file = useRef();

//   // file.current = "https://arxiv.org/pdf/quant-ph/0410102.pdf";

//   const { setMessage, openSnackbar } = useContext(ApplicationContext);

//   // material ui
//   const classes = useStyles();

//   // table data function
//   function currentData() {
//     const begin = (page - 1) * perPage;
//     const end = begin + perPage;
//     return tableData.slice(begin, end);
//   }

//   function getLink() {
//     // bsec and scm
//     if (useCase === "ComplianceFilesTableList")
//       return (
//         <Typography style={{ color: sentechDarkGrey }}>
//           Compliance Documents
//         </Typography>
//       );

//     if (useCase === "FinancialFilesTableList")
//       return (
//         <Typography style={{ color: sentechDarkGrey }}>
//           Financial Documents
//         </Typography>
//       );

//     if (useCase === "TechnicalFilesTableList")
//       return (
//         <Typography style={{ color: sentechDarkGrey }}>
//           Technical Documents
//         </Typography>
//       );
//   }

//   return (
//     <>
//       <TableContainer component={Paper}>
//         <Typography style={{ color: sentechDarkGrey }}>{getLink()}</Typography>
//         <br />
//         <Table sx={{ minWidth: 650 }} aria-label="simple table">
//           {/* table headings */}
//           <TableHead>
//             <TableRow>
//               <TableCell className={classes.rowheadingtext}>Sr.no.</TableCell>
//               <TableCell className={classes.rowheadingtext}>
//                 File Name
//               </TableCell>
//               <TableCell className={classes.rowheadingtext}>
//                 View File
//               </TableCell>
//               {/* <TableCell className={classes.rowheadingtext}>
//                 Download File
//               </TableCell> */}
//             </TableRow>
//           </TableHead>
//           {/* table data */}
//           <TableBody>
//             {currentData().map((row, index) => (
//               <TableRow
//                 className={index % 2 ? classes.rowtextDark : classes.rowtext}
//                 key={row.key}
//                 sx={{ "&:last-chld td, &:last-child th": { border: 0 } }}
//               >
//                 <TableCell className={classes.rowtext}>
//                   {index + 1 + (page - 1) * perPage}
//                 </TableCell>
//                 <TableCell className={classes.rowtext}>
//                   {row.key.split("-").pop()}
//                 </TableCell>
//                 {/* <TableCell className={classes.rowtext}>
//                   <FileViwer submissionId={submissionId} info={row} />{" "}
//                 </TableCell> */}
//                 <TableCell className={classes.rowtext}>
//                   <FileDownloader submissionId={submissionId} info={row} />{" "}
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </>
//   );
// }

// function FileViwer({ submissionId, info }) {
//   const { authState, setMessage, openSnackbar, setLoading, setSeverity } =
//     useContext(ApplicationContext);

//   const [accessData, setAccessData] = useState({
//     data: "",
//     loading: true,
//   });

//   const [open, setOpen] = useState(false);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   //TODO: update api

//   const handleClickOpen = async () => {
//     console.log({
//       id: submissionId,
//       file: { bucket: info.bucket, key: info.key, region: info.region },
//       action: "view",
//     });
//     try {
//       const response = await axios.post(
//         endpoint.GET_SUBMISSION_FILE, //endpoint
//         {
//           id: submissionId,
//           file: { bucket: info.bucket, key: info.key, region: info.region },
//           action: "view",
//         }, //TODO: update id
//         {
//           headers: {
//             "user-token": localStorage.getItem("user_token"),
//             authorization: localStorage.getItem("authorization"),
//           },
//         }
//       );
//       //   const response = apiResponse;
//       console.log(response);
//       if (response.status === 200) {
//         if (response.data.error === false) {
//           const data = response.data.message;
//           setAccessData({ data: data, loading: false });
//         } else {
//           setMessage("could not fetch file");
//           setSeverity("error");
//           openSnackbar();
//         }
//       } else {
//         console.log("http error");
//       }
//     } catch (error) {}
//   };

//   return accessData.loading ? (
//     <Link
//       onClick={handleClickOpen}
//       style={{
//         color: sentechBlue,
//       }}
//       type="submit"
//     >
//       Request
//     </Link>
//   ) : (
//     <>
//       <Link
//         style={{ color: sentechBlue }}
//         href={accessData.data + "#toolbar=0"}
//         target="_blank"
//         // download
//         // onClick={() => {
//         //   setOpen(true);
//         // }}
//       >
//         View file
//       </Link>{" "}
//       {/* <PDFViewer
//         open={open}
//         handleClosed={handleClose}
//         file={accessData.data + "#toolbar=0"}
//       /> */}
//     </>
//   );
// }

// function FileDownloader({ submissionId, info }) {
//   const { authState, setMessage, openSnackbar, setLoading, setSeverity } =
//     useContext(ApplicationContext);

//   const [accessData, setAccessData] = useState({
//     data: "",
//     loading: true,
//   });

//   const [open, setOpen] = useState(false);

//   const handleClose = () => {
//     setOpen(false);
//   };

//   //TODO: update api

//   const handleClickOpen = async () => {
//     console.log({
//       id: submissionId,
//       file: { bucket: info.bucket, key: info.key, region: info.region },
//       action: "download",
//     });
//     try {
//       const response = await axios.post(
//         endpoint.GET_SUBMISSION_FILE, //endpoint
//         {
//           id: submissionId,
//           file: { bucket: info.bucket, key: info.key, region: info.region },
//           action: "download",
//         }, //TODO: update id
//         {
//           headers: {
//             "user-token": localStorage.getItem("user_token"),
//             authorization: localStorage.getItem("authorization"),
//           },
//         }
//       );
//       //   const response = apiResponse;
//       if (response.status === 200) {
//         if (response.data.error === false) {
//           const data = response.data.message;
//           setAccessData({ data: data, loading: false });
//         } else {
//           setMessage("could not fetch file");
//           setSeverity("error");
//           openSnackbar();
//         }
//       } else {
//       }
//     } catch (error) {}
//   };

//   return accessData.loading ? (
//     <Link
//       onClick={handleClickOpen}
//       style={{
//         color: sentechBlue,
//       }}
//       type="submit"
//     >
//       Request
//     </Link>
//   ) : (
//     <>
//       <Link
//         style={{ color: sentechBlue }}
//         href={accessData.data}
//         target="_blank"
//         download={info.key.split("-").pop()}
//         // onClick={() => {
//         //   setOpen(true);
//         // }}
//       >
//         Open file
//       </Link>{" "}
//       {/* <PDFViewer
//         open={open}
//         handleClosed={handleClose}
//         file={accessData.data}
//       /> */}
//     </>
//   );
// }

import React, { useState, useContext } from "react";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { ApplicationContext } from "../../context/ApplicationContext";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: "0%",
    },
    rowheadingtext: {
      color: "white",
      backgroundColor: sentechBlue,
      borderBottom: "none",
    },
    rowtext: {
      color: sentechDarkGrey.at,
      borderBottom: "none",
    },
    rowtextDark: {
      color: sentechDarkGrey,
      "&.MuiTableRow-root": { backgroundColor: "rgba(112, 111, 111, 0.06  )" },
      borderBottom: "none",
    },
  })
);

export default function ComplianceFilesTable({
  tableData,
  page,
  perPage,
  useCase,
  submissionId,
}) {
  const { setMessage, openSnackbar } = useContext(ApplicationContext);
  const classes = useStyles();

  function currentData() {
    const begin = (page - 1) * perPage;
    const end = begin + perPage;
    return tableData.slice(begin, end);
  }

  function getLink() {
    if (useCase === "ComplianceFilesTableList")
      return (
        <Typography style={{ color: sentechDarkGrey }}>Compliance Documents</Typography>
      );
    if (useCase === "FinancialFilesTableList")
      return (
        <Typography style={{ color: sentechDarkGrey }}>Financial Documents</Typography>
      );
    if (useCase === "TechnicalFilesTableList")
      return (
        <Typography style={{ color: sentechDarkGrey }}>Technical Documents</Typography>
      );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Typography style={{ color: sentechDarkGrey }}>{getLink()}</Typography>
        <br />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowheadingtext}>Sr.no.</TableCell>
              <TableCell className={classes.rowheadingtext}>File Name</TableCell>
              <TableCell className={classes.rowheadingtext}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData().map((row, index) => (
              <TableRow
                className={index % 2 ? classes.rowtextDark : classes.rowtext}
                key={row.key}
                sx={{ "&:last-chld td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.rowtext}>
                  {index + 1 + (page - 1) * perPage}
                </TableCell>
                <TableCell className={classes.rowtext}>{row.key.split("-").pop()}</TableCell>
                <TableCell className={classes.rowtext}>
                  <FileDownloader submissionId={submissionId} info={row} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function FileDownloader({ submissionId, info }) {
  const { setMessage, setSeverity, openSnackbar } = useContext(ApplicationContext);
  const [accessData, setAccessData] = useState({ data: "", loading: true });
  const classes = useStyles();
  const [showOpenLink, setShowOpenLink] = useState(false);

  const handleRequestClick = async () => {
    try {
      const response = await axios.post(
        endpoint.GET_SUBMISSION_FILE,
        {
          id: submissionId,
          file: { bucket: info.bucket, key: info.key, region: info.region },
          action: "download",
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message;
          setAccessData({ data: data, loading: false });
          setShowOpenLink(true);
        } else {
          setMessage("Could not fetch file");
          setSeverity("error");
          openSnackbar();
        }
      } else {
        console.log("http error");
      }
    } catch (error) {}
  };

  const handleClickOpen = () => {
    if (!accessData.loading) {
      const link = document.createElement("a");
      link.href = accessData.data;
      link.target = "_blank";
      link.download = "";

      fetch(accessData.data)
        .then((response) => {
          if (response.ok) {
            link.addEventListener("click", () => {
              setMessage("Document downloaded successfully");
              setSeverity("success");
              openSnackbar();
              link.click();
            });
            link.click();
          } else {
            setMessage("Document was not downloaded");
            setSeverity("error");
            openSnackbar();
          }
        })
        .catch(() => {
          setMessage("Document was not downloaded");
          setSeverity("error");
          openSnackbar();
        });
    }
  };

  return (
    <>
      {accessData.loading ? (
        <Link onClick={handleRequestClick} style={{ color: sentechBlue }} type="submit">
          Request
        </Link>
      ) : (
        <Link onClick={handleClickOpen} style={{ color: sentechBlue }}>
          Open file
        </Link>
      )}
    </>
  );
}
