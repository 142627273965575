import React, { useState } from "react";
import { Box, Typography, Link,} from "@material-ui/core";
import CustomInput from "../inputFields/Inputfields";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { ApplicationContext } from "../../context/ApplicationContext";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ValidateOTP } from "../inputValidators/inputValidators";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    OTP: {
      textAlign: "center",
    },

    otpinput: {
      margin:"auto",
    },

    OTPheading: {
      paddingTop: "6%",
      paddingBottom: "3%",
    },

    link: {
      paddingTop: "2%",
    },

    buttonboxconfirm: {
      paddingTop: "2%",
      paddingBottom: "5%",
    },
  })
);

const apiResponse = {
  status: 200,
  data: {
    error: !true,
    // errorCode:0,
    message: "success",
  },
};

const OTP = (props) => {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "OTP";
  }, []);

  // set form width
  const labelWidth = "0px";

  const { authState, setMessage, openSnackbar, setLoading,setSeverity } =
    React.useContext(ApplicationContext);

  /* state */
  const [code, setCode] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  /* functions */
  const onChange = (event) => {
    let error = { error: "", errorMessage: "" };
    switch (event.target.name) {
      case "code":
        // setCode(event.target.value);
        error = ValidateOTP(event.target.value);
        setCode((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      default:
        break;
    }
  };

  const navigate = useNavigate();

  const onResendOTP = async () => {
    try {
      const response = await axios.post(
        endpoint.RESEND_OTP_ENDPOINT, //endpoint
        { email: authState.email }
        // {
        //   headers: {
        //     "user-token": localStorage.getItem("user_token"),
        //     authorization: localStorage.getItem("authorization"),
        //   },
        // }
      );
      // const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          setMessage("A new OTP has been sent");
          setSeverity("success")
          openSnackbar();
        } else {
          switch (response.data.errorCode) {
            //missing input fields
            case 0:
              setMessage("Missing input fields");
              // setMessage("OTP is invalid. Please enter correct OTP.");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to query users table
            case 1:
              setMessage("Unable to query users table");
              setSeverity("error")
              openSnackbar();
              break;
            //Invalid email
            case 2:
              setMessage("Invalid email");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to query user IDP table
            case 3:
              setMessage("Unable to query user IDP table");
              setSeverity("error")
              openSnackbar();
              break;
            //Invalid email
            case 4:
              setMessage("A new OTP has been sent");
              setSeverity("success")
              openSnackbar();
              break;
            //No current IDP challenges for users
            case 5:
              setMessage("No current IDP challenges for users");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to update OTP
            case 6:
              setMessage("Unable to update OTP");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to structure output
            case 7:
              setMessage("Unable to structure output");
              setSeverity("error")
              openSnackbar();
              break;
            default:
              break;
          }
        }
      } else {
      }
    } catch (error) {}
  };

  /* BACK END CODE  */
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        endpoint.CONFIRM_REGISTRATION_ENDPOINT, //endpoint
        { email: authState.email, password: authState.password, code: code.value } //body
        // {
        //   headers: {
        //     "user-token": localStorage.getItem("user_token"),
        //     authorization: localStorage.getItem("authorization"),
        //   },
        // }
      );
      if (response.status === 200) {
        if (response.data.error === false) {
          navigate("/accountCreated");
          setLoading(false);
        } else {
          switch (response.data.errorCode) {
            //missing input fields
            case 0:
              setLoading(false);
              setMessage("Missing input fields");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to query users table
            case 1:
              setLoading(false);
              setMessage("Unable to query users table");
              setSeverity("error")
              openSnackbar();
              break;
            //Invalid email
            case 2:
              setLoading(false);
              setMessage("Invalid email");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to query user IDP table
            case 3:
              setLoading(false);
              setMessage("Unable to query user IDP table");
              setSeverity("error")
              openSnackbar();
              break;
            //Invalid email
            case 4:
              setLoading(false);
              setMessage("A");
              setSeverity("error")
              openSnackbar();
              break;
            //Confirmation code
            case 5:
              setLoading(false);
              setMessage("OTP is invalid. Please enter correct code");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to hash password
            case 6:
              setLoading(false);
              setMessage("Unable to hash password");
              setSeverity("error")
              openSnackbar();
              break;
            //Unable to update user IDP challenge
            case 7:
              setLoading(false);
              setMessage("Unable to update user IDP challenge");
              setSeverity("error")
              openSnackbar();
              break;
            default:
              setLoading(false);
              break;
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setMessage("Unexpected error");
      setSeverity("error")
      openSnackbar();
    }
  };

  return (
    <Box className={classes.OTP}>
      <Box className={classes.OTPheading}>
        <Typography>
          You will receive an OTP on your registered email address:
        </Typography>
      </Box>
      <Box>
        <form onSubmit={onSubmit}>
          <Box className={classes.otpinput}>
            <CustomInput
              width={labelWidth}
              value={code.value}
              onChange={onChange}
              label=""
              required
              name="code"
              type="text"
              inputProps={{
                autoComplete: "off",
                minLength: "4",
              }}
              error={code.error}
              errorMessage={code.errorMessage}
            />
          </Box>
          <Box>
            <Typography className={classes.link}>
              {" "}
              <Link onClick={onResendOTP}>Resend OTP</Link>
            </Typography>
          </Box>
          <Box className={classes.buttonboxconfirm}>
            <CustomButtonSolid
              type="submit"
              text="SUBMIT"
              disabled={code.error || !code.value}
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default OTP;
