import { Box, Paper, Typography } from "@material-ui/core";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechDarkGrey } from "../../../theme/colors";

import { MessageLeft, MessageRight } from "./Messages";
import { TextInput } from "./TextInput";

// styling the box and typography
const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: "94%",
      margin: "auto",
      textAlign: "left",
      paddingTop: "30px",
      paddingLeft: "210px;",
    },
    typography: {
      textAlign: "left",
      paddingBottom: "20px",
      color: sentechDarkGrey,
    },
  })
);

function popMessages(messages) {
  // console.log(messages);

  return messages.map((el) => {
    // console.log(el);
    if (el.user === 1) {
      return (
        <MessageRight
          messageKey={el.id}
          message={el.message}
          timestamp={new Date(el.timestamp).toLocaleString("en-NZ", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        />
      );
    }
    return (
      <MessageLeft
        key={el.id}
        message={el.message}
        timestamp={new Date(el.timestamp).toLocaleString("en-NZ", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      />
    );
  });
}

export default function TenderForum({ messages, onNewMessage, handleOnPageReLoad }) {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography
        // variant="h5"
        className={classes.typography}
      >
        TENDER DISCUSSION FORUM
      </Typography>
      <Typography variant="body1" className={classes.typography}>
        Respond to any questions.
      </Typography>
      <Paper style={{ overflowY: "scroll", height: "250px" }}>
        <ul>{popMessages(messages)}</ul>
      </Paper>
      <TextInput submit={ async (message) => {
          onNewMessage(message)
          await handleOnPageReLoad(); 
        } 
      }
      />
      <Typography variant="body1" className={classes.typography}>
        All your responses will be submitted to the SCM Official for approval
        and publishing.
      </Typography>
    </Box>
  );
}
