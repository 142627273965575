import { Cancel } from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ApplicationContext } from "../../../../context/ApplicationContext";
import { sentechBlue } from "../../../../theme/colors";
import capitalizeFirstLetter from "../../../../utilities/capitaliseFirstLetter";
import CustomDropDown from "../../../inputFields/DropdownField";
import CustomButtonOutlined from "../../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../../layout/buttons/solidButton";

export default function NewFileDialog({
  uploadedTypes,
  opened,
  closed,
  untaggedFiles,
  onTaggedFiles,
}) {
  const { setMessage, openSnackbar, setSeverity } =
    useContext(ApplicationContext);
  const handleSubmit = (event) => {
    // const newState = untaggedFiles.map((item, index) => {
    //   return { ...item, type: dropdownVals[index] };
    // });
    for (let index = 0; index < taggedFiles.length; index++) {
      const element = taggedFiles[index];
      if (element.type === "") {
        setMessage("You have not tagged all files");
        setSeverity("error");
        openSnackbar();
        return;
      }
    }
    onTaggedFiles(taggedFiles);
    closed();
  };

  const handleClosed = () => {};

  const onNewdropdownval = (val, indexChanged) => {
    setTaggedFiles(
      taggedFiles.map((file, index) => {
        if (index === indexChanged)
          return {
            ...file,
            type: val,
          };
        return file;
      })
    );
  };

  const initialState = untaggedFiles.map((file, index) => ({
    file,
    type: "",
  }));

  const [taggedFiles, setTaggedFiles] = useState([initialState]);

  useEffect(() => {
    setTaggedFiles(
      untaggedFiles.map((file, index) => ({
        file,
        type: "",
      }))
    );
  }, [untaggedFiles]);

  return (
    <Dialog
      open={opened}
      onClose={closed}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box style={{ paddingLeft: "480px" }}>
            <IconButton style={{ color: sentechBlue }} onClick={closed}>
              <Cancel />
            </IconButton>
          </Box>
          <Box style={{ paddingLeft: "200px", paddingBottom: "20px" }}>
            Files to upload
          </Box>
          {untaggedFiles.map((item, index) => (
            <SingleFile
              onNewdropdownval={onNewdropdownval}
              label={item.name}
              index={index}
              uploadedTypes={uploadedTypes}
              onError={(error) => {
                setMessage(
                  `You have already uploaded a ${capitalizeFirstLetter(
                    error
                  )} file `
                );
                setSeverity("error");
                openSnackbar();
              }}
            />
          ))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          style={{ width: "100%", justifyContent: "center", display: "flex" }}
        >
          <CustomButtonOutlined onClick={closed} text="Cancel" />
          <CustomButtonSolid onClick={handleSubmit} text="Upload" />
        </Box>
      </DialogActions>
    </Dialog>
  );
}

function SingleFile({
  index,
  onNewdropdownval,
  label,
  uploadedTypes,
  onError,
}) {
  const [dropdown, setDropdown] = useState("");

  const labelWidth = "180px";

  const Type = [
    { label: "Compliance", id: "compliance" },
    { label: "Technical", id: "technical" },
    { label: "Financial", id: "financial" },
  ];

  const handleInputChange = (event) => {
    if (label === event.target.name) {
      if (uploadedTypes.includes(event.target.value)) {
        onError(event.target.value);
        return;
      }
      setDropdown(event.target.value);
      onNewdropdownval(event.target.value, index);
    }
  };

  return (
    <Box style={{ paddingBottom: "15px" }}>
      <CustomDropDown
        options={Type}
        width={labelWidth}
        value={dropdown}
        focused={true}
        label={label}
        required
        name={label}
        onChange={handleInputChange}
      />
    </Box>
  );
}
