import React from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import { Box, Typography,} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    confirmpassword: {
      textAlign: "center",
    },

    typography: {
      paddingTop: "3%",
      paddingBottom: "2%",
    },

    link: {
      paddingTop: "2%",
      paddingBottom: "2%",
    },

    confirmpasswordbox: {
      paddingTop: "3%",
      paddingBottom: "5%",
    },
  })
);

function ConfirmPassword() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Confirm password";
  }, []);

  // navigate
  const navigate = useNavigate();

  function handleClick() {
    navigate("/login");
  }

  return (
    <Box className={classes.confirmpassword}>
      <Box>
        <Typography className={classes.typography}>
          Password Changed!
        </Typography>
      </Box>
      <Box>
        <TaskAltIcon sx={{ fontSize: 80 }} />
      </Box>
      <Box>
        <Typography>Your password has been successfully changed.</Typography>
        <Typography>
          Please login with your new credentials.
        </Typography>
      </Box>
      <Box className={classes.confirmpasswordbox}>
        <CustomButtonSolid onClick={handleClick} text="LOGIN" />
      </Box>
    </Box>
  );
}

export default ConfirmPassword;
