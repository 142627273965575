import React, { useState } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import CustomInput from "../inputFields/Inputfields";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { ApplicationContext } from "../../context/ApplicationContext";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import {
  ValidateOTP,
  ValidatePassword,
} from "../inputValidators/inputValidators";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    changepassword: {
      textAlign: "center",
    },

    typography: {
      paddingTop: "5%",
      paddingBottom: "2%",
    },

    link: {
      paddingTop: "2%",
      paddingBottom: "2%",
    },

    changepasswordbox: {
      paddingTop: "3%",
      paddingBottom: "5%",
    },
    textbox: {
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
    },
    text: {
      display: "flex",
      justifyContent: "left",
      // paddingTop: "3%",
      width: labelWidth,
      color: sentechDarkGrey,
      textAlign: "left",
      fontSize: "small",
    },
  })
);
const labelWidth = "240px";

const ChangePassword = (props) => {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Change password";
  }, []);

  // application context
  const { authState, setLoading, setMessage, openSnackbar, setSeverity } =
    React.useContext(ApplicationContext);

  /* state */
  const [code, setCode] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });
  const [password, setPassword] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  /* functions */
  const onChange = (event) => {
    let error = { error: "", errorMessage: "" };
    switch (event.target.name) {
      case "code":
        // setCode(event.target.value);
        error = ValidateOTP(event.target.value);
        setCode((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "password":
        // setPassword(event.target.value);
        error = ValidatePassword(event.target.value);
        setPassword((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      default:
        break;
    }
  };

  // navigate
  const navigate = useNavigate();

  /* back end code */
  const onResendOTP = async () => {
    try {
      const response = await axios.post(
        endpoint.RESEND_OTP_ENDPOINT, //endpoint
        { email: authState.email }
        // {
        //   headers: {
        //     "user-token": localStorage.getItem("user_token"),
        //     authorization: localStorage.getItem("authorization"),
        //   },
        // }
      );
      if (response.status === 200) {
        if (response.data.error === false) {
          setMessage("A new OTP has been sent");
          setSeverity("success");
          openSnackbar();
        } else {
          switch (response.data.errorCode) {
            //missing input fields
            case 0:
              setMessage("Missing input fields");
              setSeverity("error");
              openSnackbar();
              break;
            //Unable to query users table
            case 1:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              break;
            //Invalid email
            case 2:
              setMessage("Invalid email");
              setSeverity("error");
              openSnackbar();
              break;
            //Unable to query user IDP table
            case 3:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              break;
            //Invalid email
            case 4:
              setMessage("Invalid email");
              setSeverity("error");
              openSnackbar();
              break;
            //No current IDP challenges for users
            case 5:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              break;
            //Unable to update OTP
            case 6:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              break;
            //Unable to structure output
            case 7:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              break;
            default:
              break;
          }
        }
      } else {
      }
    } catch (error) {}
  };

  const onSubmit = async (event) => {
    setLoading(true);

    // console.log(
    //   "forgot password:" +
    //     JSON.stringify({
    //       email: authState.email, //body
    //       code: code.value,
    //       password: password.value,
    //     })
    // );
    event.preventDefault();
    try {
      const response = await axios.post(
        endpoint.RESET_PASSWORD_ENDPOINT, //endpoint
        {
          email: authState.email, //body
          code: code.value,
          password: password.value,
        }
        // {
        //   headers: {
        //     "user-token": localStorage.getItem("user_token"),
        //     authorization: localStorage.getItem("authorization"),
        //   },
        // }
      );
      if (response.status === 200) {
        if (response.data.error === false) {
          navigate("/confirmPassword");
          setLoading(false);
        } else {
          switch (response.data.errorCode) {
            //missing input fields
            case 0:
              setMessage("Missing input fields");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to query users table
            case 1:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Invalid email
            case 2:
              setMessage("Invalid email");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to query user IDP table
            case 3:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Invalid email
            case 4:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to hash password
            case 5:
              setMessage("OTP is invalid. Please enter correct code.");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to update user IDP table
            case 6:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to hash password
            case 7:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to update password
            case 8:
              setMessage("Unable to update password");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            default:
              setLoading(false);
              break;
          }
        }
      } else {
        setMessage("Unexpected errror occurred");
        setSeverity("error");
        openSnackbar();
        setLoading(false);
      }
    } catch (error) {
      setMessage("Unexpected errror occurred");
      setSeverity("error");
      openSnackbar();
      setLoading(false);
    }
  };

  return (
    <Box className={classes.changepassword}>
      <Box>
        <Typography className={classes.typography}>
          To confirm your new password, please enter the OTP sent to your email
          addresss
        </Typography>
      </Box>
      <Box>
        <form onSubmit={onSubmit}>
          <CustomInput
            width={labelWidth}
            value={code.value}
            onChange={onChange}
            label="OTP:"
            required
            name="code"
            type="text"
            inputProps={{
              autoComplete: "off",
              minLength: "4",
            }}
            error={code.error}
            errorMessage={code.errorMessage}
          />
          <Box>
            <Typography className={classes.link}>
              {" "}
              <Link onClick={onResendOTP}> Resend OTP</Link>
            </Typography>
          </Box>
          <CustomInput
            width={labelWidth}
            label="Password:"
            value={password.value}
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={onChange}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={password.error}
            errorMessage={password.errorMessage}
          />
          <Box className={classes.textbox}>
            <Typography className={classes.text}></Typography>
            <Box style={{ width: "20%" }}>
              <Typography className={classes.text}>
                Password must include:
                <br />
                Atleast 8 characters
                <br />
                Atleast one uppercase letter
                <br />
                Atleast one lowercase letter
                <br />
                Atleast one number
              </Typography>
            </Box>
          </Box>
          <Box className={classes.changepasswordbox}>
            <CustomButtonSolid
              type="submit"
              text="NEXT"
              disabled={
                code.error || !code.value || password.error || !password.value
              }
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default ChangePassword;
