import { Button, createStyles, makeStyles } from "@material-ui/core";
import { sentechBlue } from "../../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      marginLeft: "20px",
      marginRight: "20px",
      display: "inline-block",
      width: "max-content",
      "&.MuiButton-contained": {
        textTransform: "none",
        backgroundColor: sentechBlue,
        color: "white",
        "&:hover": {
          backgroundColor: "#003a73",
          border: "none",
          color: "white",
        },
        "&.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.26)",
          boxShadow: "none",
          backgroundColor: "rgba(0, 0, 0, 0.12)",
        },
      },
    },
  })
);

export default function CustomButtonSolid({ type, text, disabled, onClick }) {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      type={type}
      variant="contained"
      disabled={disabled}
      size="large"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
