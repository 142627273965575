import Link from "./sideBarLink";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../../theme/colors";

// styling 
const useStyles = makeStyles((theme) =>
    createStyles({
      navbar: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "center",
        width: "100%",
        // verticalAlign:"middle",
        // paddingRight: "3%",
        paddingLeft: "5%",
        // backgroundColor: "white",
        // borderBottom: sentechDarkGrey,
        // "2px solid"
        // webkitBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
        // mozBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
        // boxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
      },

      navlink: {
        verticalAlign:"middle",
        // width: "200px",
        // padding: "20px 20px",
        // margin: "10px auto 0 auto",
        // float: "left",
        // borderRadius: "20px",
      },

      profile: {
        textDecoration: "none", 
        color: sentechLightGrey, 
        paddingTop: "1%", 
        "&:hover": {
          textDecoration: "none",
          color: sentechBlue,
        },
      }
    })
);

function BidderSidebar({child}){

  // material ui 
  const classes = useStyles();
    
  return(
    <div className={classes.navbar}>
      {/* <nav className={classes.navbar}> */}
        <Link  text="OPEN TENDERS" path={"/OPENTENDERS"}/>
        <Link text="NOMINATED TENDERS" path={"/NOMINATEDTENDERS"}/>
        <Link text="MY TENDERS" path={"/MYTENDERS"}/>
        <a className={classes.profile} href="/PROFILE">MY PROFILE</a>
        {child}
      {/* </nav> */}
    </div>
  );
}

export default BidderSidebar;