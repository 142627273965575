import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useLocation, useNavigate } from "react-router";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    bodytext: {
      paddingLeft: "7.5%",
      paddingTop: "5%",
      color: sentechDarkGrey,
      textAlign: "center",
    },

    backtotendersbutton: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
  })
);

function TenderDeletedPage() {
  
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Deleted";
  }, []);

  // navigate
  const navigate = useNavigate();
  const { state } = useLocation();

  function handleClick() {
    navigate("/MYTENDERS");
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>{state.tender_number}</Typography>
      </Box>
      <Box className={classes.bodytext}>
        <Typography>
          Tender {state.tender_number} has been deleted from your list.
        </Typography>
      </Box>
      <Box className={classes.backtotendersbutton}>
        <Link
          sx={{
            color: sentechBlue,
          }}
          onClick={handleClick}
        >
          Back to My Tenders
        </Link>
      </Box>
    </Box>
  );
}

export default TenderDeletedPage;
