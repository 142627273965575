import React from "react";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {
  Box,
  Card,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { CancelOutlined, DeleteOutlineOutlined } from "@material-ui/icons";
import { LinearProgress } from "@mui/material";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import CustomButtonOutlined from "../layout/buttons/outlinedButton";
import CustomButtonSolid from "../layout/buttons/solidButton";
import capitalizeFirstLetter from "../../utilities/capitaliseFirstLetter";
import fileSizeDisplay from "../../utilities/filesizeDisplay";

// style for text, progress bar and stacks
const useStyles = makeStyles((theme) =>
  createStyles({
    text1: {
      "& .MuiTypography-body1": {
        textAlign: "left",
        color: sentechDarkGrey,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      width: "33%",

    },
    text2: {
      "& .MuiTypography-body1": {
        textAlign: "left",
        color: sentechDarkGrey,
      },
      marginLeft: "10%",
      
    },
    text3: {
      "& .MuiTypography-body1": { textAlign: "right", color: sentechDarkGrey },
      marginRight: "5%",
    },
    progressBar: {
      width: "100%",
      marginRight: "5%",
    },
    stack: {
      justifyContent: "space-between",
    },
  })
);

export default function FileCard({
  fileKey,
  filename,
  filetype,
  fileSize,
  loadingState,
  deletable,
  onDelete,
  closed,
}) {
  const classes = useStyles();
  // function deleteOnClick() {}
  function cancelOnClick() {}

  const handleOnDelete = () => {
    handleCloseToast();
    onDelete();
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleOnClick = () => {
    setOpenDialog(true);
  };

  const handleCloseToast = (reason) => {
    setOpenDialog(false);
  };
  return (
    <Card>
      <ListItem key={fileKey}>
        <ListItemText className={classes.text1} primary={filename} />
        <ListItemText
          className={classes.text2}
          primary={capitalizeFirstLetter(filetype ?? "")}
        />
        <ListItemText
          className={classes.text3}
          primary={fileSize ? fileSizeDisplay(fileSize) : ""}
        />
        {deletable && !closed ? (
          <IconButton onClick={handleOnClick} style={{ cursor: "pointer" }}>
            <DeleteOutlineOutlined />
          </IconButton>
        ) : (
          <Box style={{ width: "48px" }} />
        )}
        <Dialog
          open={openDialog}
          onClose={handleCloseToast}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Confirm that you want to delete this response?
              <br />
              Once deleted, you will not be able to recover this response.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButtonOutlined
              className={classes.canceldeletebutton}
              onClick={handleCloseToast}
              text="Cancel"
            />

            <CustomButtonSolid
              className={classes.deletebutton}
              onClick={handleOnDelete}
              text="Delete File"
            />
          </DialogActions>
        </Dialog>
      </ListItem>
      {loadingState && (
        <ListItem key={fileKey + "prog"} className={classes.stack}>
          <LinearProgress
            className={classes.progressBar}
            value={loadingState}
            variant="determinate"
          />
          {/* <ListItemIcon onClick={cancelOnClick} style={{ cursor: "pointer" }}>
            <CancelOutlined />
          </ListItemIcon> */}
        </ListItem>
      )}

      <Divider />
    </Card>
  );
}
