import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import CustomPagination from "../layout/pagination/pagination";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { ApplicationContext } from "../../context/ApplicationContext";
import TenderTable from "../tenderTables/tenderTable";
import { tenderErorr } from "../../apiRequests/errorMessages";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    landingpagetable: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    pagination: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
  })
);

function LandingPage() {

  // material ui
  const classes = useStyles();

  const {
    popPublicTenders,
    publicTenders,
    setLoading,
    setMessage,
    openSnackbar,
    categories,
    popCategories,
    setSeverity,
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    setLoading(true);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}

    try {
      const response = await axios.get(
        endpoint.LIST_PUBLIC_ENDPOINT //endpoint
      );
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message.resp_items;
          popPublicTenders(
            data.filter((itm) => itm.close_datetime > Date.now() / 1000)
          );
          setLoading(false);
        } else {
          setMessage(tenderErorr(response.data.errorCode));
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Home";
  }, []);

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(publicTenders.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  return (
    <Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>TENDERS</Typography>
      </Box>
      <Box className={classes.landingpagetable}>
        <TenderTable
          tableData={publicTenders}
          page={page}
          perPage={perPage}
          useCase="landing"
        />
      </Box>
      <Box className={classes.pagination}>
        <CustomPagination onNewPage={onNewPage} count={maxPage} />
      </Box>
    </Box>
  );
}

export default LandingPage;
