import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../login/login";
import ForgotPassword from "../../forgotPassword/forgot_password";
import ChangePassword from "../../forgotPassword/change_password";
import ConfirmPassword from "../..//forgotPassword/confirm_password";
import SignUp from "../../signUp/signUp";
import TnCs from "../../signUp/TnC's";
import OTP from "../../signUp/OTP";
import AccountCreated from "../../signUp/accountCreated";

import LandingPage from "../../landingPage/landingPage";
import SignOut from "../../signOut/signOut";

import { Paper, Backdrop, CircularProgress } from "@material-ui/core";
import { ApplicationContext } from "../../../context/ApplicationContext";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
// import Toast from '../toast/toast';

import TenderDetails from "../../bsec/availableTender/tenderDetails";

import AdminDashboard from "../../sysadmin/adminDashboard/adminDashboard";
import SentechUsers from "../../sysadmin/sentechUsers/sentechUsers";
import AddSentechUser from "../../sysadmin/sentechUsers/addSentechUser";
import ViewUser from "../../sysadmin/sentechUsers/viewUser";
import BiddersList from "../../sysadmin/bidders/biddersList";
import ViewBidder from "../../sysadmin/bidders/viewBidder";

import AddTender from "../../scm/availableTenders/addTender";
import ViewTender from "../../scm/availableTenders/viewTender";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import Toast from "../toast/toast";
import Profile from "../../profile/profilePage";
import OpenTenderPage from "../../bidder/openTender/openTenderPage";
import NominatedTenderPage from "../../bidder/nominatedTender/nominatedTenderPage";

import TenderResponsePage from "../../bidder/myTender/tenderResponsePage";
import MyTendersPage from "../../bidder/myTender/myTendersPage";
import TenderSubmittedPage from "../../bidder/myTender/tenderSubmittedPage";
import TenderDeletedPage from "../../bidder/myTender/deleteTenderPage";
import ConfirmTender from "../../bidder/confirmTender/confirmNominatedTender";
// import Test from "../../test/test";
// import Test1 from "../../test/test1"

import BannerImage from "../../../assets/Sentech e-Portal Banner 4.png";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import { useRef } from "react";
import { useEffect } from "react";
import PageNotFound from "../../pageNotFound/pageNotFound";
import AvailList from "../../sentechUsersTendersPage/availableTenders";
import AvailableTenders from "../../sentechUsersTendersPage/availableTenders";
import ClosedTendersPage from "../../sentechUsersTendersPage/closedTender/closedTendersPage";
import TenderSubmission from "../../sentechUsersTendersPage/closedTender/tenderSubmission";
import TenderResponse from "../../sentechUsersTendersPage/closedTender/tenderResponse";

const useStyles = makeStyles((theme) =>
  createStyles({
    bannerimage: {
      width: "100%",
      height: "8%",
    },

    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 4,
      opacity: "0",
      color: "#E30613",
      background: "#fff !important",
      "& > .MuiCircularProgress-indeterminate": {
        animation:
          "MuiCircularProgress-keyframes-circular-rotate 1s linear infinite",
      },
    },
  })
);

function Container() {
  const classes = useStyles();

  const { loading, snackbarOpened, message, severity, closeSnackbar } =
    React.useContext(ApplicationContext);

  const ref = useRef();

  useEffect(() => {
    const refreshToken = async () => {
      try {
        const response = await axios.post(
          endpoint.REFRESH_ENDPOINT, //endpoint
          {},
          {
            headers: {
              "refresh-token": localStorage.getItem("refresh_token"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const message = response.data.message;
            localStorage.setItem("authorization", message.authorization_token);
            localStorage.setItem("user_token", message.access_token);
            localStorage.setItem("refresh_token", message.refresh_token);
          } else {
          }
        }
      } catch (error) {}
    };
    refreshToken();
    const interval = setInterval(refreshToken, 1500 * 1000);
    ref.current = interval;
    return () => clearInterval(interval);
  }, []);

  return (
    <Paper square className={classes.root}>
      <Navbar />
      <img
        src={BannerImage}
        alt="bannerimage"
        className={classes.bannerimage}
      />
      <Routes>
        {/* <Route path="/test" element={<Test />} />
        <Route path="/test1" element={<Test1 />} /> */}
        {/* auth routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/confirmPassword" element={<ConfirmPassword />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/TnCs" element={<TnCs />} />
        <Route path="/OTP" element={<OTP />} />
        <Route path="/accountCreated" element={<AccountCreated />} />
        <Route path="/signOut" element={<SignOut />} />
        {/* bidders routes */}
        <Route path="/OPENTENDERS" element={<OpenTenderPage />} />
        <Route path="/NOMINATEDTENDERS" element={<NominatedTenderPage />} />

        <Route path="/CONFIRMTENDER" element={<ConfirmTender />} />
        <Route path="/MYTENDERS" element={<MyTendersPage />} />
        <Route path="/MYTENDERS/RESPONSE" element={<TenderResponsePage />} />
        <Route
          path="/MYTENDERS/RESPONSE/SUBMITTED"
          element={<TenderSubmittedPage />}
        />
        <Route
          path="/MYTENDERS/RESPONSE/DELETED"
          element={<TenderDeletedPage />}
        />
        {/* bsec routes */}
        <Route path="/AVAILABLETENDERS" element={<AvailableTenders />} />
        <Route
          path="/AVAILABLETENDERS/TENDERDETAILS"
          element={<TenderDetails />}
        />
        <Route path="/CLOSEDTENDERS" element={<ClosedTendersPage />} />
        <Route
          path="/CLOSEDTENDERS/TENDERSUBMISSION"
          element={<TenderSubmission />}
        />
        <Route
          path="/CLOSEDTENDERS/TENDERSUBMISSION/TENDERRESPONSE"
          element={<TenderResponse />}
        />
        {/* sysadmin routes */}
        <Route path="/DASHBOARD" element={<AdminDashboard />} />
        <Route path="/SENTECHUSERS" element={<SentechUsers />} />
        <Route
          path="/SENTECHUSERS/ADDSENTECHUSER"
          element={<AddSentechUser />}
        />
        <Route path="/SENTECHUSERS/VIEWUSER" element={<ViewUser />} />

        <Route path="/BIDDERS" element={<BiddersList />} />
        <Route path="/BIDDERS/VIEWBIDDER" element={<ViewBidder />} />

        {/* scm routes */}
        <Route path="/AVAILABLETENDERS/ADDTENDER" element={<AddTender />} />
        <Route path="/AVAILABLETENDERS/VIEWTENDER" element={<ViewTender />} />
        {/* <Route path="/AVAILABLETENDERS" element={<TendersList />} /> */}

        {/* <Route path="/TENDERSCLOSED" element={<ClosedTendersListPage />} />
        <Route
          path="/TENDERSCLOSED/SUBMISSION"
          element={<SCMTenderSubmission />}
        />
        <Route
          path="/TENDERSCLOSED/SUBMISSION/RESPONSE"
          element={<SCMTenderResponse />}
        /> */}
        {/* Profile Page */}
        <Route path="/PROFILE" element={<Profile />} />
        {/* <Route path="/T" element={<AvailList />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* toast messages */}
      <Toast
        open={snackbarOpened}
        message={message}
        severity={severity}
        onClose={closeSnackbar}
      />
      {/* loading screen */}
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress
          size={200}
          thickness={0.5}
          style={{ padding: "5px" }}
        />
      </Backdrop>
      <Footer />
    </Paper>
  );
}

export default Container;
