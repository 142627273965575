import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { createStyles } from "@mui/material";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      textAlign: "left",
      color: sentechDarkGrey,
      position: "relative",
      marginTop: "auto",
      marginBottom: "auto",
    },

    wrapBox: {
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
      color: sentechDarkGrey,
    },

    wrapText: {
      width: "20%",
      textAlign: "left",
      color: sentechDarkGrey,
      position: "relative",

      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: sentechDarkGrey,
          border: "0.5px solid",
        },
        "&.Mui-focused fieldset": {
          border: "2px solid",
          borderColor: sentechBlue,
        },
      },

      // "& .MuiInputBase-root": {
      //   maxHeight: "35px"
      // },
      // "& .MuiInputBase-input": {
      //   textAlign: "left",
      //   maxHeight: "35px" ,
      //   // height:"1.2em",
      //   margin:"10px"
      // },
      // "& .MuiOutlinedInput-input":{
      //  padding:"8px"
      // }
    },

    button: {
      margin: theme.spacing(1),
      maxHeight: "40px",
    },
  })
);

function CustomInput({
  width,
  disabled,
  label,
  name,
  required,
  inputProps,
  type,
  value,
  onChange,
  autoFocus,
  onFocus,
  readOnly,
  error,
  errorMessage,
  defaultValue,
  params,
  multiline
}) {
  const classes = useStyles();
  return (
    <Box className={classes.wrapBox}>
      <Typography className={classes.label} style={{ width: width }}>
        {label}
      </Typography>
      <TextField
        style={{ color: sentechDarkGrey }}
        className={classes.wrapText}
        {...params}
        multiline={multiline}
        size="small"
        variant="outlined"
        defaultValue={defaultValue}
        autoFocus={autoFocus}
        id={name}
        required={required}
        InputProps={inputProps}
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        onFocus={onFocus}
        disabled={disabled}
        error={error}
        helperText={errorMessage}
      ></TextField>
    </Box>
  );
}

export default CustomInput;

// const [username, setUsername] = useState({
//   value: "",
//   error: false,
//   errorMessage: "",
// });
// const [password, setPassword] = useState("");

// /* functions */
// const handleInputChange = (event) => {
//   switch (event.target.name) {
//     case "username":
//       const { error, errorMessage } = ValidateEmail(event.target.value);
//       setUsername((prevState) => {
//         return {
//           value: event.target.value,
//           error: error,
//           errorMessage: errorMessage,
//         };
//       });
//       break;

//     case "password":
//       setPassword(event.target.value);
//       break;

//     default:
//       break;
//   }
// };
