import React, { useContext, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Paper,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import { ApplicationContext } from "../../context/ApplicationContext";

import { endpoint } from "../../endpoint/endpoints";
import axios from "axios";
import {
  reportError,
  tenderResponseError,
} from "../../apiRequests/errorMessages";
import findCategory from "../../utilities/findCategory";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    rowheadingtext: {
      // fontSize: "12px",
      color: "white",
      backgroundColor: sentechBlue,
      borderBottom: "none",
    },

    rowtext: {
      // fontSize: "10px",
      color: sentechDarkGrey.at,
      borderBottom: "none",
    },

    rowtextDark: {
      // fontSize: "10px",
      color: sentechDarkGrey,
      "&.MuiTableRow-root": { backgroundColor: "rgba(112, 111, 111, 0.06  )" },
      borderBottom: "none",
    },
  })
);

export default function DashboardTable({ tableData, page, perPage }) {
  // material ui
  const classes = useStyles();

  const { categories } = useContext(ApplicationContext);

  // table data function
  function currentData() {
    const begin = (page - 1) * perPage;
    const end = begin + perPage;
    return tableData.slice(begin, end);
  }

  const now = Date.now() / 1000;

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          style={{ minWidth: "max-content", minHeight: "100%" }}
          aria-label="simple table"
        >
          {/* table headings */}
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowheadingtext}>Sr.no.</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Tender Number
              </TableCell>
              <TableCell className={classes.rowheadingtext}>Status</TableCell>
              <TableCell className={classes.rowheadingtext}>Category</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Tender Type
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Tender Description
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Issue Date
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Briefing Session
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Closing Date
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Submission Report
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Access Log Report
              </TableCell>
            </TableRow>
          </TableHead>
          {/* table data */}
          <TableBody>
            {currentData().map((row, index) => (
              <TableRow
                className={index % 2 ? classes.rowtextDark : classes.rowtext}
                key={row["id"]}
                sx={{ "&:last-chld td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.rowtext}>
                  {index + 1 + (page - 1) * perPage}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.tender_number}
                </TableCell>
                <TableCell className={classes.rowtext}>{row.status}</TableCell>
                <TableCell className={classes.rowtext}>
                  {findCategory(row.category_id, categories)}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.tender_type === "public" ? "Open" : "Nominated"}
                </TableCell>
                <TableCell
                  style={{ width: "350px" }}
                  className={classes.rowtext}
                >
                  {row.tender_description}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {new Date(row.issue_date * 1000).toLocaleString("en-NZ", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {new Date(row.brief_session * 1000).toLocaleString("en-NZ", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {new Date(row.close_datetime * 1000).toLocaleString("en-NZ", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {" "}
                  {row.close_datetime < now ? (
                    <SubmissionDownloader
                      tenderNumber={row.tender_number}
                      id={row.id}
                    />
                  ) : null}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {" "}
                  {row.close_datetime < now ? (
                    <AccessDownloader
                      tenderNumber={row.tender_number}
                      id={row.id}
                    />
                  ) : null}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function SubmissionDownloader({ tenderNumber, id }) {
  const { setMessage, openSnackbar, setSeverity } =
    useContext(ApplicationContext);

  const [submissionData, setSubmissionData] = useState({
    data: "",
    loading: true,
  });

  const headers = [
    { label: "Name", key: "first_name" },
    { label: "Surname", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "Company Name", key: "company_name" },
    { label: "Company Mobile", key: "company_mobile_phone" },
    { label: "Company Telephone", key: "company_phone" },
    { label: "Submit Time", key: "updatedAt" },
    { label: "User Id", key: "user_id" },
    { label: "Tender Id", key: "tender_id" },
    { label: "Files", key: "submission_files" },
  ];

  const downloadSubmission = async () => {
    try {
      const response = await axios.post(
        endpoint.SUBMISSION_REPORT, //endpoint
        { tender_id: id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message.map((itm) => ({
            user_id: itm.user_id,
            first_name: itm.first_name,
            last_name: itm.last_name,
            email: itm.email,
            company_name: itm.company_name,
            company_mobile_phone: JSON.stringify(
              itm.company_mobile_phone.toString()
            ),
            company_phone: JSON.stringify(itm.company_phone),
            submission_files: JSON.stringify(itm.submission_files),
            createdAt: convertUnixToDateTime(itm.createdAt / 1000),
            updatedAt: convertUnixToDateTime(itm.updatedAt / 1000),
            tender_id: itm.tender_id,
          }));
          setSubmissionData({ data: data, loading: false });
        } else {
          setMessage("No submission data found for this tender");
          setSeverity("error");
          openSnackbar();
        }
      } else {
      }
    } catch (error) {}
  };

  return submissionData.loading ? (
    <Link
      onClick={downloadSubmission}
      style={{
        color: sentechBlue,
      }}
      type="submit"
    >
      Request
    </Link>
  ) : (
    <CSVLink
      headers={headers}
      data={submissionData.data}
      separator={";"}
      enclosingCharacter={``}
      style={{
        color: sentechBlue,
      }}
      filename={tenderNumber + " submissionReport.csv"}
    >
      Download
    </CSVLink>
  );
}

function AccessDownloader({ tenderNumber, id }) {
  const { setMessage, openSnackbar, setSeverity } =
    useContext(ApplicationContext);

  const [accessData, setAccessData] = useState({
    data: "",
    loading: true,
  });

  //TODO: update api

  const downloadAccess = async () => {
    try {
      const response = await axios.post(
        endpoint.ACCESS_LOGS, //endpoint
        { resourse_id: id }, //TODO: update id
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message.map((itm) => ({
            email: itm.email,
            first_name: itm.first_name,
            last_name: itm.last_name,
            user_id: itm.user_id,
            action_type: itm.action_type,
            meta: itm.meta.Bucket + "/" + itm.meta.Key,
            createdAt: convertUnixToDateTime(itm.createdAt / 1000),
            updatedAt: convertUnixToDateTime(itm.updatedAt / 1000),
            tender_id: itm.resource_id,
          }));
          if (data.length) setAccessData({ data: data, loading: false });
          else {
            setMessage("No Access Logs have been found for this Tender");
            setSeverity("error");
            openSnackbar();
          }
        } else {
          setMessage(reportError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
        }
      } else {
      }
    } catch (error) {}
  };

  const headers = [
    { label: "Name", key: "first_name" },
    { label: "Surname", key: "last_name" },
    { label: "Email", key: "email" },
    { label: "File", key: "meta" },
    { label: "Action", key: "action_type" },
    { label: "Updated", key: "updatedAt" },
    { label: "User Id", key: "user_id" },
    { label: "Tender Id", key: "tender_id" },
  ];
  return accessData.loading ? (
    <Link
      onClick={downloadAccess}
      style={{
        color: sentechBlue,
      }}
      type="submit"
    >
      Request
    </Link>
  ) : (
    <CSVLink
      headers={headers}
      separator={";"}
      enclosingCharacter={``}
      style={{
        color: sentechBlue,
      }}
      data={accessData.data}
      filename={tenderNumber + " accessReport.csv"}
    >
      Download
    </CSVLink>
  );
}

function convertUnixToDateTime(unixTime) {
  // console.log(unixTime)
  const date = new Date(unixTime * 1000);
  const pad = function (num) {
    return (num < 10 ? "0" : "") + num;
  };

  // return `${date.getFullYear()}-${date.}-${date.getDate()}T${date.getHours()}:${date.getMinutes()}`
  const newDate =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds());
  return newDate;
  // return date.toISOString().split(".")[0];
}
