import React, { useContext, useState } from "react";
import { Box, InputAdornment, Link, Typography } from "@material-ui/core";
import Sidebar from "../layout/biddersSidebar/sideBar";
import BreadCrumb from "../layout/breadcrumbs/breadcrumbs";
import { useNavigate } from "react-router";
import CustomInput from "../inputFields/Inputfields";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import { ApplicationContext } from "../../context/ApplicationContext";
import CustomButtonSolid from "../layout/buttons/solidButton";
import CustomButtonOutlined from "../layout/buttons/outlinedButton";
import { endpoint } from "../../endpoint/endpoints";
import axios from "axios";
import { userError } from "../../apiRequests/errorMessages";
// import {
//   ValidateCompanyName,
//   ValidateCSDNumber,
//   ValidateEmail,
//   ValidateName,
//   ValidateNumber,
//   ValidateRegistrationNumber,
// } from "../inputValidators/inputValidators";
import AddIcon from '@mui/icons-material/Add';

const profileInfo = {
  name: "younos",
  surname: "mohamed",
  companyName: "aizatron",
  companyEmail: "younos@aizatron.com",
  companyTelephone: "021134567",
  companyRegistration: "reg1234",
  companyCSD: "csd1234",
};

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },

    profileinput: {
      paddingLeft: "6%",
    },

    editbutton: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "5%",
    },

    profilebuttonbox: {
      paddingTop: "3%",
      paddingBottom: "5%",
      paddingLeft: "40%",
      paddingRight: "35%",
      display: "flex",
      justifyContent: "space-between",
    },

    resetpasswordlink: {
      paddingTop: "3%",
      paddingLeft: "47%",
    },
  })
);

function BidderProfilePage() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Profile";
  }, []);

  // set form width
  const labelWidth = "200px";

  const navigate = useNavigate();

  //   function handleClick() {
  //     navigate("/BIDDERSPROFILE/EDITPROFILE");
  //   }

  const {
    popAuthState,
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    setSeverity,
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    if (authState.id) return;
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.GET_USER_ENDPOINT,
        { id: localStorage.getItem("id") }, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          popAuthState({
            isLoggedIn: true,
            id: message.id,
            email: message.email,
            firstName: message.first_name,
            lastName: message.last_name,
            companyPhone: message.company_phone,
            csd: message.csd_number,
            companyName: message.company_name,
            reg: message.reg_number,
            companyMobile: message.company_mobile_phone,
            role: "bidder", //message.role,
          });
          setName(message.first_name);
          setSurname(message.last_name);
          setCompanyCSD(message.csd_number);
          setCompanyEmail(message.email);
          setcompanyRegistration(message.reg_number);
          setCompanyTelephone(message.company_phone);
          setCompanyMobile(message.company_mobile_phone);
          setCompanyName(message.company_name);
          setLoading(false);
        } else {
          setMessage(userError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
        console.log("http error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  const [name, setName] = useState(authState.name ?? "" );
  const [surname, setSurname] = useState(authState.surname ?? "");
  const [companyName, setCompanyName] = useState(authState.companyName ?? "");
  const [companyEmail, setCompanyEmail] = useState(authState.companyEmail ?? "");
  const [companyTelephone, setCompanyTelephone] = useState(
    authState.companyTelephone ?? ""
  );
  const [companyMobile, setCompanyMobile] = useState(
    authState.companyMobile ?? ""
  );
  const [companyRegistration, setcompanyRegistration] = useState(
    authState.companyRegistration ?? ""
  );
  const [companyCSD, setCompanyCSD] = useState(authState.companyCSD ?? "");

  const [disabled, setDisabled] = useState(true);

  function handleDisabled() {
    setDisabled(!disabled);
  }

  function handleCancel() {
    setName(authState.firstName);
    setSurname(authState.lastName);
    setCompanyCSD(authState.csd);
    setcompanyRegistration(authState.reg);
    setCompanyTelephone(authState.companyPhone);
    setCompanyMobile(authState.companyMobile);
    setCompanyName(authState.companyName);
    setDisabled(!disabled);
  }

  function handleResetClick() {
    navigate("/forgotPassword");
  }

  /* functions */
  const handleInputChange = (event) => {
    // let error = { error: "", errorMessage: "" };
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        // error = ValidateName(event.target.value);
        // setName(
        //   (prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "surname":
        setSurname(event.target.value);
        // error = ValidateName(event.target.value);
        // setSurname((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "companyName":
        setCompanyName(event.target.value);
        // error = ValidateCompanyName(event.target.value);
        // setCompanyName((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "companyEmail":
        setCompanyEmail(event.target.value);
        // error = ValidateEmail(event.target.value);
        // setCompanyEmail((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "companyTelephone":
        setCompanyTelephone(event.target.value);
        // error = ValidateNumber(event.target.value);
        // setCompanyTelephone((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "companyMobile":
        setCompanyMobile(event.target.value);
        // error = ValidateNumber(event.target.value);
        // setCompanyMobile((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "companyRegistration":
        setcompanyRegistration(event.target.value);
        // error = ValidateRegistrationNumber(event.target.value);
        // setcompanyRegistration((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      case "companyCSD":
        setCompanyCSD(event.target.value);
        // error = ValidateCSDNumber(event.target.value);
        // setCompanyCSD((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        break;

      default:
        break;
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    handleDisabled();
    // console.log(
    //   JSON.stringify({
    //     id: localStorage.getItem("id"),
    //     first_name: name,
    //     last_name: surname,
    //     company_name: companyName,
    //     company_phone: companyTelephone,
    //     company_mobile_phone: companyMobile,
    //     reg_number: companyRegistration,
    //     csd_number: companyCSD,
    //     // role: "bidder", //update
    //   })
    // );
    try {
      const response = await axios.post(
        endpoint.EDIT_USER_ENDPOINT, //endpoint
        {
          id: localStorage.getItem("id"),
          first_name: name,
          last_name: surname,
          company_name: companyName,
          company_phone: companyTelephone,
          company_mobile_phone: companyMobile,
          reg_number: companyRegistration,
          csd_number: companyCSD,
          // role: "bidder", //update
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          popAuthState({
            isLoggedIn: true,
            id: authState.id,
            email: authState.email,
            firstName: name,
            lastName: surname,
            companyPhone: companyTelephone,
            companyMobile: companyMobile,
            csd: companyCSD.value,
            companyName: companyName,
            reg: companyRegistration,
            role: "bidder",
          });
          setMessage("Profile Updated Successfully!");
          setSeverity("success");
          openSnackbar();
        } else {
          setMessage(userError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>PROFILE</Typography>
      </Box>
      <Box>
        <form
        // onSubmit={handleOnSubmit}
        >
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={name}
              focused={true}
              onChange={handleInputChange}
              label="Name:"
              required
              name="name"
              type="text"
              // error={name.error}
              // errorMessage={name.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={surname}
              focused={true}
              onChange={handleInputChange}
              label="Surname:"
              required
              name="surname"
              type="text"
              // error={surname.error}
              // errorMessage={surname.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={companyName}
              focused={true}
              onChange={handleInputChange}
              label="Company Name:"
              required
              name="companyName"
              type="text"
              // error={companyName.error}
              // errorMessage={companyName.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={companyEmail}
              focused={true}
              onChange={handleInputChange}
              label="Company Email:"
              required
              name="companyEmail"
              type="text"
              // error={companyEmail.error}
              // errorMessage={companyEmail.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              focused={true}
              label="Company Telephone:"
              name="companyTelephone"
              required
              type="text"
              value={companyTelephone}
              onChange={handleInputChange}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  <AddIcon style={{ fontSize: "small" }}/>
                </InputAdornment>
                  ),
                }}
              // error={companyTelephone.error}
              // errorMessage={companyTelephone.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              focused={true}
              label="Company Mobile Phone:"
              name="companyMobile"
              required
              type="text"
              value={companyMobile}
              onChange={handleInputChange}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                  <AddIcon style={{ fontSize: "small" }}/>
                </InputAdornment>
                  ),
                }}
              // error={companyMobile.error}
              // errorMessage={companyMobile.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              focused={true}
              value={companyRegistration}
              onChange={handleInputChange}
              label="Company Registration Number:"
              required
              name="companyRegistration"
              type="text"
              inputProps={{
                autoComplete: "companyRegistration",
              }}
              // error={companyRegistration.error}
              // errorMessage={companyRegistration.errorMessage}
            />
          </Box>
          <Box className={classes.profileinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={companyCSD}
              focused={true}
              label="Company CSD Number:"
              name="companyCSD"
              required
              type="text"
              onChange={handleInputChange}
              inputProps={{
                autoComplete: "companyCSD",
              }}
              // error={companyCSD.error}
              // errorMessage={companyCSD.errorMessage}
            />
          </Box>
          {!disabled && (
            <Box className={classes.resetpasswordlink}>
              <Link
                sx={{
                  color: sentechBlue,
                }}
                onClick={handleResetClick}
              >
                Reset password
              </Link>
            </Box>
          )}
          <Box>
            {disabled && (
              <Box className={classes.editbutton}>
                <CustomButtonSolid
                  onClick={handleDisabled}
                  text="Edit Profile"
                />
              </Box>
            )}
            {!disabled && (
              <Box className={classes.profilebuttonbox}>
                <CustomButtonOutlined onClick={handleCancel} text="Cancel" />
                <CustomButtonSolid
                  // type="submit"
                  onClick={handleOnSubmit}
                  text="Save Changes"
                  disabled={
                    !name ||
                    !surname ||
                    !companyName ||
                    !companyTelephone ||
                    !companyMobile ||
                    !companyRegistration
                  }
                />
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default BidderProfilePage;
