import { createStyles, List, makeStyles } from "@material-ui/core";
import FileCard from "./fileCard";
import UploadFileCard from "./uploadFileCard";

// style box
const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: "94%",
      margin: "auto",
      textAlign: "left",
      paddingTop: "30px",
      paddingLeft: "210px;",
    },
  })
);

export default function UploadFileList({
  uploadList,
  tender_id,
  onUploadSuccess,
}) {
  const classes = useStyles();

  if (!uploadList) return;

  return (
    <List className={classes.box}>
      {uploadList?.map((file) => {
        return (
          <UploadFileCard
            // key={file.file.name}
            tender_id={tender_id}
            file={file.file}
            type={file.type}
            onUpload={(fileName, type) => onUploadSuccess(fileName, type)}
          />
        );
      })}
    </List>
  );
}
