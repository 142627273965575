// updated aizatron endpoints --comment for usage
// export const endpoint = {
//   /* AUTH APIS */
//   SIGN_IN_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/signin",
//   REGISTER_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/register",
//   CONFIRM_REGISTRATION_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/confirmregistration",
//   RESEND_OTP_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/resendotp",
//   FORGOT_PASSWORD_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/forgotpassword",
//   RESET_PASSWORD_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/resetpassword",
//   SIGNOUT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/signout",
//   REFRESH_ENDPOINT:
//     "https://nzmc4wu44h.execute-api.us-west-2.amazonaws.com/dev/refreshtoken",
//   // USER API
//   GET_USER_ENDPOINT:
//     "https://c0mvagu3s6.execute-api.us-west-2.amazonaws.com/dev/get",
//   EDIT_USER_ENDPOINT:
//     "https://c0mvagu3s6.execute-api.us-west-2.amazonaws.com/dev/edit",
//   DELETE_USER_ENDPOINT:
//     "https://c0mvagu3s6.execute-api.us-west-2.amazonaws.com/dev/delete",
//   // USER MANAGEMENT API
//   ADMIN_LIST_USER_ENDPOINT:
//     "https://859lopr9hg.execute-api.us-west-2.amazonaws.com/dev/list", 
//   ADMIN_ADD_USER_ENDPOINT:
//     "https://859lopr9hg.execute-api.us-west-2.amazonaws.com/dev/add",
//   ADMIN_EDIT_USER_ENDPOINT:
//     "https://859lopr9hg.execute-api.us-west-2.amazonaws.com/dev/edit", 
//   ADMIN_DELET_USER_ENDPOINT:
//     "https://859lopr9hg.execute-api.us-west-2.amazonaws.com/dev/delete", 
//   // TENDER API
//   LIST_PUBLIC_ENDPOINT:
//     "https://sy08zt64l6.execute-api.us-west-2.amazonaws.com/dev/listpublic",
//   LIST_PRIVATE_ENDPOINT:
//     "https://sy08zt64l6.execute-api.us-west-2.amazonaws.com/dev/listprivate",
//   LIST_MYTENDER_ENDPOINT:
//     "https://sy08zt64l6.execute-api.us-west-2.amazonaws.com/dev/listmytender",
//   GET_TENDER_ENDPOINT:
//     "https://sy08zt64l6.execute-api.us-west-2.amazonaws.com/dev/gettender",  
//   ADMIN_LIST_TEDNERS:
//     "https://sy08zt64l6.execute-api.us-west-2.amazonaws.com/dev/listtender",  
//   // TENDER RESPONSE API
//   RESPOND_ENDPOINT:
//     "https://iu6244z96j.execute-api.us-west-2.amazonaws.com/dev/respond",
//   UPLOAD_SUBMISSION_FILE_ENDPOINT:
//     "https://iu6244z96j.execute-api.us-west-2.amazonaws.com/dev/uploadsubmissionfile",
//   GET_FILE_ENDPOINT:
//     "https://iu6244z96j.execute-api.us-west-2.amazonaws.com/dev/getfile",
//   DELETE_FILE_ENDPOINT:
//     "https://iu6244z96j.execute-api.us-west-2.amazonaws.com/dev/deletefile",
//   SAVE_SUBMISSION_ENDPOINT:
//     "https://iu6244z96j.execute-api.us-west-2.amazonaws.com/dev/savesubmission",
//   DELETE_RESPONSE_ENDPOINT:
//     "https://iu6244z96j.execute-api.us-west-2.amazonaws.com/dev/deleteresponse",
//   // TENDER MANAGEMENT API
//   ADD_TENDER: "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/add",
//   LIST_CATEGORIES:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/ListCategories",
//   EDIT_TENDER:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/edit",
//   DELETE_TENDER:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/delete",
//   UPDATE_TENDER_FILE:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/updatefile",
//   ASSIGN_TENDER:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/assign",
//   REMOVE_USER_TENDER:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/remove",
//   INVITE_BIDDER:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/invite",
//   BIDDER_DECLINE:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/decline",
//   UPDATE_MESSAGE:
//     "https://0je4ig1615.execute-api.us-west-2.amazonaws.com/dev/updatemessage",
//   //Submission review
//   LIST_SUBMISSIONS:
//     "https://u29gfjl9ga.execute-api.us-west-2.amazonaws.com/dev/listsubmissions",
//   GET_SUBMISSION:
//     "https://u29gfjl9ga.execute-api.us-west-2.amazonaws.com/dev/getsubmission",
//   GET_SUBMISSION_FILE:
//     "https://u29gfjl9ga.execute-api.us-west-2.amazonaws.com/dev/getsubmissionfile",
//   //Reporting
//   ACCESS_LOGS:
//     "https://ni3n9e3kec.execute-api.us-west-2.amazonaws.com/dev/accesslogs",
//   SUBMISSION_REPORT:
//     "https://ni3n9e3kec.execute-api.us-west-2.amazonaws.com/dev/submissionreport",

//   WEBSOCKET: 
//   // "wss://4dpyn70ho9.execute-api.eu-west-1.amazonaws.com/prod", 
//     // "wss://rbe6rmrfoj.execute-api.us-west-2.amazonaws.com/prod",
//     "wss://lubf4xbjt1.execute-api.us-west-2.amazonaws.com/prod",
// };

// // sentech dev endpoints --comment for usage
// export const endpoint = {
//   /* AUTH APIS */
//   SIGN_IN_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/signin",
//   REGISTER_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/register",
//   CONFIRM_REGISTRATION_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/confirmregistration",
//   RESEND_OTP_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/resendotp",
//   FORGOT_PASSWORD_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/forgotpassword",
//   RESET_PASSWORD_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/resetpassword",
//   SIGNOUT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/signout",
//   REFRESH_ENDPOINT:
//     "https://gz494z72hi.execute-api.us-west-2.amazonaws.com/dev/refreshtoken",
//   // USER API
//   GET_USER_ENDPOINT:
//     "https://li0lm2r2ba.execute-api.us-west-2.amazonaws.com/dev/get",
//   EDIT_USER_ENDPOINT:
//     "https://li0lm2r2ba.execute-api.us-west-2.amazonaws.com/dev/edit",
//   DELETE_USER_ENDPOINT:
//     "https://li0lm2r2ba.execute-api.us-west-2.amazonaws.com/dev/delete",
//   // USER MANAGEMENT API
//   ADMIN_LIST_USER_ENDPOINT:
//     "https://919ixxx9xj.execute-api.us-west-2.amazonaws.com/dev/list", 
//   ADMIN_ADD_USER_ENDPOINT:
//     "https://919ixxx9xj.execute-api.us-west-2.amazonaws.com/dev/add",
//   ADMIN_EDIT_USER_ENDPOINT:
//     "https://919ixxx9xj.execute-api.us-west-2.amazonaws.com/dev/edit", 
//   ADMIN_DELET_USER_ENDPOINT:
//     "https://919ixxx9xj.execute-api.us-west-2.amazonaws.com/dev/delete", 
//   // TENDER API
//   LIST_PUBLIC_ENDPOINT:
//     "https://wt9om0szw1.execute-api.us-west-2.amazonaws.com/dev/listpublic",
//   LIST_PRIVATE_ENDPOINT:
//     "https://wt9om0szw1.execute-api.us-west-2.amazonaws.com/dev/listprivate",
//   LIST_MYTENDER_ENDPOINT:
//     "https://wt9om0szw1.execute-api.us-west-2.amazonaws.com/dev/listmytender",
//   GET_TENDER_ENDPOINT:
//     "https://wt9om0szw1.execute-api.us-west-2.amazonaws.com/dev/gettender",  
//   ADMIN_LIST_TEDNERS:
//     "https://wt9om0szw1.execute-api.us-west-2.amazonaws.com/dev/listtender",  
//   // TENDER RESPONSE API
//   RESPOND_ENDPOINT:
//     "https://c2dvrkbwt5.execute-api.us-west-2.amazonaws.com/dev/respond",
//   UPLOAD_SUBMISSION_FILE_ENDPOINT:
//     "https://c2dvrkbwt5.execute-api.us-west-2.amazonaws.com/dev/uploadsubmissionfile",
//   GET_FILE_ENDPOINT:
//     "https://c2dvrkbwt5.execute-api.us-west-2.amazonaws.com/dev/getfile",
//   DELETE_FILE_ENDPOINT:
//     "https://c2dvrkbwt5.execute-api.us-west-2.amazonaws.com/dev/deletefile",
//   SAVE_SUBMISSION_ENDPOINT:
//     "https://c2dvrkbwt5.execute-api.us-west-2.amazonaws.com/dev/savesubmission",
//   DELETE_RESPONSE_ENDPOINT:
//     "https://c2dvrkbwt5.execute-api.us-west-2.amazonaws.com/dev/deleteresponse",
//   // TENDER MANAGEMENT API
//   ADD_TENDER: "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/add",
//   LIST_CATEGORIES:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/ListCategories",
//   EDIT_TENDER:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/edit",
//   DELETE_TENDER:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/delete",
//   UPDATE_TENDER_FILE:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/updatefile",
//   ASSIGN_TENDER:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/assign",
//   REMOVE_USER_TENDER:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/remove",
//   INVITE_BIDDER:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/invite",
//   BIDDER_DECLINE:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/decline",
//   UPDATE_MESSAGE:
//     "https://t28lnikxo6.execute-api.us-west-2.amazonaws.com/dev/updatemessage",
//   //Submission review
//   LIST_SUBMISSIONS:
//     "https://dw4qgnl5kj.execute-api.us-west-2.amazonaws.com/dev/listsubmissions",
//   GET_SUBMISSION:
//     "https://dw4qgnl5kj.execute-api.us-west-2.amazonaws.com/dev/getsubmission",
//   GET_SUBMISSION_FILE:
//     "https://dw4qgnl5kj.execute-api.us-west-2.amazonaws.com/dev/getsubmissionfile",
//   //Reporting
//   ACCESS_LOGS:
//     "https://3co6eer977.execute-api.us-west-2.amazonaws.com/dev/accesslogs",
//   SUBMISSION_REPORT:
//     "https://3co6eer977.execute-api.us-west-2.amazonaws.com/dev/submissionreport",
//   // Websocket
//   WEBSOCKET: 
//     "wss://i9iyqy9a00.execute-api.us-west-2.amazonaws.com/prod",
// };

// // sentech qa endpoints --comment for usage
// export const endpoint = {
//   /* AUTH APIS */
//   SIGN_IN_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/signin",
//   REGISTER_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/register",
//   CONFIRM_REGISTRATION_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/confirmregistration",
//   RESEND_OTP_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/resendotp",
//   FORGOT_PASSWORD_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/forgotpassword",
//   RESET_PASSWORD_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/resetpassword",
//   SIGNOUT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/signout",
//   REFRESH_ENDPOINT:
//     "https://25prh9oq5m.execute-api.eu-west-1.amazonaws.com/dev/refreshtoken",
//   // USER API
//   GET_USER_ENDPOINT:
//     "https://r4ravpbyq5.execute-api.eu-west-1.amazonaws.com/dev/get",
//   EDIT_USER_ENDPOINT:
//     "https://r4ravpbyq5.execute-api.eu-west-1.amazonaws.com/dev/edit",
//   DELETE_USER_ENDPOINT:
//     "https://r4ravpbyq5.execute-api.eu-west-1.amazonaws.com/dev/delete",
//   // USER MANAGEMENT API
//   ADMIN_LIST_USER_ENDPOINT:
//     "https://7zjnwnogmh.execute-api.eu-west-1.amazonaws.com/dev/list", 
//   ADMIN_ADD_USER_ENDPOINT:
//     "https://7zjnwnogmh.execute-api.eu-west-1.amazonaws.com/dev/add",
//   ADMIN_EDIT_USER_ENDPOINT:
//     "https://7zjnwnogmh.execute-api.eu-west-1.amazonaws.com/dev/edit", 
//   ADMIN_DELET_USER_ENDPOINT:
//     "https://7zjnwnogmh.execute-api.eu-west-1.amazonaws.com/dev/delete", 
//   // TENDER API
//   LIST_PUBLIC_ENDPOINT:
//     "https://fvbyja7qs8.execute-api.eu-west-1.amazonaws.com/dev/listpublic",
//   LIST_PRIVATE_ENDPOINT:
//     "https://fvbyja7qs8.execute-api.eu-west-1.amazonaws.com/dev/listprivate",
//   LIST_MYTENDER_ENDPOINT:
//     "https://fvbyja7qs8.execute-api.eu-west-1.amazonaws.com/dev/listmytender",
//   GET_TENDER_ENDPOINT:
//     "https://fvbyja7qs8.execute-api.eu-west-1.amazonaws.com/dev/gettender",  
//   ADMIN_LIST_TEDNERS:
//     "https://fvbyja7qs8.execute-api.eu-west-1.amazonaws.com/dev/listtender",  
//   // TENDER RESPONSE API
//   RESPOND_ENDPOINT:
//     "https://50kh631wx7.execute-api.eu-west-1.amazonaws.com/dev/respond",
//   UPLOAD_SUBMISSION_FILE_ENDPOINT:
//     "https://50kh631wx7.execute-api.eu-west-1.amazonaws.com/dev/uploadsubmissionfile",
//   GET_FILE_ENDPOINT:
//     "https://50kh631wx7.execute-api.eu-west-1.amazonaws.com/dev/getfile",
//   DELETE_FILE_ENDPOINT:
//     "https://50kh631wx7.execute-api.eu-west-1.amazonaws.com/dev/deletefile",
//   SAVE_SUBMISSION_ENDPOINT:
//     "https://50kh631wx7.execute-api.eu-west-1.amazonaws.com/dev/savesubmission",
//   DELETE_RESPONSE_ENDPOINT:
//     "https://50kh631wx7.execute-api.eu-west-1.amazonaws.com/dev/deleteresponse",
//   // TENDER MANAGEMENT API
//   ADD_TENDER: "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/add",
//   LIST_CATEGORIES:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/ListCategories",
//   EDIT_TENDER:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/edit",
//   DELETE_TENDER:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/delete",
//   UPDATE_TENDER_FILE:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/updatefile",
//   ASSIGN_TENDER:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/assign",
//   REMOVE_USER_TENDER:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/remove",
//   INVITE_BIDDER:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/invite",
//   BIDDER_DECLINE:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/decline",
//   UPDATE_MESSAGE:
//     "https://nwc135b7ll.execute-api.eu-west-1.amazonaws.com/dev/updatemessage",
//   //Submission review
//   LIST_SUBMISSIONS:
//     "https://qar8cvtgvb.execute-api.eu-west-1.amazonaws.com/dev/listsubmissions",
//   GET_SUBMISSION:
//     "https://qar8cvtgvb.execute-api.eu-west-1.amazonaws.com/dev/getsubmission",
//   GET_SUBMISSION_FILE:
//     "https://qar8cvtgvb.execute-api.eu-west-1.amazonaws.com/dev/getsubmissionfile",
//   //Reporting
//   ACCESS_LOGS:
//     "https://4piqs0hc9i.execute-api.eu-west-1.amazonaws.com/dev/accesslogs",
//   SUBMISSION_REPORT:
//     "https://4piqs0hc9i.execute-api.eu-west-1.amazonaws.com/dev/submissionreport",
//   // Websocket
//   WEBSOCKET: 
//     "wss://lda5uunyp7.execute-api.eu-west-1.amazonaws.com/prod",
// };

// updated sentech prod endpoints --comment for usage
export const endpoint = {
  /* AUTH APIS */
  SIGN_IN_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/signin",
  REGISTER_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/register",
  CONFIRM_REGISTRATION_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/confirmregistration",
  RESEND_OTP_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/resendotp",
  FORGOT_PASSWORD_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/forgotpassword",
  RESET_PASSWORD_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/resetpassword",
  SIGNOUT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/signout",
  REFRESH_ENDPOINT:
    "https://asjdr80a5k.execute-api.af-south-1.amazonaws.com/dev/refreshtoken",
  // USER API
  GET_USER_ENDPOINT:
    "https://s625247rc0.execute-api.af-south-1.amazonaws.com/dev/get",
  EDIT_USER_ENDPOINT:
    "https://s625247rc0.execute-api.af-south-1.amazonaws.com/dev/edit",
  DELETE_USER_ENDPOINT:
    "https://s625247rc0.execute-api.af-south-1.amazonaws.com/dev/delete",
  // USER MANAGEMENT API
  ADMIN_LIST_USER_ENDPOINT:
    "https://3rb64ta6ql.execute-api.af-south-1.amazonaws.com/dev/list", 
  ADMIN_ADD_USER_ENDPOINT:
    "https://3rb64ta6ql.execute-api.af-south-1.amazonaws.com/dev/add",
  ADMIN_EDIT_USER_ENDPOINT:
    "https://3rb64ta6ql.execute-api.af-south-1.amazonaws.com/dev/edit", 
  ADMIN_DELET_USER_ENDPOINT:
    "https://3rb64ta6ql.execute-api.af-south-1.amazonaws.com/dev/delete", 
  // TENDER API
  LIST_PUBLIC_ENDPOINT:
    "https://icvqp75th7.execute-api.af-south-1.amazonaws.com/dev/listpublic",
  LIST_PRIVATE_ENDPOINT:
    "https://icvqp75th7.execute-api.af-south-1.amazonaws.com/dev/listprivate",
  LIST_MYTENDER_ENDPOINT:
    "https://icvqp75th7.execute-api.af-south-1.amazonaws.com/dev/listmytender",
  GET_TENDER_ENDPOINT:
    "https://icvqp75th7.execute-api.af-south-1.amazonaws.com/dev/gettender",  
  ADMIN_LIST_TEDNERS:
    "https://icvqp75th7.execute-api.af-south-1.amazonaws.com/dev/listtender",  
  // TENDER RESPONSE API
  RESPOND_ENDPOINT:
    "https://vmwt2ihz7g.execute-api.af-south-1.amazonaws.com/dev/respond",
  UPLOAD_SUBMISSION_FILE_ENDPOINT:
    "https://vmwt2ihz7g.execute-api.af-south-1.amazonaws.com/dev/uploadsubmissionfile",
  GET_FILE_ENDPOINT:
    "https://vmwt2ihz7g.execute-api.af-south-1.amazonaws.com/dev/getfile",
  DELETE_FILE_ENDPOINT:
    "https://vmwt2ihz7g.execute-api.af-south-1.amazonaws.com/dev/deletefile",
  SAVE_SUBMISSION_ENDPOINT:
    "https://vmwt2ihz7g.execute-api.af-south-1.amazonaws.com/dev/savesubmission",
  DELETE_RESPONSE_ENDPOINT:
    "https://vmwt2ihz7g.execute-api.af-south-1.amazonaws.com/dev/deleteresponse",
  // TENDER MANAGEMENT API
  ADD_TENDER: "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/add",
  LIST_CATEGORIES:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/ListCategories",
  EDIT_TENDER:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/edit",
  DELETE_TENDER:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/delete",
  UPDATE_TENDER_FILE:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/updatefile",
  ASSIGN_TENDER:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/assign",
  REMOVE_USER_TENDER:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/remove",
  INVITE_BIDDER:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/invite",
  BIDDER_DECLINE:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/decline",
  UPDATE_MESSAGE:
    "https://fjpyn7l4x7.execute-api.af-south-1.amazonaws.com/dev/updatemessage",
  //Submission review
  LIST_SUBMISSIONS:
    "https://57rqzfjr89.execute-api.af-south-1.amazonaws.com/dev/listsubmissions",
  GET_SUBMISSION:
    "https://57rqzfjr89.execute-api.af-south-1.amazonaws.com/dev/getsubmission",
  GET_SUBMISSION_FILE:
    "https://57rqzfjr89.execute-api.af-south-1.amazonaws.com/dev/getsubmissionfile",
  //Reporting
  ACCESS_LOGS:
    "https://ip4rskk7fk.execute-api.af-south-1.amazonaws.com/dev/accesslogs",
  SUBMISSION_REPORT:
    "https://ip4rskk7fk.execute-api.af-south-1.amazonaws.com/dev/submissionreport",

  WEBSOCKET: 
    "wss://t2d1ua3i57.execute-api.af-south-1.amazonaws.com/prod",
};