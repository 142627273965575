import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Link, Breadcrumbs } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbslink: {
      textDecoration: "none",
      color: "black",

      "&:hover": {
        textDecoration: "none",
        color: " #0F589D",
      },
    },
  })
);

const BreadCrumb = () => {
  // material ui
  const classes = useStyles();

  // navigate
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const pathnames = pathname.split("/").filter(Boolean);

  return (
    <Breadcrumbs className={classes.breadcrumbslink} aria-label="breadcrumb">
      {pathnames.length ? (
        <Link className={classes.breadcrumbslink} onClick={() => navigate("/")}>
          HOME
        </Link>
      ) : (
        <Typography className={classes.breadcrumbslink}> HOME </Typography>
      )}
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography className={classes.breadcrumbslink} key={name}>
            {path2String(name)}
          </Typography>
        ) : (
          <Link
            className={classes.breadcrumbslink}
            key={name}
            onClick={() => navigate(routeTo)}
          >
            {path2String(name)}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumb;

const path2String = (name) => {
  const path2stringMap = {
    OPENTENDERS: "OPEN TENDERS",
    NOMINATEDTENDERS: "NOMINATED TENDERS",
    MYTENDERS: "MY TENDERS",
    SENTECHUSERS: "SENTECH USERS",
    AVAILABLETENDERS: "AVAILABLE TENDERS",
    CLOSEDTENDERS: "CLOSED TENDERS",
    TENDERDETAILS:"TENDER DETAILS",
    TENDERSUBMISSION:"TENDER SUBMISSION",
    TENDERRESPONSE:"TENDER RESPONSE",
    ADDTENDER:"ADD TENDER",
    VIEWTENDER:"VIEW TENDER",
    CONFIRMTENDER:"CONFIRM TENDER",
    VIEWBIDDER:"VIEW BIDDER",
    ADDSENTECHUSER:"ADD SENTECH USER",
    VIEWUSER:"VIEW USER"
  };
  try {
    return path2stringMap[name] ?? name;
  } catch (err) {
    return name;
  }
};
