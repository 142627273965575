export default function convertUnixToDateTime(unixTime) {
  // console.log(unixTime)
  const date = new Date(unixTime * 1000);
  const pad = function (num) {
    return (num < 10 ? "0" : "") + num;
  };

  // return `${date.getFullYear()}-${date.}-${date.getDate()}T${date.getHours()}:${date.getMinutes()}`
  const newDate =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) 
    // ":" +
    // pad(date.getSeconds());
  return newDate;
  // return date.toISOString().split(".")[0];
}
