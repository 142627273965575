import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import { sentechDarkGrey } from "../../../theme/colors";
import { ApplicationContext } from "../../../context/ApplicationContext";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import CustomInput from "../../inputFields/Inputfields";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import { tenderResponseError, userError } from "../../../apiRequests/errorMessages";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "3%",
      color: sentechDarkGrey,
      paddingBottom: "2%",
    },

    companyinput: {
      paddingLeft: "2%",
    },

    buttonbox1: {
      paddingTop: "4%",
      paddingBottom: "5%",
      paddingLeft: "40%",
      paddingRight: "40%",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

function ConfirmTender() {

  // material ui
  const classes = useStyles();

  const {
    authState,
    setMessage,
    openSnackbar,
    setLoading,
    popAuthState,
    setSeverity,
  } = useContext(ApplicationContext);

  // navigate
  const navigate = useNavigate();
  const { state } = useLocation();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Confirm";
  }, []);

  // set form width
  const labelWidth = "200px";

  const handleCancel = () => {
    navigate("/OPENTENDERS");
  };

  const handleOnPageLoad = async (event) => {
    if (authState.id) return;
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.GET_USER_ENDPOINT,
        { id: localStorage.getItem("id") }, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          popAuthState({
            isLoggedIn: true,
            id: message.id,
            email: message.email,
            firstName: message.first_name,
            lastName: message.last_name,
            companyPhone: message.company_phone,
            csd: message.csd_number,
            companyName: message.company_name,
            reg: message.reg_number,
            companyMobile: message.company_mobile_phone,
            role: "bidder", //message.role,
          });
          setLoading(false);
        } else {
          setMessage(userError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        endpoint.RESPOND_ENDPOINT, //endpoint
        { tender_id: state.id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          setLoading(false);
          setMessage("Tender added to MyTenders");
          setSeverity("success");
          openSnackbar();
          setTimeout(() => {
            navigate("/MYTENDERS");
          }, 2000);
        } else {
          setMessage(tenderResponseError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // bidder info
  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>
          Please confirm your details to respond to tender {state.tender_number}
        </Typography>
      </Box>
      <Box className="formgroup">
        <form
        // onSubmit={handleOnSubmit}
        >
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              focused={true}
              value={authState.firstName}
              label="Name:"
              required
              name="name"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              focused={true}
              value={authState.lastName}
              label="Surname:"
              required
              name="surname"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              focused={true}
              value={authState.companyName}
              label="Company name:"
              required
              name="companyName"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              focused={true}
              value={authState.email}
              label="Email:"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={authState.companyPhone}
              label="Company Tel. No.:"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={authState.companyMobile}
              label="Company Mobile No.:"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={authState.reg}
              label="Company Reg. No.:"
              type="text"
            />
          </Box>
          <Box className={classes.companyinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={authState.csd}
              label="Company CSD. No.:"
              type="text"
            />
          </Box>
          <Box className={classes.buttonbox1}>
            <>
              <CustomButtonOutlined
                className={classes.custombutton}
                onClick={handleCancel}
                text="Cancel"
              />
              <CustomButtonSolid onClick={handleConfirm} text="Confirm" />
            </>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ConfirmTender;
