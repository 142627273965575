import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Paper,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../theme/colors";
import { useNavigate } from "react-router";
import { ApplicationContext } from "../../context/ApplicationContext";
import findCategory from "../../utilities/findCategory";

// styling
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      border: "0%",
    },

    rowheadingtext: {
      // fontSize: "12px",
      color: "white",
      backgroundColor: sentechBlue,
      borderBottom: "none",
    },

    rowtext: {
      // fontSize: "10px",
      color: sentechDarkGrey,
      borderBottom: "none",
    },

    rowtextDark: {
      // fontSize: "10px",
      color: sentechDarkGrey,
      "&.MuiTableRow-root": { backgroundColor: "rgba(112, 111, 111, 0.06  )" },
      borderBottom: "none",
    },
  })
);

export default function TenderTable({ tableData, page, perPage, useCase }) {
  // material ui
  const classes = useStyles();

  const navigate = useNavigate();

  const { setMessage, openSnackbar, categories, setSeverity } =
    useContext(ApplicationContext);

  // table data function
  function currentData() {
    const begin = (page - 1) * perPage;
    const end = begin + perPage;
    return tableData.slice(begin, end);
  }

  function getLink(info) {
    if (useCase === "landing")
      return (
        <Link
          style={{
            color: sentechBlue,
          }}
          onClick={
            localStorage.getItem("bidderRole")
              ? () => {
                  navigate("/CONFIRMTENDER", { state: { ...info } });
                }
              : localStorage.getItem("isLoggedIn")
              ? () => {
                  // navigate("/CONFIRMTENDER", { state: { ...info } });
                  setMessage("Sentech Users cannot respond to tenders");
                  setSeverity("error");
                  openSnackbar();
                }
              : () => {
                  navigate("/login");
                }
          }
        >
          Respond to tender
        </Link>
      );
    // bidder
    if (useCase === "bidderNominatedTenderList")
      return (
        <Link
          style={{
            color: sentechBlue,
          }}
          onClick={() => {
            navigate("/CONFIRMTENDER", { state: { ...info } });
          }}
        >
          Respond to tender
        </Link>
      );

    if (useCase === "bidderOpenTendersList")
      return (
        <Link
          style={{
            color: sentechBlue,
          }}
          onClick={() => {
            navigate("/CONFIRMTENDER", { state: { ...info } });
          }}
        >
          Respond to tender
        </Link>
      );

    if (useCase === "bidderMyTendersList")
      return (
        <Link
          style={{
            color: sentechBlue,
          }}
          onClick={() => {
            navigate("./RESPONSE", { state: { ...info } });
          }}
        >
          My Response
        </Link>
      );

    if (useCase === "bidderTendersResponse") return;

    // bsec
    if (useCase === "bsecClosedTendersList")
      return (
        <Link
          style={{ color: sentechBlue }}
          onClick={() => {
            navigate("./TENDERSUBMISSION", { state: { ...info } });
          }}
        >
          View submissions
        </Link>
      );

    if (useCase === "bsecAvailableTendersList")
      return (
        <Link
          style={{ color: sentechBlue }}
          onClick={() => {
            navigate("./TENDERDETAILS", { state: { ...info } });
          }}
        >
          View forum
        </Link>
      );

    if (useCase === "bsecTenderDetailsList") return;

    // scm
    if (useCase === "scmList")
      return (
        <Link
          style={{ color: sentechBlue }}
          onClick={() => {
            navigate("./VIEWTENDER", { state: { ...info } });
          }}
        >
          View tender
        </Link>
      );

    if (useCase === "scmSubmission")
      return (
        <Link
          style={{ color: sentechBlue }}
          onClick={() => {
            navigate("./SUBMISSION");
          }}
        >
          View submissions
        </Link>
      );

    if (useCase === "scmViewSubmission")
      return (
        <Link
        // style={{color: sentechBlue}}
        // onClick={() => {
        //   navigate("./SUBMISSION");
        // }}
        >
          {/* View submissions */}
        </Link>
      );
  }

  return (
    <>
      <TableContainer component={Paper} className="container">
        <Table style={{ minWidth: "max-content", minHeight: "100%"}} aria-label="simple table">
          {/* table headings */}
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowheadingtext}>Sr.No.</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Tender Number
              </TableCell>
              <TableCell className={classes.rowheadingtext}>Status</TableCell>
              <TableCell className={classes.rowheadingtext}>Category</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Tender Type
              </TableCell>
              <TableCell  className={classes.rowheadingtext}>
                Tender Description
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Issue Date
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Briefing Session
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Closing Date
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Bid Document
              </TableCell>
              <TableCell className={classes.rowheadingtext}>Response</TableCell>
            </TableRow>
          </TableHead>
          {/* table data */}
          <TableBody>
            {currentData().map((row, index) => (
              <TableRow
                className={index % 2 ? classes.rowtextDark : classes.rowtext}
                key={row["id"]}
                sx={{ "&:last-chld td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.rowtext}>
                  {index + 1 + (page - 1) * perPage}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.tender_number}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.status ?? "Advertised"}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {findCategory(row.category_id, categories)}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.tender_type === "public" ? "Open" : "Nominated"}
                </TableCell>
                <TableCell style={{width: "350px"}} className={classes.rowtext}>
                  {row.tender_description}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {new Date(row?.issue_date * 1000).toLocaleString("en-NZ", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {new Date(row?.brief_session * 1000).toLocaleString("en-NZ", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {new Date(row?.close_datetime * 1000).toLocaleString(
                    "en-NZ",
                    {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {" "}
                  {/* <Link
                    style={{
                      color: sentechBlue,
                    }}
                    onClick={() => {
                      row.tender_file.forEach((element) => {
                        window.open(element);
                      });
                    }}
                    // href={row.tender_file[0]}
                    target="_blank"
                    download
                  >
                    Download documents
                  </Link>*/}

{/* <Link
  style={{
    color: sentechBlue,
  }}
  onClick={() => {
    row.tender_file.forEach((element) => {
      const link = document.createElement("a");
      link.href = element;
      link.target = "_blank";
      link.download = "";

      link.addEventListener("click", () => {
        setMessage("Document downloaded successfully");
        setSeverity("success");
        openSnackbar();
        setTimeout(() => {
          window.location.reload(); // Refresh the page after 5 seconds
        }, 8000);
      });

      link.addEventListener("error", (e) => {
        // e.preventDefault(); // Prevents the link from opening in a new tab
        setMessage("Document was not downloaded");
        setSeverity("error");
        openSnackbar();
        setTimeout(() => {
          window.location.reload(); // Refresh the page after 5 seconds
        }, 5000);
      });

      link.click();
    });
  }}
>
  Download documents
</Link> */}

                <Link
                  style={{
                    color: sentechBlue,
                  }}
                  onClick={() => {
                    row.tender_file.forEach((element) => {
                      const link = document.createElement("a");
                      link.href = element;
                      link.target = "_blank";
                      link.download = "";

                      // Perform a separate HTTP request to check the validity of the URL
                      fetch(element)
                        .then((response) => {
                          if (response.ok) {
                            // URL is valid, trigger the download
                            link.addEventListener("click", () => {
                              setMessage("Document downloaded successfully");
                              setSeverity("success");
                              openSnackbar();
                              // setTimeout(() => {
                              //   window.location.reload(); // Refresh the page after 5 seconds
                              // }, 5000);
                            });
                            link.click();
                          } else {
                            // URL is invalid, display error message
                            setMessage("Document was not downloaded");
                            setSeverity("error");
                            openSnackbar();
                            // setTimeout(() => {
                            //   window.location.reload(); // Refresh the page after 5 seconds
                            // }, 5000);
                          }
                        })
                        .catch(() => {
                          // Error occurred during the HTTP request, display error message
                          setMessage("Document was not downloaded");
                          setSeverity("error");
                          openSnackbar();
                          // setTimeout(() => {
                          //   window.location.reload(); // Refresh the page after 5 seconds
                          // }, 5000);
                        });
                    });
                  }}
                >
                  Download documents
                </Link>

                  {" "} 
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {" "}
                  {getLink(row)}{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
