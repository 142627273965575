import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ApplicationContext } from "../../context/ApplicationContext";

import BidderProfilePage from "./bidderProfile";
import SentechUserProfile from "./sentechUserProfile";

export default function Profile() {
  // bsec, scm, sysadmin
  if (localStorage.getItem("bidderRole")) return BidderProfilePage();
  if (
    localStorage.getItem("bsecRole") ||
    localStorage.getItem("adminRole") ||
    localStorage.getItem("scmRole")
  )
    return SentechUserProfile();
}
