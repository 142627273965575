import React, { useContext, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import Sidebar from "../../layout/sysadminSidebar/sideBar";
import CustomInput from "../../inputFields/Inputfields";
// toast message imports
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import { ApplicationContext } from "../../../context/ApplicationContext";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import { userManagementError } from "../../../apiRequests/errorMessages";
import {
  ValidateEmail,
  ValidateName,
  ValidateEmployeeReferenceNumber
} from "../../inputValidators/inputValidators";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },

    viewbidderinput: {
      paddingLeft: "6%",
    },

    role: {
      paddingLeft: "6%",
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
    },

    checkbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: sentechBlue,
      },
    },

    checkboxes: {
      width: "20%",
      color: sentechDarkGrey,
    },

    newuserbuttonbox1: {
      paddingTop: "3%",
      paddingBottom: "5%",
      display: "flex",
      margin: "auto",
      justifyContent: "center",
    },
  })
);

function AddSentechUser() {
  // material ui
  const classes = useStyles();

  const {
    setMessage,
    openSnackbar,
    setLoading,
    popSentechUsersList,
    setSeverity,
  } = useContext(ApplicationContext);

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Add user";
  }, []);

  // set form width
  const labelWidth = "200px";

  // navigation
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [employeeReference, setEmployeeReference] = useState("");
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [checkbox, setCheckbox] = useState({
    admin: false,
    bsec: false,
    scm: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckbox({
      ...checkbox,
      [event.target.name]: event.target.checked,
    });
  };

  const handleInputChange = (event) => {
    // let error = { error: "", errorMessage: "" };
    switch (event.target.name) {
      case "name":
        // error = ValidateName(event.target.value);
        // setName((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        setName(event.target.value);
        break;

      case "surname":
        // error = ValidateName(event.target.value);
        // setSurname((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        setSurname(event.target.value);
        break;

      case "employeeReference":
        // error = ValidateEmployeeReferenceNumber(event.target.value);
        // setEmployeeReference((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        setEmployeeReference(event.target.value);
        break;

      case "email":
        // error = ValidateEmail(event.target.value);
        // setEmail((prevState) => {
        //   return {
        //     value: event.target.value,
        //     error: error.error,
        //     errorMessage: error.errorMessage,
        //   };
        // });
        setEmail(event.target.value);
        break;

      case "department":
        setDepartment(event.target.value);
        break;

      case "designation":
        setDesignation(event.target.value);
        break;

      default:
        break;
    }
  };

  const handleCancel = () => {
    navigate("/SENTECHUSERS");
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    // console.log(
    //   JSON.stringify({
    //     email: email,
    //     first_name: name,
    //     last_name: surname,
    //     employee_ref: employeeReference,
    //     role: Object.keys(checkbox).filter((key) => checkbox[key]),
    //     department: department,
    //     designation: designation,
    //   })
    // );
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.ADMIN_ADD_USER_ENDPOINT, //endpoint
        {
          email: email,
          first_name: name,
          last_name: surname,
          employee_ref: employeeReference,
          role: Object.keys(checkbox).filter((key) => checkbox[key]),
          department: department,
          designaion: designation,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          //pop sentech users is data returned
          popSentechUsersList([
            {
              firstName: message.first_name,
              lastName: message.last_name,
              email: message.email,
              employeeRef: message.employee_ref,
              department: message.department,
              designation: message.designaion,
              role: message.role,
              id: message.id,
            },
          ]);
          setLoading(false);
          navigate("/SENTECHUSERS");
          setMessage("User Succesfully created");
          setSeverity("success");
          openSnackbar();
        } else {
          setMessage(userManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>ADD NEW USERS</Typography>
      </Box>
      <Box>
        <form
        // onSubmit={handleOnSubmit}
        >
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              focused={true}
              value={name}
              onChange={handleInputChange}
              label="Name:"
              required
              name="name"
              type="text"
              inputProps={{
                autoComplete: "first name",
              }}
            //   error={name.error}
            // errorMessage={name.errorMessage}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              focused={true}
              value={surname}
              onChange={handleInputChange}
              label="Surname:"
              required
              name="surname"
              type="text"
              inputProps={{
                autoComplete: "last name",
              }}
              // error={surname.error}
              // errorMessage={surname.errorMessage}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              focused={true}
              value={employeeReference}
              onChange={handleInputChange}
              label="Employee Reference:"
              required
              name="employeeReference"
              type="text"
              inputProps={{
                autoComplete: "employeeReference",
              }}
              // error={employeeReference.error}
              // errorMessage={employeeReference.errorMessage}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              focused={true}
              value={email}
              onChange={handleInputChange}
              label="Email:"
              required
              name="email"
              type="text"
              inputProps={{
                autoComplete: "email",
              }}
              // error={email.error}
              // errorMessage={email.errorMessage}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              focused={true}
              label="Department:"
              name="department"
              required
              type="text"
              value={department}
              onChange={handleInputChange}
              inputProps={{
                autoComplete: "department",
              }}
            /> 
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              focused={true}
              value={designation}
              onChange={handleInputChange}
              label="Designation:"
              required
              name="designation"
              type="text"
              inputProps={{
                autoComplete: "designation",
              }}
            />
          </Box>

          <Box className={classes.role}>
            <Typography style={{ color: sentechDarkGrey, width: labelWidth }}>
              Role:
            </Typography>
            <Box style={{ width: "20%" }} />
          </Box>
          {/* role checkboxes */}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "6%",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            <Box style={{ width: labelWidth }} />
            <Box className={classes.checkboxes}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleCheckboxChange}
                      value={checkbox.admin}
                      name="admin"
                    />
                  }
                  label="Sys Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleCheckboxChange}
                      value={checkbox.scm}
                      name="scm"
                    />
                  }
                  label="SCM Official"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleCheckboxChange}
                      value={checkbox.bsec}
                      name="bsec"
                    />
                  }
                  label="BSEC Member"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box className={classes.newuserbuttonbox1}>
            <CustomButtonOutlined onClick={handleCancel} text="Cancel" />
            <CustomButtonSolid
              onClick={handleOnSubmit}
              text="Add User"
              disabled={
                !name ||
                !surname ||
                !employeeReference ||
                !email ||
                !department ||
                !designation ||
                !(checkbox.admin || checkbox.scm || checkbox.bsec)
              }
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default AddSentechUser;
