import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import Sidebar from "../../layout/bsecSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import TenderSubmissionTable from "../../../components/tenderTables/tenderSubmissionTable";
import ComplianceFilesTable from "../../tenderTables/uploadedFilesTable";
import CustomPagination from "../../layout/pagination/pagination";
import TenderNotes from "./notes/notes";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import axios from "axios";
import { endpoint } from "../../../endpoint/endpoints";
import {
  submissionReviewError,
  tenderErorr,
  tenderManagementError,
} from "../../../apiRequests/errorMessages";
import { useRef } from "react";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    submissiontable: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    filesheading: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "7.5%",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    filestable: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },

    tendernotes: {
      width: "90%",
      display: "flex",
      justifyContent: "center",
    },

    backbuttonbox: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "4%",
      paddingBottom: "5%",
    },
  })
);

function TenderResponse() {
  // material ui
  const classes = useStyles();

  const { setMessage, openSnackbar, setLoading,setSeverity} =
    useContext(ApplicationContext);

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Response";
  }, []);

  // navigate
  const navigate = useNavigate();

  const { state } = useLocation();
  // console.log(state);
  const [submissionFiles, setSubmissionfiles] = useState([]);
  const [tenderSubmissionId, setTenderSubmissionId] = useState("");
  // shows messages
  const [messages, setMessages] = useState([
    {
      id: "m1",
      message: "hello",
      user: "Jack",
      timestamp: 1667774308930,
    },
    {
      id: "m2",
      message: "hello",
      user: "John",
      timestamp: 1667774308930,
    },
  ]);
  const webSocket = useRef();

  const [webSocketState, setwebSocketState] = useState(false);

  function handleClick() {
    navigate("/CLOSEDTENDERS");
  }

  const handleOnPageLoad = async (event) => {
    setLoading(true);

    try {
      const response = await axios.post(
        endpoint.GET_SUBMISSION, //endpoint
        {
          tender_id: state.tender_id,
          user_id: state.id,
        }, //TODO: update id
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const messages = response.data.message.tender_submission_notes.Items.sort(compare)
          const mappedMessages =
            messages.map((itm) => ({
              id: itm.id,
              timestamp: itm.createdAt,
              message: itm.message,
              user: itm.role,
            }));
          setMessages(mappedMessages);
          if (response.data.message.submission_result) {
            const files =
              response.data.message.submission_result[0].submission_files;
            setTenderSubmissionId(
              response.data.message.submission_result[0].id
            );
            setSubmissionfiles(files);
          } else if (response.data.message.tender_results) {
            const files =
              response.data.message.tender_results[0].submission_files;
            setTenderSubmissionId(response.data.message.tender_results[0].id);
            setSubmissionfiles(files);
          }
          setLoading(false);
        } else {
          setMessage(submissionReviewError(response.data.errorCode));
          setSeverity("error")
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleOnPageReLoad = async (event) => {
    setLoading(true)
    await delay(1500)

    try {
      const response = await axios.post(
        endpoint.GET_SUBMISSION, //endpoint
        {
          tender_id: state.tender_id,
          user_id: state.id,
        }, //TODO: update id
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const messages = response.data.message.tender_submission_notes.Items.sort(compare)
          const mappedMessages =
            messages.map((itm) => ({
              id: itm.id,
              timestamp: itm.createdAt,
              message: itm.message,
              user: itm.role,
            }));
          setMessages(mappedMessages);
          if (response.data.message.submission_result) {
            const files =
              response.data.message.submission_result[0].submission_files;
            setTenderSubmissionId(
              response.data.message.submission_result[0].id
            );
            setSubmissionfiles(files);
          } else if (response.data.message.tender_results) {
            const files =
              response.data.message.tender_results[0].submission_files;
            setTenderSubmissionId(response.data.message.tender_results[0].id);
            setSubmissionfiles(files);
          }
          setLoading(false);
        } else {
          setMessage(submissionReviewError(response.data.errorCode));
          setSeverity("error")
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const connectWebsocket = () => {
    const authToken = localStorage.getItem("authorization");
    const userToken = localStorage.getItem("user_token");

    webSocket.current = new WebSocket(
      `${endpoint.WEBSOCKET}?Authorization=${authToken}&user-token=${userToken}`
    );
    webSocket.current.onopen = () => {
      console.log("connected")
      // setwebSocketState(true)
    };
    webSocket.current.onclose = () => {
      console.log("disconnected")
      // setwebSocketState(false)
    };
    webSocket.current.onerror = (e) => console.log(e);
    webSocket.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      switch (data.action) {
        case "message":
          //TODO: filter based on tender id
          // setMessages((prevMessages) => [
          //   ...prevMessages,
          //   {
          //     message: data.message.message.S,
          //     id: data.message.id.S,
          //     user: data.message.role.L[0].S === "bidder" ? 0 : 1, //bidder designated as 0, sentech user designated as 1
          //   },
          // ]);
          break;
        default:
          break;
      }
    };
  };

  React.useEffect(() => {
    // if (webSocketState) return;
    if (webSocket.current) return;
    connectWebsocket();
    return () => {
      if (webSocket.current) {
        webSocket.current.close();
        webSocket.current = null;
      }
    };
  }, [webSocket]);

  // function for files table data
  const perPage = 5;
  // const maxPage = Math.ceil(uploadedFilesTableData.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  return (
    <Box>
      {/* <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box> */}
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>{state.company_name}</Typography>
      </Box>
      <Box className={classes.submissiontable}>
        <TenderSubmissionTable tableData={[state]} page={1} perPage={1} />
      </Box>
      <Box className={classes.filesheading}>
        <Typography>Uploaded Files:</Typography>
      </Box>
      <Box className={classes.filestable}>
        <ComplianceFilesTable
          tableData={submissionFiles.filter((itm) => itm.type === "compliance")}
          page={page}
          perPage={perPage}
          useCase={"ComplianceFilesTableList"}
          submissionId={tenderSubmissionId}
        />
      </Box>
      <Box className={classes.filestable}>
        <ComplianceFilesTable
          tableData={submissionFiles.filter((itm) => itm.type === "technical")}
          page={page}
          perPage={perPage}
          useCase={"TechnicalFilesTableList"}
          submissionId={tenderSubmissionId}
        />
      </Box>
      <Box className={classes.filestable}>
        <ComplianceFilesTable
          tableData={submissionFiles.filter((itm) => itm.type === "financial")}
          page={page}
          perPage={perPage}
          useCase={"FinancialFilesTableList"}
          submissionId={tenderSubmissionId}
        />
      </Box>

      {/* <Box className="paginations">
            <CustomPagination onNewPage={onNewPage} count={maxPage} />
            </Box> */}
      <Box className={classes.tendernotes}>
        <TenderNotes
          messages={messages}
          handleOnPageReLoad={handleOnPageReLoad}
          onNewMessage={(newMessage) => {
            webSocket.current.send(
              JSON.stringify({
                action: "notes",
                message: {
                  message: newMessage,
                  tender_id: state.tender_id,
                },
              })
            );
          }}
        />
      </Box>
      <Box className={classes.backbuttonbox}>
      <CustomButtonOutlined onClick={handleClick} text="Back to Closed Tenders" />
      </Box>
    </Box>
  );
}

export default TenderResponse;

function compare(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
