import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    messageRow: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageRight: {
      position: "relative",
      marginLeft: "20px",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      maxWidth: "60%",
      //   width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
    },
    messageLeft: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      width: "60%",
      //height: "50px",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
    },

    messageContent: {
      padding: 0,
      margin: 0,
      textAlign: "left",
      color: sentechDarkGrey,
    },
    messageContentRight: {
      padding: 0,
      margin: 0,
      textAlign: "left",
      color: sentechBlue,
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: "300",
      marginTop: "10px",
      bottom: "-3px",
      right: "5px",
    },

    displayName: {
      marginLeft: "20px",
    },
  })
);

export function MessageLeft(props, { key }) {
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  const classes = useStyles();
  return (
    <ul key={key}>
      <div className={classes.messageRow}>
        <div className={classes.messageLeft}>
          <div>
            <p className={classes.messageContent}>
              {`(${timestamp}) `}
              {message}
            </p>
          </div>
        </div>
      </div>
    </ul>
  );
}

export function MessageRight(props, { key }) {
  const classes = useStyles();
  const message = props.message ? props.message : "no message";
  const timestamp = props.timestamp ? props.timestamp : "";
  return (
    <ul key={key}>
      <div className={classes.messageRowRight}>
        <div className={classes.messageRight}>
          <p className={classes.messageContentRight}>
          {`(${timestamp}) `}
            {message}
          </p>
        </div>
      </div>
    </ul>
  );
}
