import { Box, Paper, Typography } from "@material-ui/core";
import { MessageLeft, MessageRight } from "./Messages";
import { TextInput } from "./TextInput";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechDarkGrey } from "../../../../theme/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: "94%",
      margin: "auto",
      textAlign: "left",
      paddingTop: "3%",
      paddingLeft: "20%",
    },
    typography: {
      textAlign: "left",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },
  })
);

function popMessages(messages) {
  return messages.map((el) => {
    return (
      <MessageLeft
        key={el.id}
        message={el.message}
        user={el.user}
        timestamp={new Date(el.timestamp).toLocaleString("en-NZ", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      />
    );
  });
}

export default function TenderNotes({ messages, onNewMessage, handleOnPageReLoad }) {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography
        // variant="h5"
        className={classes.typography}
      >
        TENDER EVALUATION NOTES
      </Typography>
      <Typography variant="body1" className={classes.typography}>
        Add any tender evaluation notes.
      </Typography>
      <Paper style={{ overflowY: "scroll", height: "250px" }}>
        <ul>{popMessages(messages)}</ul>
      </Paper>
      <TextInput submit={ async (message) => {
          onNewMessage(message)
          await handleOnPageReLoad(); 
        } 
      }
      />
      <Typography variant="body1" className={classes.typography}>
        Tender evaluation notes are not visible to bidders.
      </Typography>
    </Box>
  );
}
