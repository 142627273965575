import React from 'react';
import Container from './components/layout/container/container';
import ApplicationContextProvider from './context/ApplicationContext';

import {useEffect, useState} from 'react';


function App() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
      <ApplicationContextProvider>
      <Container>{windowSize.innerWidth} {windowSize.innerHeight}</Container>  
      </ApplicationContextProvider>
  );
}

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

export default App;