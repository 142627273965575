import React, { useState } from "react";
import CustomInput from "../inputFields/Inputfields";
import {
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { ApplicationContext } from "../../context/ApplicationContext";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment, Typography } from "@material-ui/core";
import {
  ValidateCompanyName,
  ValidateCSDNumber,
  ValidateEmail,
  ValidateName,
  ValidateNumber,
  ValidatePassword,
  ValidateRegistrationNumber,
} from "../inputValidators/inputValidators";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../theme/colors";
import CustomButtonSolid from "../layout/buttons/solidButton";
import AddIcon from '@mui/icons-material/Add';

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    signup: {
      textAlign: "center",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    text: {
      display: "flex",
      justifyContent: "left",
      // paddingTop: "3%",
      width: labelWidth,
      color: sentechDarkGrey,
      textAlign: "left",
      fontSize: "small",
    },

    checkbox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "3%",
      marginLeft: "5%",
    },

    formbox: {
      paddingTop: "1%",
      alignItems: "center",
    },

    textbox: {
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
    },

    buttonboxsignup: {
      paddingTop: "2%",
      paddingBottom: "5%",
    },

    forminfotext: {
      display: "flex",
      justifyContent: "center",
      color: sentechLightGrey,
      paddingTop: "2%",
    },
  })
);
// set form width
const labelWidth = "240px";

function SignUp() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Sign up";
  }, []);

  /* application context */
  const {
    popAuthState,
    setLoading,
    setMessage,
    setSeverity,
    openSnackbar,
    authState,
  } = React.useContext(ApplicationContext);

  /* state */
  const [first_name, setFirstName] = useState(
    authState.first_name ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [last_name, setLastName] = useState(
    authState.last_name ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [company_name, setCompanyName] = useState(
    authState.company_name ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [username, setUsername] = useState(
    authState.email ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [password, setPassword] = useState(
    authState.password ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [company_phone, setCompanyPhone] = useState(
    authState.company_phone ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [company_mobile, setCompanyMobile] = useState(
    authState.company_mobile_phone ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [reg_number, setCompanyRegistrationNumber] = useState(
    authState.reg_number ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );
  const [csd_number, setCompanyCSDNumber] = useState(
    authState.csd_number ?? {
      value: "",
      error: false,
      errorMessage: "",
    }
  );

  const [checkbox, setCheckbox] = useState({
    tnc: false,
    notif: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  /* functions */
  const handleInputChange = (event) => {
    let error = { error: "", errorMessage: "" };
    switch (event.target.name) {
      case "first_name":
        // setFirstName(event.target.value);
        error = ValidateName(event.target.value);
        setFirstName((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "last_name":
        // setLastName(event.target.value);
        error = ValidateName(event.target.value);
        setLastName((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "company_name":
        // setCompanyName(event.target.value);
        error = ValidateCompanyName(event.target.value);
        setCompanyName((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "username":
        // setUsername(event.target.value);
        error = ValidateEmail(event.target.value);
        setUsername((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "password":
        // setPassword(event.target.value);
        error = ValidatePassword(event.target.value);
        setPassword((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "company_phone":
        // setCompanyPhone(event.target.value);
        error = ValidateNumber(event.target.value);
        setCompanyPhone((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;
      case "company_mobile":
        // setCompanyPhone(event.target.value);
        error = ValidateNumber(event.target.value);
        setCompanyMobile((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "reg_number":
        // setCompanyRegistrationNumber(event.target.value);
        error = ValidateRegistrationNumber(event.target.value);
        setCompanyRegistrationNumber((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "csd_number":
        // setCompanyCSDNumber(event.target.value);
        error = ValidateCSDNumber(event.target.value);
        setCompanyCSDNumber((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      default:
        break;
    }
  };

  const handleCheckboxChange = (event) => {
    setCheckbox({
      ...checkbox,
      [event.target.name]: event.target.checked,
    });
  };

  /* navigate */
  const navigate = useNavigate();

  const handleOnSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      const response = await axios.post(
        endpoint.REGISTER_ENDPOINT, //endpoint
        {
          email: username.value,
          password: password.value,
          first_name: first_name.value,
          last_name: last_name.value,
          company_name: company_name.value,
          company_phone: company_phone.value,
          reg_number: reg_number.value,
          csd_number: csd_number.value,
          company_mobile_phone: company_mobile.value,
        }
      );
      if (response.status === 200) {
        if (response.data.error === false) {
          navigate("/OTP");
          popAuthState({
            email: username.value,
            password: password.value,
          });
          setLoading(false);
        } else {
          switch (response.data.errorCode) {
            //missing input fields
            case 0:
              setMessage("Missing input fields");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to query users table
            case 1:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Email already in use
            case 2:
              setMessage("Email already in use");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to query bidder table
            case 3:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Phone  number already in use
            case 4:
              setMessage("Phone  number already in use");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to hash password
            case 5:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to write user to users table
            case 6:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to write user to bidders table
            case 7:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to write user to registration challenge
            case 8:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to send confirmation email
            case 9:
              setMessage("Unable to send confirmation email");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            default:
              setLoading(false);
              break;
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.signup}>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>SIGN UP</Typography>
      </Box>
      <Box className={classes.forminfotext}>
        <Typography style={{ color: sentechBlue, fontSize: "14px" }}>Enter your details to create your account. All fields are required unless otherwise indicated.</Typography>
      </Box>
      <Box className={classes.formbox}>
        <form onSubmit={handleOnSubmit}>
          <CustomInput
          required
            width={labelWidth}
            focused={true}
            value={first_name.value}
            onChange={handleInputChange}
            label="Name:"
            name="first_name"
            type="text"
            inputProps={{
              autoComplete: "first name",
            }}
            error={first_name.error}
            errorMessage={first_name.errorMessage}
          />
          <CustomInput
            width={labelWidth}
            focused={true}
            value={last_name.value}
            onChange={handleInputChange}
            label="Surname:"
            required
            name="last_name"
            type="text"
            inputProps={{
              autoComplete: "last name",
            }}
            error={last_name.error}
            errorMessage={last_name.errorMessage}
          />
          <CustomInput
            width={labelWidth}
            focused={true}
            value={company_name.value}
            onChange={handleInputChange}
            label="Company Name:"
            required
            name="company_name"
            type="text"
            inputProps={{
              autoComplete: "companyName",
            }}
            error={company_name.error}
            errorMessage={company_name.errorMessage}
          />
          <CustomInput
            width={labelWidth}
            focused={true}
            value={username.value}
            onChange={handleInputChange}
            label="Company Email (Username):"
            required
            name="username"
            type="text"
            inputProps={{
              autoComplete: "email",
            }}
            error={username.error}
            errorMessage={username.errorMessage}
          />
          <CustomInput
            width={labelWidth}
            label="Password:"
            value={password.value}
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={handleInputChange}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={password.error}
            errorMessage={password.errorMessage}
          />
          <Box className={classes.textbox}>
            <Typography className={classes.text}></Typography>
            <Box style={{ width: "20%" }}>
              <Typography className={classes.text}>
                Password must include:
                <br />
                Atleast 8 characters
                <br />
                Atleast one uppercase letter
                <br />
                Atleast one lowercase letter
                <br />
                Atleast one number
              </Typography>
            </Box>
          </Box>
          <CustomInput
            width={labelWidth}
            focused={true}
            value={company_phone.value}
            onChange={handleInputChange}
            label="Company Telephone:"
            required
            name="company_phone"
            type="text"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                <AddIcon style={{ fontSize: "small" }}/>
              </InputAdornment>
              ),
            }}
            error={company_phone.error}
            errorMessage={company_phone.errorMessage}
            input={ValidateNumber}
          />
          <CustomInput
            width={labelWidth}
            focused={true}
            value={company_mobile.value}
            onChange={handleInputChange}
            label="Company Mobile Phone:"
            required
            name="company_mobile"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                <AddIcon style={{ fontSize: "small" }}/>
              </InputAdornment>
                ),
              }}
            error={company_mobile.error}
            errorMessage={company_mobile.errorMessage}
          />{" "}
          <Box className={classes.textbox}>
            <Typography className={classes.text}></Typography>
            <Box style={{ width: "20%" }}>
              <Typography className={classes.text}>
                Phone numbers must include country code (e.g.
                +27316984425)
              </Typography>
            </Box>
          </Box>
          <CustomInput
            width={labelWidth}
            focused={true}
            value={reg_number.value}
            onChange={handleInputChange}
            label="Company Registration Number:"
            required
            name="reg_number"
            type="companyRegistrationNumber"
            inputProps={{
              autoComplete: "companyRegistrationNumber",
            }}
            error={reg_number.error}
            errorMessage={reg_number.errorMessage}
          />
          <CustomInput
            width={labelWidth}
            focused={true}
            value={csd_number.value}
            onChange={handleInputChange}
            label="Company CSD Number: (Optional)"
            // required
            name="csd_number"
            type="companyCSDNumber"
            inputProps={{
              autoComplete: "companyCSDNumber",
            }}
            error={csd_number.error}
            errorMessage={csd_number.errorMessage}
          />
          <Box className={classes.checkbox}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckboxChange}
                    value={checkbox.tnc}
                    name="tnc"
                  />
                }
                label={
                  <div>
                    <span>I have read and accept the </span>
                    <Link
                      onClick={() => {
                        navigate("/TnCs");
                        popAuthState({
                          email: username,
                          password: password,
                          first_name: first_name,
                          last_name: last_name,
                          company_name: company_name,
                          company_phone: company_phone,
                          reg_number: reg_number,
                          csd_number: csd_number,
                          company_mobile_phone: company_mobile,
                        });
                      }}
                    >
                      Terms and Conditions.
                    </Link>
                  </div>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleCheckboxChange}
                    value={checkbox.notif}
                    name="notif"
                  />
                }
                label="I agree to receive correspondence regarding tenders."
              />
            </FormGroup>
          </Box>
          <Typography style={{ marginTop: "1%", fontSize: "14px", color: sentechBlue, }}>By ticking the boxes above you agree to our Terms and Conditions <br/> and agree to receive correspondence regarding tenders.</Typography>
          <Box className={classes.buttonboxsignup}>
            <CustomButtonSolid
              type="submit"
              text="SIGN UP"
              disabled={
                first_name.error ||
                !first_name.value ||
                last_name.error ||
                !last_name.value ||
                username.error ||
                !username.value ||
                company_name.error ||
                !company_name.value ||
                password.error ||
                !password.value ||
                csd_number.error ||
                // !csd_number.value ||
                company_phone.error ||
                !company_phone.value ||
                company_mobile.error ||
                !company_mobile.value ||
                reg_number.error ||
                !reg_number ||
                !checkbox.tnc ||
                !checkbox.notif
              }
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default SignUp;
