import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";

// styling 
const useStyles = makeStyles((theme) =>
    createStyles({
      pag:{
        display: "flex",
        justifyContent: "space-evenly",
        paddingTop: "20px",
        paddingLeft: "180px",
        color: sentechDarkGrey,
      }
    })
);

function CustomPagination({ count, page, onNewPage }) {

  // material ui 
  const classes = useStyles();

  // pagination function
  function onPageChanged(e, newPage) {
    onNewPage(newPage);
  }

  return (
    <Pagination className={classes.pag}
      page={page}
      count={count}
      variant="outlined"
      shape="rounded"
      size="medium"
      onChange={onPageChanged}
    />
  );
}

export default CustomPagination;