import React from "react";
import PropTypes from "prop-types";

/* MATERIAL UI */
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, SnackbarContent } from "@material-ui/core";
// import { Alert } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Toast = (props) => {
  const { open, onClose, message, severity } = props;

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  /* JX */
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      // autoHideDuration={5000}
    >
      {/* <SnackbarContent
        // style={{ backgroundColor: "#0F589D", color: "white", width: "5000" }}
        message={message}
        action={action}
      /> */}
      <Alert severity={severity} onClose={onClose} >
        {message}
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
};

export default Toast;
