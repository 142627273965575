import React, { useState, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { sentechDarkGrey } from "../../../theme/colors";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { endpoint } from "../../../endpoint/endpoints";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import CustomPagination from "../../layout/pagination/pagination";
import { tenderErorr } from "../../../apiRequests/errorMessages";
import DashboardTable from "../../tenderTables/sysAdminDashboardTable";
import { useRef } from "react";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    table: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
  })
);

function AdminDashboard() {
  // material ui
  const classes = useStyles();

  const {
    authState,
    popAdminTenders,
    adminTenders,
    setMessage,
    openSnackbar,
    setLoading,
    categories,
    popCategories,setSeverity
  } = useContext(ApplicationContext);

  const mounted = useRef(true);

  const handleOnPageLoad = async (event) => {
    if (!mounted.current) return;
    mounted.current = false;
    setLoading(true);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}

    try {
      const response = await axios.get(
        endpoint.ADMIN_LIST_TEDNERS, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message.resp_items;
          popAdminTenders(data);
          setLoading(false);
        } else {
          setMessage(tenderErorr(response.data.errorCode));
          setSeverity("error")
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Dashboard";
  }, []);
  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(adminTenders.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>ADMIN DASHBOARD</Typography>
      </Box>
      <Box className={classes.table}>
        <DashboardTable
          tableData={adminTenders}
          page={page}
          perPage={perPage}
        />
      </Box>
      <Box className={classes.paginations}>
        <CustomPagination onNewPage={onNewPage} count={maxPage} />
      </Box>
    </Box>
  );
}

export default AdminDashboard;
