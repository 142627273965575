import React, { createContext } from "react";

export const ApplicationContext = createContext();

const ApplicationContextProvider = (props) => {
  /* auth */
  const [authState, setAuthState] = React.useState({
    isLoggedIn: false,
  });

  /* ui state */
  const [snackbarOpened, setSnackbarOpened] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("success");
  const [loading, setLoading] = React.useState(false);

  const popAuthState = (newAuthState) => {
    setAuthState({
      ...newAuthState,
    });
  };

  const openSnackbar = () => {
    setSnackbarOpened(true);
  };

  const closeSnackbar = () => {
    setSnackbarOpened(false);
  };

  /* Tenders */
  const [publicTenders, setPublicTenders] = React.useState([]);
  const [privateTenders, setPrivateTenders] = React.useState([]);
  const [myTenders, setMyTenders] = React.useState([]);
  const [openTenders, setOpenTenders] = React.useState([]);
  const [closedTenders, setClosedTenders] = React.useState([]);
  const [adminTenders, setAdminTenders] = React.useState([]);
  const [categories, setCategories] = React.useState([]);

  //go back and add prevState items with unique ids

  const popCategories = (items) => {
    setCategories([...items]);
  };

  const popPublicTenders = (items) => {
    setPublicTenders((prevState) => [...items]);
  };

  const popAdminTenders = (items) => {
    setAdminTenders((prevState) => [...items]);
  };

  const popPrivateTenders = (items) => {
    setPrivateTenders((prevState) => [...items]);
  };

  const popMyTenders = (items) => {
    setMyTenders((prevState) => [...items]);
  };

  const popOpenTenders = (items) => {
    setOpenTenders((prevState) => [ ...items]);
  };

  const popClosedTenders = (items) => {
    setClosedTenders((prevState) => [ ...items]);
  };

  // Users
  const [biddersList, setBiddersList] = React.useState([]);
  const [sentechUsersList, setSentechUsersList] = React.useState([]);

  const popBiddersList = (state) => {
    setBiddersList((prevState) => [...state]);
  };

  const popSentechUsersList = (state) => {
    setSentechUsersList((prevState) => [...state]);
  };

  const clearState = () => {
    setAuthState([]);
    setBiddersList([]);
    setSentechUsersList([]);
    setPublicTenders([]);
    setPrivateTenders([]);
    setOpenTenders([]);
    setClosedTenders([]);
    setMyTenders([]);
  };

  return (
    <ApplicationContext.Provider
      value={{
        authState,
        popAuthState,
        message,
        setMessage,
        snackbarOpened,
        severity,
        setSeverity,
        openSnackbar,
        closeSnackbar,
        loading,
        setLoading,
        publicTenders,
        popPublicTenders,
        privateTenders,
        popPrivateTenders,
        myTenders,
        popMyTenders,
        openTenders,
        popOpenTenders,
        closedTenders,
        popClosedTenders,
        biddersList,
        popBiddersList,
        sentechUsersList,
        popSentechUsersList,
        adminTenders,
        popAdminTenders,
        categories,
        popCategories,
        clearState,
      }}
    >
      {" "}
      {props.children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContextProvider;
