import React from "react";
import { Box, Typography, } from "@material-ui/core";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useNavigate } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CustomButtonSolid from "../layout/buttons/solidButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    accountcreated: {
      textAlign: "center",
    },

    typography: {
      paddingTop: "6%",
      paddingBottom: "2%",
    },

    accountcreatedbox: {
      paddingTop: "3%",
      paddingBottom: "5%",
    },
  })
);

function AccountCreated() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Account created";
  }, []);

  // navigation
  const navigate = useNavigate();

  function handleClick() {
    navigate("/login");
  }

  return (
    <Box className={classes.accountcreated}>
      <Box>
        <Typography className={classes.typography}>Account created!</Typography>
      </Box>
      <Box>
        <TaskAltIcon sx={{ fontSize: 80 }} />
      </Box>
      <Box>
        <Typography>Your account has been successfully created.</Typography>
        <Typography>
          Please login with your new credentials.
        </Typography>
      </Box>
      <Box className={classes.accountcreatedbox}>
        <CustomButtonSolid onClick={handleClick} text="LOGIN" />
      </Box>
    </Box>
  );
}

export default AccountCreated;
