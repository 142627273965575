import { Box, Button, Paper, Typography } from "@material-ui/core";
import { MessageLeft, MessageRight } from "./Messages";
import { TextInput } from "./TextInput";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { textAlign } from "@mui/system";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";

// styles box and typography
const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      width: "94%",
      margin: "auto",
      textAlign: "left",
      paddingTop: "30px",
    },
    typography: {
      textAlign: "left",
      paddingBottom: "20px",
      color: sentechDarkGrey,
    },
  })
);

function popMessages(messages) {
  // console.log(messages);

  return messages.map((el) => {
    // console.log(el);
    if (el.user === 1) {
      return (
        <MessageRight
          key={el.id}
          message={el.message}
          timestamp={new Date(el.timestamp).toLocaleString("en-NZ", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        />
      );
    }
    return (
      <MessageLeft
        key={el.id}
        message={el.message}
        timestamp={new Date(el.timestamp).toLocaleString("en-NZ", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      />
    );
  });
}

export default function EditTenderForum({ messages, onNewMessage, handleOnPageReLoad }) {
  const classes = useStyles();
  // tender discussion responses
  return (
    <Box className={classes.box}>
      <Typography
        // variant="h5"
        className={classes.typography}
      >
        TENDER DISCUSSION FORUM
      </Typography>
      <Typography variant="body1" className={classes.typography}>
        View and approve answers by BSEC members or type your own reply to
        questions posted by bidders.
      </Typography>
      <Paper style={{ overflowY: "scroll", height: "250px" }}>
        <ul>{popMessages(messages)}</ul>
      </Paper>
      <TextInput submit={ async (message) => {
          onNewMessage(message)
          await handleOnPageReLoad(); 
        } 
      }
      />
      <br />

      <br />
      <br />
      {/* <Typography variant="body1" className={classes.typography}>
        Type your comment here
      </Typography>
      <br /> */}
    </Box>
  );
}
