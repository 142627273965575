import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Typography, Button } from "@material-ui/core";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import Sidebar from "../../layout/sysadminSidebar/sideBar";
import CustomInput from "../../inputFields/Inputfields";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import { ApplicationContext } from "../../../context/ApplicationContext";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import { endpoint } from "../../../endpoint/endpoints";
import axios from "axios";
import { userManagementError } from "../../../apiRequests/errorMessages";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    viewbidderinput: {
      paddingLeft: "6%",
    },

    newuserbuttonbox1: {
      paddingTop: "3%",
      paddingBottom: "5%",
      display: "flex",
      margin: "auto",
      justifyContent: "center",
    },
  })
);

function ViewBidder() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "View bidder";
  }, []);

  // set form width
  const labelWidth = "200px";

  // navigation
  const navigate = useNavigate();

  const { state } = useLocation();

  function handleCancel() {
    navigate("/BIDDERS");
  }

  const { setMessage, openSnackbar } = useContext(ApplicationContext);

  const [name, setName] = useState(state.firstName);
  const [surname, setSurname] = useState(state.lastName);
  const [companyName, setCompanyName] = useState(state.companyName);
  const [companyEmail, setCompanyEmail] = useState(state.email);
  const [companyTelephone, setCompanyTelephone] = useState(state.companyPhone);
  const [companyMobile, setCompanyMobile] = useState(state.companyMobile);
  const [companyRegistration, setcompanyRegistration] = useState(state.reg);
  const [companyCSD, setCompanyCSD] = useState(state.csd);

  const [disabled, setDisabled] = useState(true);

  function handleDisabled(event) {
    event.preventDefault();
    setDisabled(!disabled);
  }
  /* functions */
  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;

      case "surname":
        setSurname(event.target.value);
        break;

      case "companyName":
        setCompanyName(event.target.value);
        break;

      case "companyEmail":
        setCompanyEmail(event.target.value);
        break;

      case "companyTelephone":
        setCompanyTelephone(event.target.value);
        break;

      case "companyMobile":
        setCompanyMobile(event.target.value);
        break;

      case "companyRegistration":
        setcompanyRegistration(event.target.value);
        break;

      case "companyCSD":
        setCompanyCSD(event.target.value);
        break;

      default:
        break;
    }
  };

  // const handleOnSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios.post(
  //       endpoint.ADMIN_EDIT_USER_ENDPOINT, //endpoint
  //       {
  //         email: companyEmail.value,
  //         first_name: name.value,
  //         last_name: surname.value,
  //         company_name: companyName.value,
  //         company_phone: companyTelephone.value,
  //         company_mobile_phone: companyMobile.value,
  //         reg_number: companyRegistration.value,
  //         csd_number: companyCSD.value,
  //         role: "bidder", //update
  //       },
  //       {
  //         headers: {
  //           "user-token": localStorage.getItem("user_token"),
  //           authorization: localStorage.getItem("authorization"),
  //         },
  //       }
  //     );
  //     //   const response = apiResponse;
  //     if (response.status === 200) {
  //       if (response.data.error === false) {
  //         console.log(response.data.message);
  //         const message = response.data.message;
  //         setMessage("Bidder updated successfully");
  //         setSeverity("success");
  //         openSnackbar();
  //         setTimeout(() => {
  //           navigate("/BIDDERS");
  //         }, 2000);
  //       } else {
  //         setMessage(userManagementError(response.data.errorCode));
  //         setSeverity("error");
  //         setLoading(false);
  //         openSnackbar();
  //       }
  //     } else {
  //       console.log("http error");
  //     }
  //     console.log(response);
  //   } catch (error) {}
  // };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>PROFILE</Typography>
      </Box>
      <Box>
        <form
        // onSubmit={handleOnSubmit}
        >
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={name}
              focused={true}
              onChange={handleInputChange}
              label="Name:"
              required
              name="name"
              type="text"
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={surname}
              focused={true}
              onChange={handleInputChange}
              label="Surname:"
              required
              name="surname"
              type="text"
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={companyName}
              focused={true}
              onChange={handleInputChange}
              label="Company Name:"
              required
              name="companyName"
              type="text"
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled
              value={companyEmail}
              focused={true}
              onChange={handleInputChange}
              label="Company Email:"
              required
              name="companyEmail"
              type="text"
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              focused={true}
              label="Company Telephone:"
              name="companyTelephone"
              required
              type="text"
              value={companyTelephone}
              onChange={handleInputChange}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              focused={true}
              label="Company Mobile Phone:"
              name="companyMobile"
              required
              type="text"
              value={companyMobile}
              onChange={handleInputChange}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              focused={true}
              value={companyRegistration}
              onChange={handleInputChange}
              label="Company Registration Number:"
              required
              name="companyRegistration"
              type="text"
              inputProps={{
                autoComplete: "companyRegistration",
              }}
            />
          </Box>
          <Box className={classes.viewbidderinput}>
            <CustomInput
              width={labelWidth}
              disabled={disabled}
              value={companyCSD}
              focused={true}
              label="Company CSD Number:"
              name="companyCSD"
              required
              type="text"
              onChange={handleInputChange}
              inputProps={{
                autoComplete: "companyCSD",
              }}
            />
          </Box>

          <Box className={classes.newuserbuttonbox1}>
            {disabled && (
              <>
                <CustomButtonOutlined onClick={handleCancel} text="Cancel" />
                {/* <CustomButtonSolid
                  onClick={handleDisabled}
                  text="Edit Profile"
                /> */}
              </>
            )}
            {/* {!disabled && (
              <>
                <CustomButtonOutlined onClick={handleDisabled} text="Cancel" />
                <CustomButtonSolid
                  onClick={handleOnSubmit}
                  text="Edit Bidder Details"
                />
                <CustomButtonSolid
                  //   onClick={handleOnSubmit}
                  text="Delete Bidder"
                />
              </>
            )} */}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ViewBidder;
