import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Sidebar from "../../layout/scmSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import CustomInput from "../../inputFields/Inputfields";
import ViewTenderForum from "../../forum/scmForum/ViewTenderForum";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FileList from "../../../components/files/fileList";
import CustomDropDown from "../../inputFields/DropdownField";
import { color } from "@mui/system";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import convertUnixToDateTime from "../../../utilities/unixToMUIDateTime";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import EditTenderForum from "../../forum/scmForum/Forum";
import { endpoint } from "../../../endpoint/endpoints";
import FileUpload from "../../dragDropInput/fileUpload";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import axios from "axios";
import BSECResponses from "./bsecResponses";
import { tenderManagementError } from "../../../apiRequests/errorMessages";
import UpdateFileDialog from "./updateTenderfileDialog";
import DeleteTenderDialog from "./deleteTenderDialog";
import categoryCompare from "../../../utilities/categoryCompare";
import CustomAutoComplete from "../../inputFields/Autocomplete";
import { useRef } from "react";
import { CloseOutlined, DeleteOutlineOutlined } from "@material-ui/icons";
import RemoveUserDialog from "./removeUserDialog";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },

    tendertype: {
      paddingRight: "28%",
      paddingBottom: "0%",
      paddingLeft: "52%",
      color: sentechDarkGrey,
    },

    typedropdown: {
      paddingLeft: "75%",
      paddingTop: "0%",
      paddingBottom: "1%",
      width: "31%",
    },

    viewtenderinput: {
      paddingLeft: "6%",
    },

    tenderdescription: {
      paddingLeft: "75%",
      paddingTop: "0%",
      paddingBottom: "1%",
    },

    datetimeheading: {
      paddingLeft: "52%",
      paddingBottom: "0%",
      color: sentechDarkGrey,
    },

    datetime: {
      paddingLeft: "75%",
      paddingTop: "0%",
      paddingBottom: "1%",
    },

    closetimeheading: {
      paddingLeft: "52%",
      paddingBottom: "0%",
      color: sentechDarkGrey,
    },

    issuedateheading: {
      paddingLeft: "52%",
      paddingBottom: "0%",
      color: sentechDarkGrey,
    },

    BSECforum: {
      width: "90%",
      margin: "auto",
      // display: "flex",
      // justifyContent: "flex-start",
    },

    filelist: {
      width: "75%",
      // marginRight: "7%",
      marginLeft: "3%",
    },

    Userbuttonbox: {
      paddingBottom: "5%",
      paddingTop: "2%",
      display: "flex",
      justifyContent: "center",
    },

    dragdropbox: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "2%",
      paddingBottom: "4%",
      display: "flex",
      justifyContent: "center",
    },

    usersBox: {
      paddingLeft: "6%",
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
    },

    userCards: {
      width: "20%",
      color: sentechDarkGrey,
    },

    tenderforum: {
      width: "80%",
      marginLeft: "15%",
    },

    bsecResponses: {
      width: "55%",
      marginLeft: "15%",
    },
  })
);

function ViewTender() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "View tender";
  }, []);

  // set form width
  const labelWidth = "200px";

  const mounted = useRef(true);

  // navigate
  const navigate = useNavigate();

  const {
    setMessage,
    openSnackbar,
    setLoading,
    sentechUsersList,
    popSentechUsersList,
    categories,
    popCategories,
    setSeverity,
  } = useContext(ApplicationContext);

  // tender form data
  const { state } = useLocation();
  const webSocket = React.useRef(null);

  const [webSocketState, setwebSocketState] = useState(false);
  // state
  const [category, setCategory] = useState(state.category_id);
  const [tenderNumber, setTenderNumber] = useState(state.tender_number);
  const [tenderType, setTenderType] = useState(state.tender_type);
  const [tenderDescription, setTenderDescription] = useState(
    state.tender_description
  );
  const [briefingSession, setBriefingSession] = useState(
    convertUnixToDateTime(state.brief_session)
  );
  const [closing, setClosing] = useState(
    convertUnixToDateTime(state.close_datetime)
  );
  const [issueDate, setIssueDate] = useState(
    convertUnixToDateTime(state.issue_date)
  );
  const [status, setStatus] = useState(state.status);
  const [currentBSECMembers, setCurrentBsecMembers] = useState([]);
  const [currentSCMOfficials, setCurrentScmOfficials] = useState([]);
  const [currentBidders, setCurrentBidders] = useState([]);
  const [bsecMembers, setBsecMembers] = useState([]);
  const [scmOfficials, setScmOfficials] = useState([]);
  const [bidders, setBidders] = useState([]);
  // const [Categories, setCategories] = useState([{}]);

  const [disabled, setDisabled] = useState(true);

  const [SCMlist, setSCMList] = useState([]);
  const [BSECList, setBSECList] = useState([]);
  const [biddersList, popBiddersList] = useState([]);

  function handleDisabled(event) {
    event.preventDefault();
    setDisabled(!disabled);
  }

  function handleCancel(event) {
    navigate("/AVAILABLETENDERS");
  }

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "status":
        setStatus(event.target.value);
        break;

      case "category":
        setCategory(event.target.value);
        break;

      case "tenderNumber":
        setTenderNumber(event.target.value);
        break;

      case "tenderType":
        setTenderType(event.target.value);
        break;

      case "tenderDescription":
        setTenderDescription(event.target.value);
        break;

      case "briefingSession":
        if (
          Date.now() / 1000 >=
          new Date(event.target.value).valueOf() / 1000
        ) {
          setMessage("Briefing Session must be a future date-time");
          setSeverity("error");
          openSnackbar();
          break;
        }
        let briefingSessionDate = new Date(event.target.value).valueOf() / 1000;
        let closingDate = new Date(closing).valueOf() / 1000;
        if (
          briefingSessionDate >= closingDate ||
          briefingSessionDate <= Date.now() / 1000 ||
          briefingSessionDate === closingDate
        ) {
          setMessage(
            "Briefing Session must be a date-time before Closing Date and not the same as Closing Date or a date-time after Closing Date"
          );
          setSeverity("error");
          openSnackbar();
          break;
        }
        // if (
        //   new Date(event.target.value).valueOf() / 1000 >
        //   new Date(closing).valueOf() / 1000
        // ) {
        //   setMessage("Briefing Session must be a before Closing Date");
        //   setSeverity("error");
        //   openSnackbar();
        //   break;
        // }
        setBriefingSession(event.target.value);
        break;

      case "closing":
        if (
          new Date(briefingSession).valueOf() / 1000 >=
            new Date(event.target.value).valueOf() / 1000 ||
          !briefingSession
        ) {
          setMessage("Closing Date must be after Briefing Session");
          setSeverity("error");
          openSnackbar();
          break;
        }
        // if (
        //   Date.now() / 1000 >=
        //   new Date(event.target.value).valueOf() / 1000
        // ) {
        //   setMessage("Closing Date must be a future date-time");
        //   setSeverity("error");
        //   openSnackbar();
        //   break;
        // }
        setClosing(event.target.value);
        break;

      case "issueDate":
        setIssueDate(event.target.value);
        break;

      case "bsecMembers":
        setBsecMembers(event.target.value);
        break;

      case "scmOfficials":
        setScmOfficials(event.target.value);
        break;

      default:
        break;
    }
  };

  //On page load fetch categories and available Sentech Users/Bidders
  const handleOnPageLoad = async (event) => {
    if (!mounted) return;
    let tenderBidders = [];
    let tenderBSEC = [];
    let tenderSCM = [];
    mounted.current = false;
    setLoading(true);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}
    try {
      const response = await axios.post(
        endpoint.GET_TENDER_ENDPOINT, //endpoint
        { tender_id: state.tender_id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      console.log(response);
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          // setLoading(false);
          const tenderDetails = response.data.message.tender;
          setCategory(tenderDetails.category_id);
          setTenderNumber(tenderDetails.tender_number);
          setTenderType(tenderDetails.tender_type);
          setTenderDescription(tenderDetails.tender_description);
          setBriefingSession(
            convertUnixToDateTime(tenderDetails.brief_session)
          );
          setClosing(convertUnixToDateTime(tenderDetails.close_datetime));
          setIssueDate(convertUnixToDateTime(tenderDetails.issue_date));
          setStatus(tenderDetails.status);
          // 
          // setFiles(tenderDetails.filename);

          if (response.data.message.messages) {
            const messages = response.data.message.messages.sort(compare);
            const mappedMessages = messages.map((itm) => ({
              id: itm.id,
              message: itm.message,
              user: itm.role.includes("bidder") ? 0 : 1,
              status: itm.status,
              timestamp: itm.createdAt,
            }));
            setMessages(
              mappedMessages.filter((itm) => itm.status !== "pending")
            );
            setBSECMessages(
              mappedMessages.filter((itm) => itm.status === "pending")
            );
          }
          if (response.data.message.users) {
            const users = response.data.message.users;
            tenderBidders = users.filter((itm) => itm.role.includes("bidder"));
            tenderBSEC = users.filter((itm) => itm.role.includes("bsec"));

            tenderSCM = users.filter(
              (itm) =>
                itm.role.includes("scm") &&
                itm.user_id !== localStorage.getItem("id")
            );
          }
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }

    try {
      const response = await axios.get(
        endpoint.ADMIN_LIST_USER_ENDPOINT, //endpoint
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;

          const bidders = message.bidders.map((user) => ({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            companyName: user.company_name,
            companyPhone: user.company_phone,
            companyMobile: user.company_mobile_phone ?? "",
            reg: user.reg_number,
            csd: user.csd_number,
            id: user.id,
            role: user.role[0],
          }));
          popBiddersList(
            bidders.filter((itm) => {
              return tenderBidders.every((item) => {
                return itm.id !== item.user_id;
              });
            })
          );
          setCurrentBidders((prev) =>
            tenderBidders.map((item) => {
              const matched = bidders.find((itm) => itm.id === item.user_id);
              return matched;
            })
          );

          const sentechUsers = message.sentech_users.map((user) => ({
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            employeeRef: user.employee_ref,
            department: user.department,
            designation: user.designaion,
            role: user.role,
            id: user.id,
          }));
          popSentechUsersList(sentechUsers);

          const filteredUsers = sentechUsers.filter(
            (user) => user.id !== localStorage.getItem("id")
          );
          const filteredSCMUsers = filteredUsers.filter((user) =>
            user.role.includes("scm")
          );
          const filteredBSECUsers = filteredUsers.filter((user) =>
            user.role.includes("bsec")
          );

          setCurrentBsecMembers((prev) =>
            tenderBSEC.map((item) => {
              const matched = filteredBSECUsers.find(
                (itm) => itm.id === item.user_id
              );
              return matched;
            })
          );

          setCurrentScmOfficials((prev) =>
            tenderSCM.map((item) => {
              const matched = filteredSCMUsers.find(
                (itm) => itm.id === item.user_id
              );
              return matched;
            })
          );

          setSCMList(
            filteredSCMUsers
              .filter((itm) => {
                return tenderSCM.every((item) => {
                  return itm.id !== item.user_id;
                });
              })
              .filter((itm) => {
                return tenderBSEC.every((item) => {
                  return itm.id !== item.user_id;
                });
              })
          );

          setBSECList(
            filteredBSECUsers
              .filter((itm) => {
                return tenderBSEC.every((item) => {
                  return itm.id !== item.user_id;
                });
              })
              .filter((itm) => {
                return tenderSCM.every((item) => {
                  return itm.id !== item.user_id;
                });
              })
          );
          setLoading(false);
        } else {
          setMessage("Failed to get bidders and Sentech Users");
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();

  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));
    //On page load fetch categories and available Sentech Users/Bidders
    const handleOnPageReLoad = async (event) => {

      if (!mounted) return;
      let tenderBidders = [];
      let tenderBSEC = [];
      let tenderSCM = [];
      mounted.current = false;
      setLoading(true);
      await delay(1500)
      if (!categories.length)
        try {
          const response = await axios.get(
            endpoint.LIST_CATEGORIES, //endpoint
            {
              headers: {
                "user-token": localStorage.getItem("user_token"),
                authorization: localStorage.getItem("authorization"),
              },
            }
          );
          if (response.status === 200) {
            if (response.data.error === false) {
              const data = response.data.message.categories;
              const fetchedCat = data.map((category) => ({
                label: category.name,
                id: category.id,
              }));
              popCategories(fetchedCat);
            }
          }
          // setLoading(false);
        } catch (error) {}
      try {
        const response = await axios.post(
          endpoint.GET_TENDER_ENDPOINT, //endpoint
          { tender_id: state.tender_id },
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        //   const response = apiResponse;
        console.log(response);
        if (response.status === 200) {
          if (response.data.error === false) {
            // popMyTenders(data);
            // setLoading(false);
            const tenderDetails = response.data.message.tender;
            setCategory(tenderDetails.category_id);
            setTenderNumber(tenderDetails.tender_number);
            setTenderType(tenderDetails.tender_type);
            setTenderDescription(tenderDetails.tender_description);
            setBriefingSession(
              convertUnixToDateTime(tenderDetails.brief_session)
            );
            setClosing(convertUnixToDateTime(tenderDetails.close_datetime));
            setIssueDate(convertUnixToDateTime(tenderDetails.issue_date));
            setStatus(tenderDetails.status);
            // 
            // setFiles(tenderDetails.filename);
  
            if (response.data.message.messages) {
              const messages = response.data.message.messages.sort(compare);
              const mappedMessages = messages.map((itm) => ({
                id: itm.id,
                message: itm.message,
                user: itm.role.includes("bidder") ? 0 : 1,
                status: itm.status,
                timestamp: itm.createdAt,
              }));
              setMessages(
                mappedMessages.filter((itm) => itm.status !== "pending")
              );
              setBSECMessages(
                mappedMessages.filter((itm) => itm.status === "pending")
              );
            }
            if (response.data.message.users) {
              const users = response.data.message.users;
              tenderBidders = users.filter((itm) => itm.role.includes("bidder"));
              tenderBSEC = users.filter((itm) => itm.role.includes("bsec"));
  
              tenderSCM = users.filter(
                (itm) =>
                  itm.role.includes("scm") &&
                  itm.user_id !== localStorage.getItem("id")
              );
            }
          } else {
            setMessage(tenderManagementError(response.data.errorCode));
            setSeverity("error");
            openSnackbar();
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
  
      try {
        const response = await axios.get(
          endpoint.ADMIN_LIST_USER_ENDPOINT, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        //   const response = apiResponse;
        if (response.status === 200) {
          if (response.data.error === false) {
            const message = response.data.message;
  
            const bidders = message.bidders.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              companyName: user.company_name,
              companyPhone: user.company_phone,
              companyMobile: user.company_mobile_phone ?? "",
              reg: user.reg_number,
              csd: user.csd_number,
              id: user.id,
              role: user.role[0],
            }));
            popBiddersList(
              bidders.filter((itm) => {
                return tenderBidders.every((item) => {
                  return itm.id !== item.user_id;
                });
              })
            );
            setCurrentBidders((prev) =>
              tenderBidders.map((item) => {
                const matched = bidders.find((itm) => itm.id === item.user_id);
                return matched;
              })
            );
  
            const sentechUsers = message.sentech_users.map((user) => ({
              firstName: user.first_name,
              lastName: user.last_name,
              email: user.email,
              employeeRef: user.employee_ref,
              department: user.department,
              designation: user.designaion,
              role: user.role,
              id: user.id,
            }));
            popSentechUsersList(sentechUsers);
  
            const filteredUsers = sentechUsers.filter(
              (user) => user.id !== localStorage.getItem("id")
            );
            const filteredSCMUsers = filteredUsers.filter((user) =>
              user.role.includes("scm")
            );
            const filteredBSECUsers = filteredUsers.filter((user) =>
              user.role.includes("bsec")
            );
  
            setCurrentBsecMembers((prev) =>
              tenderBSEC.map((item) => {
                const matched = filteredBSECUsers.find(
                  (itm) => itm.id === item.user_id
                );
                return matched;
              })
            );
  
            setCurrentScmOfficials((prev) =>
              tenderSCM.map((item) => {
                const matched = filteredSCMUsers.find(
                  (itm) => itm.id === item.user_id
                );
                return matched;
              })
            );
  
            setSCMList(
              filteredSCMUsers
                .filter((itm) => {
                  return tenderSCM.every((item) => {
                    return itm.id !== item.user_id;
                  });
                })
                .filter((itm) => {
                  return tenderBSEC.every((item) => {
                    return itm.id !== item.user_id;
                  });
                })
            );
  
            setBSECList(
              filteredBSECUsers
                .filter((itm) => {
                  return tenderBSEC.every((item) => {
                    return itm.id !== item.user_id;
                  });
                })
                .filter((itm) => {
                  return tenderSCM.every((item) => {
                    return itm.id !== item.user_id;
                  });
                })
            );
            setLoading(false);
          } else {
            setMessage("Failed to get bidders and Sentech Users");
            setSeverity("error");
            setLoading(false);
            openSnackbar();
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

  const connectWebsocket = () => {
    const authToken = localStorage.getItem("authorization");
    const userToken = localStorage.getItem("user_token");

    webSocket.current = new WebSocket(
      `${endpoint.WEBSOCKET}?Authorization=${authToken}&user-token=${userToken}`
    );
    webSocket.current.onopen = () => {
      console.log("connected")
      // setwebSocketState(true)
    };
    webSocket.current.onclose = () => {
      console.log("disconnected")
      // setwebSocketState(false)
    };
    webSocket.current.onerror = (e) => console.log(e);
    webSocket.current.onmessage = (e) => {
      const data = JSON.parse(e.data);
      switch (data.action) {
        case "message":
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              message: data.message.message.S,
              id: data.message.id.S,
              timestamp: parseInt(data.message.createdAt.N),
              user:
                data.message.role.S === "scm" || data.message.role.S === "bsec"
                  ? 1
                  : 0, //bidder designated as 0, sentech user designated as 1
            },
          ]);
          break;
        default:
          break;
      }
    };
  };

  React.useEffect(() => {
    if (webSocket.current) return;
    // if (webSocketState) return;
    connectWebsocket();
    return () => {
      if (webSocket.current) {
        webSocket.current.close();
        webSocket.current = null;
      }
    };
  }, [webSocket]);

  // dropdown function
  const TenderTypeOptions = [
    { label: "Open", id: "public" },
    { label: "Nominated", id: "private" },
  ];

  const StatusOptions = [
    { label: "Advertised", id: "Advertised" },
    { label: "Closed", id: "Closed" },
    { label: "Evaluation", id: "Under evaluation" },
    { label: "Awarded", id: "Awarded" },
  ];

  // tender forum message
  const [messages, setMessages] = useState([]);

  const [BSECmessages, setBSECMessages] = useState([]);

  // files uploaded
  const [files, setFiles] = useState(state.tender_file.map((itm) => ({ key: itm.split("?")[0] })));
  // = useState(mapFilesToKeys(state.tender_file));
  //   []

  // useEffect(() => {
  //   setFiles(state.tender_file.map((itm) => ({ key: itm.split("?")[0] })));
  // }, [state.tender_file]);

  // function mapFilesToKeys(files) {
  //   return files.map((itm) => ({ key: itm.split("?")[0] }));
  // }

  const [uploadFiles, setUploadFiles] = useState([]);

  const [openUpdateFile, setOpenUpdateFile] = useState(false);

  const handleUpdateDialogClose = () => {
    setOpenUpdateFile(false);
  };

  // const [openDelete, setOpenDelete] = React.useState(false);

  // const handleDeleteDialogOpen = () => {
  //   setOpenDelete(true);
  // };

  // const handleDeleteDialogClose = () => {
  //   setOpenDelete(false);
  // };

  const [openRemove, setOpenRemove] = React.useState(false);

  const handleRemoveDialogOpen = () => {
    setOpenRemove(true);
  };

  const handleRemoveDialogClose = () => {
    setOpenRemove(false);
  };

  const handleOnSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.EDIT_TENDER, //endpoint
        {
          tender_id: state.tender_id,
          category_id: category,
          tender_number: tenderNumber,
          tender_type: tenderType,
          tender_description: tenderDescription,
          brief_session: new Date(briefingSession).valueOf() / 1000,
          close_datetime: new Date(closing).valueOf() / 1000,
          issue_date: new Date(issueDate).valueOf() / 1000,
          technical_review: state.technical_review,
          status: status,
          // 
          // filename: uploadFiles[0].file.name,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
          return;
        }
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      return;
    }
    const filteredBSECmembers = bsecMembers.filter((itm) => {
      return scmOfficials.every((item) => {
        return itm.id !== item.id;
      });
    });
    filteredBSECmembers.forEach(async (user) => {
      try {
        const assignResult = await axios.post(
          endpoint.ASSIGN_TENDER,
          {
            tender_id: state.tender_id,
            user_id: user.id,
            role: "bsec",
          },
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (assignResult.status === 200) {
          if (assignResult.data.error === false) {
            setCurrentBsecMembers((prev) => [...prev, user]);
            setBsecMembers([]);
            setBSECList((prev) => prev.filter((itm) => itm.id !== user.id));
          }
        }
      } catch (error) {}
    });
    scmOfficials.forEach(async (user) => {
      try {
        const assignResult = await axios.post(
          endpoint.ASSIGN_TENDER,
          { tender_id: state.tender_id, user_id: user.id, role: "scm" },
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (assignResult.status === 200) {
          if (assignResult.data.error === false) {
            setCurrentScmOfficials((prev) => [...prev, user]);
            setScmOfficials([]);
            setSCMList((prev) => prev.filter((itm) => itm.id !== user.id));
          }
        }
      } catch (error) {}
    });
    try {
      if (tenderType === "private") {
        bidders.forEach(async (user) => {
          const assignResult = await axios.post(
            endpoint.INVITE_BIDDER,
            { tender_id: state.tender_id, user_id: user.id },
            {
              headers: {
                "user-token": localStorage.getItem("user_token"),
                authorization: localStorage.getItem("authorization"),
              },
            }
          );
          if (assignResult.status === 200) {
            if (assignResult.data.error === false) {
              setCurrentBidders((prev) => [...prev, user]);
              setBidders([]);
              popBiddersList((prev) =>
                prev.filter((itm) => itm.id !== user.id)
              );
            }
          }
        });
      }
    } catch (error) {}
    setMessage("Tender Successfully Updated");
    setSeverity("success");
    openSnackbar();
    setLoading(false);
    setDisabled(true);
  };

  const handleUpdateFile = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.UPDATE_TENDER_FILE, //endpoint
        {
          tender_id: state.tender_id,
          filename: {
            // changed from sentech-etender-portal
            bucket: "338668895106.sentech-etender-portal",
            key: uploadFiles[0].file.name,
            region: "us-west-2",
            type: "technical",
          },
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          const uploadURL = message.urlLink;
          const file = uploadFiles[0].file;
          try {
            const uploadResult = await axios.put(uploadURL, file, {
              headers: {
                "Content-Type": "",
              },
              onUploadProgress: (progressEvent) => {
                let percentComplete =
                  progressEvent.loaded / progressEvent.total;
                percentComplete = parseInt(percentComplete * 100);
                setUploadFiles((prevState) => {
                  return prevState.map((file) => ({
                    file: file.file,
                    loadingState: percentComplete,
                  }));
                });
              },
            });
            if (uploadResult.status === 200) {
              // setFiles((prev) => [
              //   ...prev,
              //   { type: file.type, key: `-${file.name}` },
              // ]);
              // setFiles(prevFiles =>
              //   prevFiles.map(file => {
              //     if (file.key === `-${uploadFiles[0].file.name}`) {
              //       return { type: uploadFiles[0].file.type, key: uploadFiles[0].file.name };
              //     }
              //     return file;
              //   })
              // );
              const newFile = { type: uploadFiles[0].file.type, key: uploadFiles[0].file.name };
              setFiles([newFile]);
              setUploadFiles([]);
            }
          } catch (error) {}
          setMessage("Tender File Successfully Updated");
          setSeverity("success");
          openSnackbar();
          setLoading(false);
          setDisabled(true);
        } else {
          setUploadFiles([]);
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const removeUser = async (id, role) => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.REMOVE_USER_TENDER, //endpoint
        { tender_id: state.tender_id, user_id: id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          setMessage("User Successfully Deleted");
          if (role === "scm")
            setCurrentScmOfficials((prev) =>
              prev.filter((itm) => itm.id !== id)
            );
          if (role === "bsec")
            setCurrentBsecMembers((prev) =>
              prev.filter((itm) => itm.id !== id)
            );
          setSeverity("success");
          openSnackbar();
          setLoading(false);
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          openSnackbar();
          setSeverity("error");
          setLoading(false);
        }
      } else {
        setLoading(false);
        console.log("http error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Sentech requested delete option to be removed
  // const handleDeleteTender = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.post(
  //       endpoint.DELETE_TENDER, //endpoint
  //       { id: state.tender_id },
  //       {
  //         headers: {
  //           "user-token": localStorage.getItem("user_token"),
  //           authorization: localStorage.getItem("authorization"),
  //         },
  //       }
  //     );
  //     //   const response = apiResponse;
  //     console.log(response);
  //     if (response.status === 200) {
  //       if (response.data.error === false) {
  //         // popMyTenders(data);
  //         setMessage("Tender Successfully Deleted");
  //         openSnackbar();
  //         setLoading(false);
  //         navigate("/AVAILABLETENDERS");
  //       } else {
  //         setMessage(tenderManagementError(response.data.errorCode));
  //         openSnackbar();
  //         setLoading(false);
  //       }
  //     } else {
  //       setLoading(false);
  //       console.log("http error");
  //     }
  //     console.log(response);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
      {disabled ? (
        <>
        <Typography style={{ fontSize: "24px" }}>VIEW TENDER</Typography>
        </>
      ) : (
        <>
        <Typography style={{ fontSize: "24px" }}>EDIT TENDER</Typography>
        </>
      )}
      </Box>
      <Box>
        <Box className={classes.viewtenderinput}>
          <CustomDropDown
            disabled={disabled}
            options={StatusOptions}
            width={labelWidth}
            value={status}
            onChange={handleInputChange}
            label="Status:"
            required
            name="status"
            type="text"
          />
        </Box>
        <Box className={classes.viewtenderinput}>
          <CustomDropDown
            disabled={disabled}
            options={categories.sort(categoryCompare)}
            width={labelWidth}
            value={category}
            onChange={handleInputChange}
            label="Category:"
            required
            name="category"
            type="text"
          />
        </Box>
        <Box className={classes.viewtenderinput}>
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={tenderNumber}
            focused={true}
            onChange={handleInputChange}
            label="Tender number:"
            required
            name="tenderNumber"
            type="text"
            inputProps={{
              autoComplete: "tenderNumber",
            }}
          />
        </Box>
        <Box className={classes.viewtenderinput}>
          {/* tender type dropdown */}
          <CustomDropDown
            options={TenderTypeOptions}
            disabled={disabled}
            width={labelWidth}
            value={tenderType}
            focused={true}
            onChange={handleInputChange}
            label="Tender Type:"
            required
            name="tenderType"
            type="text"
          />
        </Box>
        <Box className={classes.viewtenderinput}>
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={tenderDescription}
            focused={true}
            onChange={handleInputChange}
            label="Tender Description:"
            required
            name="tenderDescription"
            type="text"
            inputProps={{
              autoComplete: "tenderDescription",
            }}
          />
        </Box>
        {/* <Box className={classes.tenderdescription}> */}
        {/* <h6>Maximum length: 255 characters</h6> */}
        {/* </Box> */}
        <Box className={classes.viewtenderinput}>
          {/* shows calendar and time */}
          <CustomInput
            width={labelWidth}
            disabled={true}
            value={issueDate}
            onChange={handleInputChange}
            label="Issue date and time:"
            required
            name="issueDate"
            type="datetime-local"
          />
        </Box>
        <Box className={classes.viewtenderinput}>
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={briefingSession}
            focused={true}
            onChange={handleInputChange}
            label="Briefing session date and time:"
            required
            name="briefingSession"
            type="datetime-local"
          />
        </Box>
        <Box className={classes.viewtenderinput}>
          {/* shows calendar and time */}
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={closing}
            focused={true}
            onChange={handleInputChange}
            label="Closing date and time:"
            required
            name="closing"
            type="datetime-local"
          />
        </Box>
        {/* SCM list */}
        <Box className={classes.usersBox}>
          <Typography style={{ color: sentechDarkGrey, width: labelWidth }}>
            SCM Officials:
          </Typography>
          <Box style={{ width: "20%" }}></Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "6%",
            marginLeft: "auto",
            justifyContent: "center",
          }}
        >
          <Box style={{ width: labelWidth }} />
          <Box className={classes.userCards}>
            {currentSCMOfficials.length ? (
              currentSCMOfficials.map((itm) => {
                return itm?.email ? (
                  <div style={{ display: "flex" }} key={itm.id}>
                    <Typography
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {itm.email}
                    </Typography>
                  </div>
                ) : null;
              })
            ) : (
              <Typography>No other SCM Officials have been added</Typography>
            )}
          </Box>
        </Box>
        {!disabled && (
          <Box className={classes.viewtenderinput}>
            <CustomAutoComplete
              width={labelWidth}
              value={scmOfficials}
              label=""
              onChange={(event, newValue) => setScmOfficials(newValue)}
              options={SCMlist}
            />
          </Box>
        )}
        {/* BSEC list */}
        <Box className={classes.usersBox}>
          <Typography style={{ color: sentechDarkGrey, width: labelWidth }}>
            BSEC Members:
          </Typography>
          <Box style={{ width: "20%" }}></Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: "6%",
            marginLeft: "auto",
            justifyContent: "center",
          }}
        >
          <Box style={{ width: labelWidth }} />
          <Box className={classes.userCards}>
            {currentBSECMembers.length ? (
              currentBSECMembers.map((itm) => {
                return (
                  <div style={{ display: "flex" }} key={itm.id}>
                    {disabled ? (
                      <>
                    <Typography
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {itm.email}
                    </Typography>
                    {/* <IconButton
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveDialogOpen}
                    >
                      <CloseOutlined />
                    </IconButton>
                    <RemoveUserDialog
                      open={openRemove}
                      handleClose={handleRemoveDialogClose}
                      onDeleteClicked={() => removeUser(itm.id, "bsec")}
                    /> */}
                    </>
                    ) : (
                    <>
                      <Typography
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        {itm.email}
                      </Typography>
                      <IconButton
                        style={{ cursor: "pointer" }}
                        onClick={handleRemoveDialogOpen}
                      >
                      <CloseOutlined />
                      </IconButton>
                      <RemoveUserDialog
                        open={openRemove}
                        handleClose={handleRemoveDialogClose}
                        onDeleteClicked={() => removeUser(itm.id, "bsec")}
                      />
                    </>
                    )}
                      
                  </div>
                );
              })
            ) : (
              <Typography>No BSEC Members have been added</Typography>
            )}
          </Box>
        </Box>
        {!disabled && (
          <Box className={classes.viewtenderinput}>
            <CustomAutoComplete
              width={labelWidth}
              value={bsecMembers}
              label=""
              options={BSECList}
              onChange={(event, newValue) => setBsecMembers(newValue)}
            />
          </Box>
        )}
        {/*Bidder list  */}
        {state.tender_type === "private" && (
          <>
            <Box className={classes.usersBox}>
              <Typography style={{ color: sentechDarkGrey, width: labelWidth }}>
                Bidders:
              </Typography>
              <Box style={{ width: "20%" }}></Box>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                paddingLeft: "6%",
                marginLeft: "auto",
                justifyContent: "center",
              }}
            >
              <Box style={{ width: labelWidth }} />
              <Box className={classes.userCards}>
                {currentBidders.length ? (
                  currentBidders.map((itm) => {
                    return <Typography key={itm.id}>{itm.email}</Typography>;
                  })
                ) : (
                  <Typography>No Bidders have been added</Typography>
                )}
              </Box>
            </Box>
            {!disabled && (
              <Box className={classes.viewtenderinput}>
                <CustomAutoComplete
                  width={labelWidth}
                  value={bidders}
                  label=""
                  onChange={(event, newValue) => setBidders(newValue)}
                  options={biddersList}
                />
              </Box>
            )}
          </>
        )}
         
        <Box className={classes.filelist}>
          <FileList
            fileList={files}
            // uploadList={uploadFiles}
            uploadList={uploadFiles.map((file) => ({
            key: `-${file.file.name}`,
            type: file.file.type,
            }))}
            deletable={false}
          />
        </Box>
        {!disabled && (
          <>
          <Box style={{ marginTop: "1%", marginBottom: "1%", marginLeft: "30%"}}>
            <Typography style={{color: sentechBlue, fontSize: "14px" }}>Please upload only .zip or .pdf files. An error will occur if the incorrect file type is uploaded.</Typography>
          </Box>
          <Box className={classes.dragdropbox}>
            <FileUpload
              fileList={(files) => {
                setUploadFiles(files.map((file) => ({ file })));
                setOpenUpdateFile(true);
              }}
              accepted={{ "application/zip": [".zip"] }}
              maxFiles={1}
              error={(e) => {
                setMessage(e);
                setSeverity("error");
                openSnackbar();
              }}
            />
            <UpdateFileDialog
              open={openUpdateFile}
              handleClose={handleUpdateDialogClose}
              onUpdateClicked={handleUpdateFile}
              onCancel={() => setUploadFiles([])}
            />
          </Box>
          </>
        )}
        <Box className={classes.tenderforum}>
          <EditTenderForum
            messages={messages}
            handleOnPageReLoad={handleOnPageReLoad}
            onNewMessage={(newMessage) => {
              webSocket.current.send(
                JSON.stringify({
                  action: "message",
                  message: {
                    message: newMessage,
                    tender_id: state.tender_id,
                  },
                })
              );
            }}
          />
        </Box>
        <Box className={classes.bsecResponses}>
          <BSECResponses
            messages={BSECmessages}
            onUpdate={(id, status) => {
              if (status === "accepted") {
                const newMessgae = BSECmessages.filter((itm) => itm.id === id);
                setMessages((prev) => [...prev, ...newMessgae]);
              }
              setBSECMessages((prev) => prev.filter((itm) => itm.id !== id));
            }}
          />
        </Box>
        <Box className={classes.Userbuttonbox}>
          {disabled ? (
            <>
              <CustomButtonOutlined onClick={handleCancel} text="Back" />
              <CustomButtonSolid onClick={handleDisabled} text="Edit Tender" />
            </>
          ) : (
            <>
              <CustomButtonOutlined onClick={handleDisabled} text="Cancel" />
              <CustomButtonSolid onClick={handleOnSubmit} text="Save Changes" />
              {/* <CustomButtonSolid
                  onClick={handleDeleteDialogOpen}
                  text="Delete Tender"
                />
                <DeleteTenderDialog
                  open={openDelete}
                  handleClose={handleDeleteDialogClose}
                  onDeleteClicked={handleDeleteTender}
                /> */}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ViewTender;

function compare(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
