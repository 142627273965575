import React from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue } from "../../../theme/colors";

// styling 
const useStyles = makeStyles((theme) =>
    createStyles({
        footertext: {
            padding: "10px 10px 0px 10px",
            color: "white",
            display: "flex",
            justifyContent: "flex-start",
            fontSize: "small",
            paddingLeft: "7%"
        },
        
        footer: {
            marginTop: "auto",
            position: "relative",
            bottom: "0",
            width: "100%",
            height: "95px",
            background: sentechBlue,
        },

        footerbox: {
            paddingTop: "1.2%"
        }
    })
);

function Footer() {

    // material ui 
    const classes = useStyles();

    return(
        <Box className={classes.footer}>
            <Box className={classes.footerbox}>
                <Typography className={classes.footertext}>SENTECH SOC Ltd © All rights reserved</Typography>
            </Box>
        </Box>
   )
}

export default Footer;
