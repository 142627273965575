import { Box, Link } from "@material-ui/core";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
// dialog pop up imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// toast message imports
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Cancel } from "@material-ui/icons";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import TenderTable from "../../tenderTables/tenderTable";
import DragDropFile from "../../dragDropInput/dragDropInput";
import CustomDropDown from "../../inputFields/DropdownField";
import FileList from "../../files/fileList";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../../theme/colors";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import FileUpload from "../../dragDropInput/fileUpload";
import TenderForum from "../../forum/bidderForum/Forum";
import NewFileDialog from "./dialogs/newFileDialog";
import DeleteTenderDialog from "./dialogs/deleteTenderDialog";
import SubmitDialog from "./dialogs/submitTenderDialog";
import axios from "axios";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { endpoint } from "../../../endpoint/endpoints";
import {
  tenderErorr,
  tenderResponseError,
} from "../../../apiRequests/errorMessages";
import UploadFileList from "../../files/uploadFileList";
import { useEffect } from "react";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    tenderresponsetable: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    tenderforum: {
      width: "80%",
      justifyContent: "flex-start",
    },

    canceldeletebutton: {
      paddingRight: "7%",
    },

    dialogcancelbutton: {
      backgroundColor: "white",
      borderColor: sentechBlue,
      color: "black",
      padding: "16px 28px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "14px",
      borderRadius: "12px",
      cursor: "pointer",
      marginRight: "40px",
    },

    dialogconfirmbutton: {
      backgroundColor: sentechBlue,
      border: "none",
      color: "white",
      padding: "16px 28px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "13px",
      borderRadius: "12px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: sentechBlue,
        border: "none",
        color: "white",
      },
    },

    filelist: {
      width: "80%",
      justifyContent: "flex-start",
    },

    dragdropbox: {
      width: "93%",
      // paddingLeft: "6.5%",
      paddingBottom: "2%",
      marginLeft: "auto",
      marginRight: "auto",
    },

    buttonboxes: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: "3%",
    },

    paginations: {
      display: "flex",
      justifyContent: "space-evenly",
      paddingTop: "20px",
      paddingBottom: "60px",
      paddingRight: "50px",
      color: sentechDarkGrey,
    },
    text: {},

    newtext: {
      marginLeft: "7.5%",
      marginTop: "2.5%",
    }
  })
);

function TenderResponsePage() {
  // material ui
  const classes = useStyles();

  const { setMessage, openSnackbar, setLoading, setSeverity } =
    useContext(ApplicationContext);

  // navigate
  const navigate = useNavigate();
  const { state } = useLocation();

  //websocket reference
  const webSocket = React.useRef(null);

  // const [webSocketState, setwebSocketState] = useState(false);

  const [disabled, setDisabled] = useState(
    state.close_datetime <= Date.now() / 1000
  );

  const handleBackClick = () => {
    navigate("/MYTENDERS");
  };

  // 
  // const [hasFiles, setHasFiles] = useState(false);

  // 
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   console.log(event.target.files[0])
  //   if (file) {
  //     setHasFiles(true);
  //     console.log(setHasFiles);
  //     // ... do something with the file ...
  //   } else {
  //     setHasFiles(false);
  //   }
  // };  

  const handleOnSubmit = async () => {
    // 
    // if (!hasFiles) {
    //   console.log(!hasFiles)
    //   setMessage("Please upload at least one file before submitting.");
    //   setSeverity("error");
    //   openSnackbar();
    //   return;
    // }
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.SAVE_SUBMISSION_ENDPOINT, //endpoint
        { my_tenders_id: state.id }, //need my tender id from somewhere
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          navigate("/MYTENDERS/RESPONSE/SUBMITTED", { state: { ...state } });
          setLoading(false);
        } 
        else {
          if (response.data.errorCode === 2) {
            setMessage("There are no submission files to submit");
            setSeverity("error");
            openSnackbar();
          } 
          else {
            setMessage(tenderResponseError(response.data.errorCode));
          }

          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClicc = () => {
    navigate("/MYTENDERS/RESPONSE");
  };

  // tender discussion forum messages
  const [messages, setMessages] = useState([
    // {
    //   id: 1,
    //   message: "hello",
    //   user: 1,
    //   timestamp: 1670154559,
    // },
    // {
    //   id: 2,
    //   message: "hello",
    //   user: 0,
    //   timestamp: 1670154559,
    // },
  ]);

  const handleSubmitOpened = () => {
    if (Date.now() / 1000 >= state.close_datetime) {
      setMessage("Unable to submit. The tender is closed.");
      setSeverity("error");
      openSnackbar();
    } else {
      setOpenSubmitTender(true);
    }
  };
  
  const handleSubmitClosed = () => {
    setOpenSubmitTender(false);
  };

  // files uploaded
  const [S3files, setS3Files] = useState([]);
  const [uploadedTypes, setUploadedTypes] = useState([]);

  const [uploadFiles, setUploadFiles] = useState([]);

  const [untaggedFiles, setUntaggedFiles] = useState([]);

  // delete tender button link with dialog pop up and toast message
  const [openDelete, setOpenDelete] = React.useState(false);

  const handleDeleteDialogOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDelete(false);
  };

  // submit response button with dialog pop up and toast message
  const [openSubmitTender, setOpenSubmitTender] = React.useState(false);

  // const handleSubmitOpened = () => {
  //   setOpenSubmitTender(true);
  // };

  // const handleSubmitClosed = () => {
  //   setOpenSubmitTender(false);
  // };

  const [openNewfile, setOpenNewFile] = React.useState(false);

  const OpenNewFileDialog = () => {
    setOpenNewFile(true);
  };

  const CloseNewFileDialog = () => {
    setOpenNewFile(false);
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Response";
  }, []);

  React.useEffect(() => {
    const handleOnPageLoad = async (event) => {
      // if (myTenders.length) return;
      setLoading(true);
      try {
        const response = await axios.post(
          endpoint.GET_TENDER_ENDPOINT, //endpoint
          { tender_id: state.tender_id },
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        //   const response = apiResponse;
        if (response.status === 200) {
          if (response.data.error === false) {
            // popMyTenders(data);
            if (response.data.message.messages) {
              const messages = response.data.message.messages.sort(compare);
              const mappedMessages = messages.map((itm) => ({
                id: itm.id,
                message: itm.message,
                timestamp: itm.createdAt,
                user: itm.role.includes("bidder") ? 0 : 1,
              }));
              setMessages(mappedMessages);
            }
            const s3Files =
              response.data.message.submission_files.submission_files;
            if (s3Files) {
              setS3Files(s3Files);
              setUploadedTypes(s3Files.map((itm) => itm.type));
            }
            setLoading(false);
          } else {
            setMessage(tenderErorr(response.data.errorCode));
            response.data.errorCode === 5
              ? setSeverity("info")
              : setSeverity("error");
            openSnackbar();
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };

    handleOnPageLoad();
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleOnPageReLoad = async (event) => {
    setLoading(true);
    await delay(1500)
    try {
      const response = await axios.post(
        endpoint.GET_TENDER_ENDPOINT, //endpoint
        { tender_id: state.tender_id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          if (response.data.message.messages) {
            const messages = response.data.message.messages.sort(compare);
            const mappedMessages = messages.map((itm) => ({
              id: itm.id,
              message: itm.message,
              timestamp: itm.createdAt,
              user: itm.role.includes("bidder") ? 0 : 1,
            }));
            setMessages(mappedMessages);
          }
          const s3Files =
            response.data.message.submission_files.submission_files;
          if (s3Files) {
            setS3Files(s3Files);
            setUploadedTypes(s3Files.map((itm) => itm.type));
          }
          setLoading(false);
        } else {
          setMessage(tenderErorr(response.data.errorCode));
          response.data.errorCode === 5
            ? setSeverity("info")
            : setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDeleteTender = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.DELETE_RESPONSE_ENDPOINT, //endpoint
        { tender_id: state.tender_id, files: S3files },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          navigate("/MYTENDERS/RESPONSE/DELETED", { state: { ...state } });
          setLoading(false);
        } else {
          setMessage(tenderResponseError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFileDelete = async (file) => {
    try {
      const response = await axios.post(
        endpoint.DELETE_FILE_ENDPOINT, //endpoint
        { tender_id: state.tender_id, files: file },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          setS3Files((prev) => prev.filter((itm) => itm.key !== file.key));
          setUploadedTypes((prev) => prev.filter((itm) => itm !== file.type));
          setMessage("File successfully Deleted");
          setSeverity("success");
          openSnackbar();
        } else {
          setMessage(tenderResponseError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (webSocket.current) return;
    // if (webSocketState) return;
  
    const connectWebSocket = () => {
      const authToken = localStorage.getItem("authorization");
      const userToken = localStorage.getItem("user_token");
  
      webSocket.current = new WebSocket(
        `${endpoint.WEBSOCKET}?Authorization=${authToken}&user-token=${userToken}`
      );
      webSocket.current.onopen = () => 
        console.log("connected");
        // setwebSocketState(true)
      // };
      webSocket.current.onclose = () => 
        console.log("disconnected");
        // setwebSocketState(false)
        //reconnect
        // setTimeout(() => {
        // webSocket = null; // Reset WebSocket 
        //   connectWebSocket();
        // }, 10000); 
      // };
      webSocket.current.onerror = (e) => console.log(e);
      webSocket.current.onmessage = (e) => {
        const data = JSON.parse(e.data);
        console.log(data);
        switch (data.action) {
          case "message":
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                message: data.message.message.S,
                timestamp: parseInt(data.message.createdAt.N),
                id: data.message.id.S,
                user: data.message.role.L[0].S === "bidder" ? 0 : 1,
              },
            ]);
            break;
          default:
            break;
        }
      };
    };
  
    connectWebSocket();
  
    return () => {
      if (webSocket.current) {
        webSocket.current.close();
        webSocket.current = null;
      }
    };
  }, [webSocket]);
  

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>
          {state.tender_number}
        </Typography>
      </Box>
      <Box className={classes.newtext}>
        <Typography style={{ color: sentechBlue, fontSize: "14px" }}>The time zone used across the portal is South African Standard Time (SAST) (UTC+02:00).</Typography>
      </Box>
      <Box className={classes.tenderresponsetable}>
        <TenderTable
          tableData={[state]}
          page={1}
          perPage={1}
          useCase={"bidderTendersResponse"}
        />
      </Box>
      <Box className={classes.tenderforum}>
        <TenderForum
          messages={messages}
          handleOnPageReLoad={handleOnPageReLoad}
          onNewMessage={(newMessage) => {
            if (Date.now() / 1000 >= state.close_datetime - 604800) {
              setMessage("Forum has closed");
              setSeverity("error");
              openSnackbar();
            } else {
              webSocket.current.send(
                JSON.stringify({
                  action: "message",
                  message: { message: newMessage, tender_id: state.tender_id },
                })
              );
            }
          }}
        />
        <Typography
          // variant="h5"
          style={{
            textAlign: "left",
            margin: "auto 10%",
            paddingLeft: "120px",
            paddingTop: "20px",
            color: sentechDarkGrey,
          }}
        >
          YOUR TENDER RESPONSE
        </Typography>
        <Typography
          variant="body1"
          style={{
            textAlign: "left",
            margin: "auto 10%",
            paddingLeft: "120px",
            paddingBottom: "20px",
            color: sentechDarkGrey,
          }}
        >
          Upload only PDF or .zip files for your response.
          <br />
          Please upload one (1) document at a time.
          <br /> Max number of files that can be uploaded: 3 (1 per category){" "}
          <br />
          Max file size: 30MB <br />
          Your response will be saved automatically, but will not be submitted
          unless you click on the Submit button.
          <br /> Ensure you submit your response before the tender close time
          for your response to be eligible for evaluation.
          <br /> You may edit your response until the close date.
        </Typography>
      </Box>
      {!disabled && (
        <Box>
          {/* delete tender button link with dialog pop up and toast message */}
          <Link
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "1%",
              // paddingBottom: "1%",
              color: sentechBlue,
            }}
            onClick={handleDeleteDialogOpen}
          >
            Delete tender from list
          </Link>
          <DeleteTenderDialog
            open={openDelete}
            handleClose={handleDeleteDialogClose}
            onDeleteClicked={handleDeleteTender}
          />
        </Box>
      )}
      <Box className={classes.filelist}>
        <FileList
          fileList={S3files}
          onDelete={handleFileDelete}
          closed={disabled}
        />
        <UploadFileList
          uploadList={uploadFiles}
          tender_id={state.tender_id}
          onUploadSuccess={(fileName, type) => {
            setUploadFiles((prev) =>
              prev.filter((itm) => itm.file.name !== fileName)
            );
            setUploadedTypes((prev) => [...prev, type]);
            setS3Files((prev) =>
              prev
                ? [...prev, { type: type, key: `-${fileName}` }]
                : [{ type: type, key: `-${fileName}` }]
            );
          }}
        />
      </Box>
      <Box style={{ marginBottom: "1%", marginLeft: "30%"}}>
        <Typography style={{color: sentechBlue, fontSize: "14px" }}>Please upload only .zip or .pdf files. An error will occur if the incorrect file type is uploaded.</Typography>
      </Box>
      <Box className={classes.dragdropbox}>
        {/* <DragDropFile /> */}
        {!disabled && S3files.length + uploadFiles.length < 3 && (
          <FileUpload
            fileList={(files) => {
              if (state.close_datetime <= Date.now() / 1000) {
                setMessage(
                  "The tender has closed and no new submissions may be added"
                );
                setSeverity("error");
                openSnackbar();
              } else {
                if (files.length) {
                  setUntaggedFiles(files);
                  OpenNewFileDialog();
                }
              }
            }}
            accepted={{
              "application/pdf": [".pdf"],
              "application/zip": [".zip"],
            }}
            maxFiles={1}
            error={(e) => {
              setMessage(e);
              setSeverity("error");
              openSnackbar();
            }}
          />
        )}
        <Box className={classes.choosefilebutton}>
          <NewFileDialog
            uploadedTypes={uploadedTypes}
            opened={openNewfile}
            closed={CloseNewFileDialog}
            untaggedFiles={untaggedFiles}
            onTaggedFiles={(files) => {
              setUploadFiles((prev) => [...prev, ...files]);
            }}
          />
        </Box>
      </Box>
      <Box className={classes.buttonboxes}>
        <CustomButtonOutlined onClick={(e) => handleBackClick()} text="Back" />

        {/* submit response button with dialog pop up and toast message */}
        {!disabled && (
          <CustomButtonSolid
          // disabled={!hasFiles}
            onClick={handleSubmitOpened}
            text="Submit your response"
          />
        )}
        <SubmitDialog
          open={openSubmitTender}
          handleClosed={handleSubmitClosed}
          onSubmit={() => handleOnSubmit()}
        />
      </Box>
    </Box>
  );
}

export default TenderResponsePage;

function compare(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
