import React from "react";
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechDarkGrey, sentechBlue } from "../../theme/colors";
import { useNavigate } from "react-router";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      border: "0%",
    },

    rowheadingtext: {
      // fontSize: "12px",
      color: "white",
      backgroundColor: sentechBlue,
      borderBottom: "none",
    },

    rowtext: {
      // fontSize: "10px",
      color: sentechDarkGrey.at,
      borderBottom: "none",
    },

    rowtextDark: {
      // fontSize: "10px",
      color: sentechDarkGrey,
      "&.MuiTableRow-root": { backgroundColor: "rgba(112, 111, 111, 0.06  )" },
      borderBottom: "none",
    },
  })
);

export default function TenderSubmissionTable({
  tableData,
  page,
  perPage,
  useCase,
  tender_id,
}) {
  // material ui
  const classes = useStyles();

  const navigate = useNavigate();

  // table data function
  function currentData() {
    const begin = (page - 1) * perPage;
    const end = begin + perPage;
    return tableData.slice(begin, end);
  }

  function getLink(info) {
    // bsec
    if (useCase === "bsecTenderSubmissionList")
      return (
        <Link
          style={{ color: sentechBlue }}
          onClick={() => {
            navigate("./TENDERRESPONSE", { state: { ...info, tender_id } });
          }}
        >
          View responses
        </Link>
      );

    // scm
    if (useCase === "scmTenderSubmissionList")
      return (
        <Link
          style={{ color: sentechBlue }}
          onClick={() => {
            navigate("./SUBMISSION/RESPONSE", {
              state: { ...info, tender_id },
            });
          }}
        >
          View responses
        </Link>
      );
    return;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: "max-content"}} aria-label="simple table">
          {/* table headings */}
          <TableHead>
            <TableRow>
              <TableCell className={classes.rowheadingtext}>Sr.No.</TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Name
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Email
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Contact Details
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company Registration No.
              </TableCell>
              <TableCell className={classes.rowheadingtext}>
                Company CSD No.
              </TableCell>
              <TableCell className={classes.rowheadingtext}></TableCell>
            </TableRow>
          </TableHead>
          {/* table data */}
          <TableBody>
            {currentData().map((row, index) => (
              <TableRow
                className={index % 2 ? classes.rowtextDark : classes.rowtext}
                key={row["id"]}
                sx={{ "&:last-chld td, &:last-child th": { border: 0 } }}
              >
                <TableCell className={classes.rowtext}>
                  {index + 1 + (page - 1) * perPage}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.company_name}
                </TableCell>
                <TableCell className={classes.rowtext}>{row.email}</TableCell>
                <TableCell className={classes.rowtext}>
                  {row.company_phone}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.reg_number}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {row.csd_number}
                </TableCell>
                <TableCell className={classes.rowtext}>
                  {" "}
                  {getLink(row)}{" "}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
