import React, { useState, useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { endpoint } from "../../../endpoint/endpoints";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import Sidebar from "../../layout/biddersSidebar/sideBar";
import TenderTable from "../../tenderTables/tenderTable";
import CustomPagination from "../../layout/pagination/pagination";
import { tenderErorr } from "../../../apiRequests/errorMessages";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "7.5%",
      paddingTop: "2.5%",
    },
    text: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: "7.5%",
      paddingTop: "2.5%",
      color: sentechDarkGrey,
    },
    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    table: {
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
  })
);

function OpenTenderPage() {
  // material ui
  const classes = useStyles();

  const {
    popPublicTenders,
    publicTenders,
    setMessage,
    openSnackbar,
    setLoading,
    categories,
    popCategories,
    setSeverity
  } = useContext(ApplicationContext);

  const handleOnPageLoad = async (event) => {
    setLoading(false);
    if (!categories.length)
      try {
        const response = await axios.get(
          endpoint.LIST_CATEGORIES, //endpoint
          {
            headers: {
              "user-token": localStorage.getItem("user_token"),
              authorization: localStorage.getItem("authorization"),
            },
          }
        );
        if (response.status === 200) {
          if (response.data.error === false) {
            const data = response.data.message.categories;
            const fetchedCat = data.map((category) => ({
              label: category.name,
              id: category.id,
            }));
            popCategories(fetchedCat);
          }
        }
        // setLoading(false);
      } catch (error) {}

    try {
      const response = await axios.get(endpoint.LIST_PUBLIC_ENDPOINT);
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message.resp_items;
          popPublicTenders(
            data.filter((itm) => itm.close_datetime > Date.now() / 1000)
          );
          setLoading(false);
        } else {
          setMessage(tenderErorr(response.data.errorCode));
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
        console.log("http error");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Open Tenders";
  }, []);

  //fetech tenders on page load
  React.useEffect(() => {
    const refresh = setInterval(() =>
    handleOnPageLoad() 
    ,2820
    );
    return () => {
      clearInterval(refresh)
    };
  }, []);
      
  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(publicTenders.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>OPEN TENDERS</Typography>
      </Box>
      <Box className={classes.text}>
        <Typography>
          <strong>Important information to all Bidders</strong>
          <br /> • All bids will close at 12pm on the date specified in the bid
          document.
          <br /> • Bids must be submitted electronically on the eTender
          platform.
          <br /> • Bidders are encouraged to submit early to avoid technical
          glitches.
          <br /> • All clarification seeking questions must be posted on the
          eTender Portal seven (7) days before the closing date advertised.
          <br />• Should you encounter any problem, please contact Bid office on
          (+27) 67 427 0292.
        </Typography>
      </Box>
      <Box className={classes.table}>
        <TenderTable
          tableData={publicTenders}
          page={page}
          perPage={perPage}
          useCase={"bidderOpenTendersList"}
        />
      </Box>
      <Box className={classes.paginations}>
        <CustomPagination onNewPage={onNewPage} count={maxPage} />
      </Box>
    </Box>
  );
}

export default OpenTenderPage;
