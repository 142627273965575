import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from "@material-ui/core";
import { sentechBlue } from "../../../theme/colors";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import CustomButtonSolid from "../../layout/buttons/solidButton";


const useStyles = makeStyles((theme) =>
  createStyles({
    canceldeletebutton: {
      paddingRight: "5%",
    },

    dialogcancelbutton: {
      backgroundColor: "white",
      borderColor: sentechBlue,
      color: "black",
      padding: "16px 28px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "14px",
      borderRadius: "12px",
      cursor: "pointer",
      marginRight: "40px",
    },

    dialogconfirmbutton: {
      backgroundColor: sentechBlue,
      border: "none",
      color: "white",
      padding: "16px 28px",
      textAlign: "center",
      textDecoration: "none",
      display: "inline-block",
      fontSize: "13px",
      borderRadius: "12px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: sentechBlue,
        border: "none",
        color: "white",
      },
    },

    buttonboxes: {
      paddingLeft: "50%",
      paddingRight: "25%",
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "3%",
    },
  })
);

export default function DeleteUserDialog({
  open,
  handleClose,
  onDeleteClicked,
}) {
  const handleClick = () => {
    onDeleteClicked();
  };
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Confirm that you want to delete this User
          <br />
          Once deleted, you will not be able to recover this User's data
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButtonOutlined
          className={classes.canceldeletebutton}
          onClick={handleClose}
          text="Cancel"
        />

        <CustomButtonSolid
          className={classes.deletebutton}
          onClick={handleClick}
          text="Delete User"
        />
      </DialogActions>
    </Dialog>
  );
}
