import Link from "./sideBarLink";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey, sentechLightGrey } from "../../../theme/colors";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    navbar: {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "center",
      width: "100%",
      // verticalAlign:"middle",
      // paddingRight: "3%",
      paddingLeft: "5%",
      // backgroundColor: "white",
      // borderBottom: sentechDarkGrey,
      // "2px solid"
      // webkitBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
      // mozBoxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
      // boxShadow: "0 4px 6px -6px rgb(155, 154, 158)",
    },

    navlink: {
      verticalAlign: "middle",
      // width: "200px",
      // padding: "20px 20px",
      // margin: "10px auto 0 auto",
      // float: "left",
      // borderRadius: "20px",
    },

    profile: {
      textDecoration: "none", 
      color: sentechLightGrey, 
      paddingTop: "1%", 
      "&:hover": {
        textDecoration: "none",
        color: sentechBlue,
      },
    }
  })
);

function SentechSidebar({ child }) {
  // material ui
  const classes = useStyles();

  function getUserNav() {
    if (localStorage.getItem("scmRole") || localStorage.getItem("bsecRole")) {
      return (
        <>
          {" "}
          <Link text="AVAILABLE TENDERS" path={"/AVAILABLETENDERS"} />
          <Link text="CLOSED TENDERS" path={"/CLOSEDTENDERS"} />
        </>
      );
    }
  }

  function getAdminNav() {
    if (localStorage.getItem("adminRole")) {
      return (
        <>
          {" "}
          <Link text="DASHBOARD" path={"/DASHBOARD"} />
          <Link text="SENTECH USERS" path={"/SENTECHUSERS"} />
          <Link text="BIDDERS" path={"/BIDDERS"} />
        </>
      );
    }
  }

  return (
    <div className={classes.navbar}>
      {/* <nav className={classes.navbar}> */}
      {getAdminNav()}
      {getUserNav()}
      {/* <Link text="MY PROFILE" path={"/PROFILE"} /> */}
      <a className={classes.profile} href="/PROFILE">MY PROFILE</a>
      {child}
      {/* </nav> */}
    </div>
  );
}

export default SentechSidebar;
