import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useDropzone } from "react-dropzone";
import {
  sentechBlue,
  sentechDarkGrey,
  sentechLightGrey,
} from "../../theme/colors";

import axios from "axios";

const useStyles = makeStyles((theme) =>
  createStyles({
    formFileUpload: {
      height: "16rem",
      textAlign: "center",
      /* position: relative; */
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "auto 10%",
      width: "80%",
      alignItems: "center",
      borderWidth: "2px",
      borderRadius: "1rem",
      borderStyle: "solid",
      borderColor: sentechLightGrey,
      backgroundColor: "white",
      cursor: "pointer",
    },
  })
);


const maxSize = 30000000;

function SizeValidator(file) {
  if (file.size > maxSize) {
    return {
      code: "file-too-large",
      message: `File is larger than the max size of 30 MB`
    };
  }

  return null
}


export default function FileUpload({ fileList, accepted, maxFiles, error }) {
  const classes = useStyles();
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    fileList(acceptedFiles);
    if (fileRejections.length) error(fileRejections[0].errors[0].message);
    // Do something with the files
  }, []);
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop,
      accept: accepted,
      maxFiles: maxFiles,
      validator: SizeValidator
    });

  return (
    <div {...getRootProps({ className: classes.formFileUpload })}>
      <input {...getInputProps()} />
      {
        <Box>
          <CloudUploadOutlinedIcon />
          <Typography style={{ color: sentechDarkGrey }}>
            Drag and drop your file here, or click to select file
          </Typography>
        </Box>
      }
    </div>
  );
}

// import React, { useState, useContext } from "react";
// import {
//   Box,
//   createStyles,
//   makeStyles,
//   Snackbar,
//   Typography,
// } from "@material-ui/core";
// import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
// import { useDropzone } from "react-dropzone";
// import { Alert } from "@mui/material";
// import axios from "axios";
// import { endpoint } from "../../endpoint/endpoints";
// import { sentechDarkGrey, sentechLightGrey } from "../../theme/colors";
// import { ApplicationContext } from "../../context/ApplicationContext";
// import convertUnixToDateTime from "../../utilities/unixToMUIDateTime";


// const useStyles = makeStyles((theme) =>
//   createStyles({
//     formFileUpload: {
//       height: "16rem",
//       textAlign: "center",
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "center",
//       margin: "auto 10%",
//       width: "80%",
//       alignItems: "center",
//       borderWidth: "2px",
//       borderRadius: "1rem",
//       borderStyle: "solid",
//       borderColor: sentechLightGrey,
//       backgroundColor: "white",
//       cursor: "pointer",
//     },
//   })
// );

// const maxSize = 30000000;



// function SizeValidator(file) {
//   if (file.size > maxSize) {
//     return {
//       code: "file-too-large",
//       message: `File is larger than the max size of 30 MB`,
//     };
//   }

//   return null;
// }

// export default function FileUpload({ fileList, accepted, maxFiles }) {
//   const classes = useStyles();
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");

//   const [category, setCategory] = useState("");
// const [tenderNumber, setTenderNumber] = useState("");
// const [tenderType, setTenderType] = useState("");
// const [tenderDescription, setTenderDescription] = useState("");
// const [briefingSession, setBriefingSession] = useState("");
// const [closing, setClosing] = useState("");
// const [issueDate, setIssueDate] = useState(
//   convertUnixToDateTime(Date.now() / 1000)
// );

// //event handlers for textfields
// const handleInputChange = (event) => {
//   switch (event.target.name) {
//     case "category":
//       setCategory(event.target.value);
//       break;

//     case "tenderNumber":
//       setTenderNumber(event.target.value);
//       break;

//     case "tenderType":
//       setTenderType(event.target.value);
//       break;

//     case "tenderDescription":
//       setTenderDescription(event.target.value);
//       break;

//     case "briefingSession":
//       if (
//         Date.now() / 1000 >=
//         new Date(event.target.value).valueOf() / 1000
//       ) {
//         // setSnackbar("Briefing Session must be a future date-time");
//         // setSeverity("error");
//         // openSnackbar();
//         break;
//       }
//       let briefingSessionDate = new Date(event.target.value).valueOf() / 1000;
//       let closingDate = new Date(closing).valueOf() / 1000;
//       if (
//         briefingSessionDate >= closingDate ||
//         briefingSessionDate <= Date.now() / 1000 ||
//         briefingSessionDate === closingDate
//       ) {
//         // setMessage(
//         //   "Briefing Session must be a date-time before Closing Date and not the same as Closing Date or a date-time after Closing Date"
//         // );
//         // setSeverity("error");
//         // openSnackbar();
//         break;
//       }
//       setBriefingSession(event.target.value);
//       break;

//     case "closing":
//       if (
//         new Date(briefingSession).valueOf() / 1000 >=
//           new Date(event.target.value).valueOf() / 1000 ||
//         !briefingSession
//       ) {
//         // setMessage("Closing Date must be after Briefing Session");
//         // setSeverity("error");
//         // openSnackbar();
//         break;
//       }
//       setClosing(event.target.value);
//       break;

//     case "issueDate":
//       setIssueDate(event.target.value);
//       break;

//     default:
//       break;
//   }
// };



//   const onDrop = async (acceptedFiles, fileRejections) => {
//     if (fileRejections.length) {
//       displaySnackbar(fileRejections[0].errors[0].message, "error");
//     } else {
//       fileList(acceptedFiles);
//       try {
//         const formData = new FormData();
//         formData.append("file", acceptedFiles[1]);

//         const response = await axios.post(
//           endpoint.ADD_TENDER,
//           {
//             category_id: category,
//             tender_number: tenderNumber,
//             tender_type: tenderType,
//             tender_description: tenderDescription,
//             brief_session: new Date(briefingSession).valueOf() / 1000,
//             close_datetime: new Date(closing).valueOf() / 1000,
//             issue_date: new Date(issueDate).valueOf() / 1000,
//             filename: acceptedFiles[0].name,
//           },
//           {
//             headers: {
//               "user-token": localStorage.getItem("user_token"),
//               authorization: localStorage.getItem("authorization"),
//             },
//           }
//         );

//         if (response.status === 200 && response.data.error === false) {
//           // File uploaded successfully
//           displaySnackbar("File has been uploaded successfully", "success");
//         } else {
//           // Error occurred during file upload
//           displaySnackbar(
//             "File was unable to be uploaded successfully",
//             "error"
//           );
//         }
//       } catch (error) {
//         // Error occurred during file upload
//         displaySnackbar(
//           "File was unable to be uploaded successfully",
//           "error"
//         );
//       }
//     }
//   };

//   const displaySnackbar = (message, severity) => {
//     setSnackbarMessage(message);
//     setSnackbarSeverity(severity);
//     setSnackbarOpen(true);
//   };

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     accept: accepted,
//     maxFiles: maxFiles,
//     validator: SizeValidator,
//   });

//   return (
//     <div {...getRootProps({ className: classes.formFileUpload })}>
//       <input {...getInputProps()} />
//       {isDragActive ? (
//         <Typography style={{ color: sentechDarkGrey }}>
//           Drop the file here...
//         </Typography>
//       ) : (
//         <Box>
//           <CloudUploadOutlinedIcon />
//           <Typography style={{ color: sentechDarkGrey }}>
//             Drag and drop your file here, or click to select file
//           </Typography>
//         </Box>
//       )}
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={4000}
//         onClose={() => setSnackbarOpen(false)}
//       >
//         <Alert
//           onClose={() => setSnackbarOpen(false)}
//           severity={snackbarSeverity}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// }
