import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import Sidebar from "../../layout/bsecSidebar/sideBar";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";
import TenderSubmissionTable from "../../tenderTables/tenderSubmissionTable";
import CustomPagination from "../../layout/pagination/pagination";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechDarkGrey } from "../../../theme/colors";
import TenderTable from "../../tenderTables/tenderTable";
import { useLocation, useNavigate } from "react-router";
import { useContext } from "react";
import { ApplicationContext } from "../../../context/ApplicationContext";
import axios from "axios";
import {
  submissionReviewError,
  tenderErorr,
  tenderManagementError,
} from "../../../apiRequests/errorMessages";
import { endpoint } from "../../../endpoint/endpoints";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import CustomDropDown from "../../inputFields/DropdownField";
import ConfirmTechReviewDialog from "./confirmTechReviewDialog";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      color: sentechDarkGrey,
    },

    submissionstable: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
      paddingBottom: "2%",
    },

    submissionheading: {
      justifyContent: "fex-start",
      paddingLeft: "7.5%",
      display: "flex",
      color: sentechDarkGrey,
    },

    submissionstable2: {
      // paddingLeft: "4%",
      marginLeft: "7%",
      marginRight: "7%",
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      margin: "1%",
    },

    paginations: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "2%",
      paddingBottom: "3%",
      paddingRight: "9.5%",
      color: sentechDarkGrey,
    },
    statusBox: {
      width: "100%",
    },
    Userbuttonbox: {
      paddingBottom: "5%",
      paddingTop: "4%",
      display: "flex",
      justifyContent: "center",
    },
  })
);

function TenderSubmission() {
  // material ui
  const classes = useStyles();

  const { setMessage, openSnackbar, setLoading, setSeverity } =
    useContext(ApplicationContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  const [submissionList, setSubmissionList] = useState([]);
  const [status, setStatus] = useState(state.status);
  const [viewTechnicalReview, setViewTechnicalReview] = useState(
    state.role.includes("scm") && !state.technical_review
  );
  const [tenderData, setTenderData] = useState([state]);
  const [techReview, setTechReview] = useState(state.technical_review);

  //page title seen on browser
  React.useEffect(() => {
    document.title = "Submission";
  }, []);

  const handleOnPageLoad = async (event) => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.LIST_SUBMISSIONS, //endpoint
        { tender_id: state.tender_id }, //TODO: update id
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const data = response.data.message;
          //TODO: check bidder data lines up
          const bidderData = data.bidder_data;
          const userData = data.user_data;
          // const returnData = bidderData.map((itm) => {
          //   const matched = userData.find((item) => item.id === itm.user_id);
          //   let output1 = matched;

          //   let output2 = itm;

          //   return { ...output1, ...output2 };
          // });
          const returnData = bidderData.map((item, index) => {
            const matched = userData.find((itm) => itm.id === item.id);
            return { ...matched, ...item };
          });
          setSubmissionList(returnData);
          setLoading(false);
        } else {
          setMessage(submissionReviewError(response.data.errorCode));
          setSeverity("error");
          setLoading(false);
          openSnackbar();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleOnPageLoad();
  }, []);

  const handleFinancialRelease = async (event) => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.EDIT_TENDER, //endpoint
        {
          tender_id: state.tender_id,
          category_id: state.category_id,
          tender_number: state.tender_number,
          tender_type: state.tender_type,
          tender_description: state.tender_description,
          brief_session: state.brief_session,
          close_datetime: state.close_datetime,
          issue_date: state.issue_date,
          technical_review: true,
          status: status,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          setViewTechnicalReview(false);
          setTechReview(true);
          setMessage("Tender Successfully Updated");
          setSeverity("success");
          openSnackbar();
          setLoading(false);
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      // console.log(response);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (event) => {
    setLoading(true);
    try {
      const response = await axios.post(
        endpoint.EDIT_TENDER, //endpoint
        {
          tender_id: state.tender_id,
          category_id: state.category_id,
          tender_number: state.tender_number,
          tender_type: state.tender_type,
          tender_description: state.tender_description,
          brief_session: state.brief_session,
          close_datetime: state.close_datetime,
          issue_date: state.issue_date,
          technical_review: techReview,
          status: status,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          setTenderData((prev) =>
            prev.map((itm) => ({ ...itm, status: status }))
          );
          setMessage("Tender Successfully Updated");
          setSeverity("success");
          openSnackbar();
          setLoading(false);
        } else {
          setMessage(tenderManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
      // console.log(response);
    } catch (error) {
      setLoading(false);
    }
  };

  const StatusOptions = [
    { label: "Advertised", id: "Advertised" },
    { label: "Closed", id: "Closed" },
    { label: "Evaluation", id: "Under evaluation" },
    { label: "Awarded", id: "Awarded" },
  ];

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "status":
        setStatus(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    navigate("/CLOSEDTENDERS");
  };

  // function for pagination
  const perPage = 5;
  const maxPage = Math.ceil(submissionList.length / perPage);

  const [page, setPage] = useState(1);

  function onNewPage(newPage) {
    setPage(newPage);
  }

  const [openDialog, setOpenDialog] = React.useState(false);

  const OpenDialog = () => {
    setOpenDialog(true);
  };

  const CloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>
      <Box className={classes.heading}>
        <Typography style={{ fontSize: "24px" }}>
          {state.tender_number}
        </Typography>
      </Box>
      <Box className={classes.submissionstable}>
        <TenderTable
          tableData={tenderData}
          page={1}
          perPage={1}
          useCase={"bsecTenderDetailsList"}
        />
      </Box>
      {state.role.includes("scm") && (
        <>
          <Box className={classes.statusBox}>
            <CustomDropDown
              options={StatusOptions}
              width={"200px"}
              value={status}
              onChange={handleInputChange}
              label="Status:"
              required
              name="status"
              type="text"
            />
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <CustomButtonSolid
              text={"Update Status"}
              onClick={handleStatusUpdate}
            />
          </Box>
        </>
      )}
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
        }}
      >
        {viewTechnicalReview ? (
          <>
            <CustomButtonSolid
              text={"Release financial documents"}
              onClick={OpenDialog}
            />
            <ConfirmTechReviewDialog
              open={openDialog}
              handleClose={CloseDialog}
              onClicked={handleFinancialRelease}
            />
          </>
        ) : null}
      </Box>
      <Box className={classes.submissionheading}>
        <Typography>Submissions available to evaluate:</Typography>
      </Box>
      <Box className={classes.submissionstable2}>
        <TenderSubmissionTable
          tableData={submissionList}
          tender_id={state.tender_id}
          page={page}
          perPage={perPage}
          useCase={"bsecTenderSubmissionList"}
        />
      </Box>
      <Box className={classes.paginations}>
        <CustomPagination onNewPage={onNewPage} count={maxPage} />
      </Box>
      <Box className={classes.Userbuttonbox}>
        <CustomButtonOutlined onClick={handleBack} text="Back" />
      </Box>
    </Box>
  );
}

export default TenderSubmission;
