import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import BreadCrumb from "../../layout/breadcrumbs/breadcrumbs";

import CustomInput from "../../inputFields/Inputfields";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { sentechBlue, sentechDarkGrey } from "../../../theme/colors";
import CustomButtonSolid from "../../layout/buttons/solidButton";
import CustomButtonOutlined from "../../layout/buttons/outlinedButton";
import { ApplicationContext } from "../../../context/ApplicationContext";
import { endpoint } from "../../../endpoint/endpoints";
import axios from "axios";
import { userManagementError } from "../../../apiRequests/errorMessages";
import DeleteUserDialog from "./deleteUserDialog";

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "9.3%",
      paddingTop: "2.5%",
    },

    heading: {
      display: "flex",
      justifyContent: "center",
      paddingTop: "3%",
      paddingBottom: "2%",
      color: sentechDarkGrey,
    },

    viewbidderinput: {
      paddingLeft: "6%",
    },

    role: {
      // paddingLeft: "6%",
      display: "flex",
      vertical: "center",
      justifyContent: "center",
      marginTop: "5px",
      marginLeft: "auto",
    },

    checkbox: {
      "&.MuiCheckbox-colorSecondary.Mui-checked": {
        color: sentechBlue,
      },
    },

    checkboxes: { width: "20%", color: sentechDarkGrey },

    newuserbuttonbox1: {
      paddingTop: "3%",
      paddingBottom: "5%",
      display: "flex",
      margin: "auto",
      justifyContent: "center",
    },
  })
);

function ViewUser() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  React.useEffect(() => {
    document.title = "View user";
  }, []);

  const { setMessage, openSnackbar, setLoading, setSeverity } =
    useContext(ApplicationContext);

  // set form width
  const labelWidth = "200px";

  // navigation
  const navigate = useNavigate();

  const { state } = useLocation();

  function handleCancel() {
    navigate("/SENTECHUSERS");
  }

  const [name, setName] = useState(state.firstName);
  const [surname, setSurname] = useState(state.lastName);
  const [employeeReference, setEmployeeReference] = useState(state.employeeRef);
  const [email, setEmail] = useState(state.email);
  const [department, setDepartment] = useState(state.department);
  const [designation, setDesignation] = useState(state.designation);
  const [checkbox, setCheckbox] = useState({
    admin: state.role.includes("admin")??false,
    bsec: state.role.includes("bsec")??false,
    scm: state.role.includes("scm")??false,
  });
  //   const [role, setRole] = useState("");

  const [disabled, setDisabled] = useState(true);

  function handleDisabled(event) {
    event.preventDefault();
    setDisabled(!disabled);
  }

  function handleEditCancel() {
    setDisabled(!disabled);
    setName(state.firstName);
    setSurname(state.lastName);
    setEmployeeReference(state.employeeRef);
    setDepartment(state.department);
    setDesignation(state.designation);
    setCheckbox({
      admin: state.role.includes("admin"),
      bsec: state.role.includes("bsec"),
      scm: state.role.includes("scm"),
    });
  }

  const handleCheckboxChange = (event) => {
    setCheckbox({
      ...checkbox,
      [event.target.name]: event.target.checked,
    });
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "name":
        setName(event.target.value);
        break;

      case "surname":
        setSurname(event.target.value);
        break;

      case "employeeReference":
        setEmployeeReference(event.target.value);
        break;

      case "email":
        setEmail(event.target.value);
        break;

      case "department":
        setDepartment(event.target.value);
        break;

      case "designation":
        setDesignation(event.target.value);
        break;

      // case "role":
      //     setRole(event.target.value);
      //     break;

      default:
        break;
    }
  };

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleDeleteDialogOpen = () => {
    setOpenDelete(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDelete(false);
  };

  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      //TODO: test
      const response = await axios.post(
        endpoint.ADMIN_DELET_USER_ENDPOINT, //endpoint
        { email: state.email, id: state.id },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          // popMyTenders(data);
          navigate("/SENTECHUSERS");
          setMessage("User successfully Deleted");
          setSeverity("success");
          setLoading(false);
        } else {
          setMessage(userManagementError(response.data.errorCode));
          setSeverity("error");
          openSnackbar();
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    // console.log(
    //   JSON.stringify({
    //     id: state.id,
    //     first_name: name,
    //     last_name: surname,
    //     employee_ref: employeeReference,
    //     role: Object.keys(checkbox).filter((key) => checkbox[key]),
    //     department: department,
    //     designation: designation,
    //   })
    // );
    setDisabled(true);
    try {
      const response = await axios.post(
        endpoint.ADMIN_EDIT_USER_ENDPOINT, //endpoint
        {
          id: state.id,
          first_name: name,
          last_name: surname,
          employee_ref: employeeReference,
          role: Object.keys(checkbox).filter((key) => checkbox[key]),
          department: department,
          designaion: designation,
        },
        {
          headers: {
            "user-token": localStorage.getItem("user_token"),
            authorization: localStorage.getItem("authorization"),
          },
        }
      );
      //   const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          setMessage("User updated successfully");
          setSeverity("success");
          openSnackbar();
          setTimeout(() => {
            navigate("/SENTECHUSERS");
          }, 2000);
        } else {
          setMessage("User not updated successfully");
          setSeverity("error");
          openSnackbar();
        }
      } else {
      }
    } catch (error) {}
  };

  return (
    <Box>
      <Box className={classes.breadcrumbs}>
        <BreadCrumb />
      </Box>      
      <Box className={classes.heading}>
        {disabled ? (
          <>
          <Typography style={{ fontSize: "24px" }}>VIEW USER DETAILS</Typography>
          </>
        ) : (
          <>
            <Typography style={{ fontSize: "24px" }}>EDIT USER DETAILS</Typography>
          </>
        )}
      </Box>
      <Box>
        <form>
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={name}
            onChange={handleInputChange}
            label="Name:"
            required
            name="name"
            type="text"
          />
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={surname}
            onChange={handleInputChange}
            label="Surname:"
            required
            name="surname"
            type="text"
          />
          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={employeeReference}
            onChange={handleInputChange}
            label="Employee Reference:"
            required
            name="employeeReference"
            type="text"
          />

          <CustomInput
            width={labelWidth}
            disabled
            value={email}
            onChange={handleInputChange}
            label="Email:"
            required
            name="email"
            type="text"
          />

          <CustomInput
            width={labelWidth}
            disabled={disabled}
            onChange={handleInputChange}
            value={department}
            label="Department:"
            name="department"
            required
            type="text"
          />

          <CustomInput
            width={labelWidth}
            disabled={disabled}
            value={designation}
            onChange={handleInputChange}
            label="Designation:"
            required
            name="designation"
            type="text"
          />
          <Box className={classes.role}>
            <Typography style={{ color: sentechDarkGrey, width: labelWidth }}>
              Role Assigned:
            </Typography>
            <Box style={{ width: "20%" }} />
          </Box>
          {/* role checkboxes */}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              // paddingLeft: "6%",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            <Box style={{ width: labelWidth }} />
            <Box className={classes.checkboxes}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleCheckboxChange}
                      checked={checkbox.admin}
                      name="admin"
                      // defaultChecked={checkbox.admin}
                      disabled={disabled}
                    />
                  }
                  label="Sys Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleCheckboxChange}
                      checked={checkbox.scm}
                      name="scm"
                      // defaultChecked={checkbox.scm}
                      disabled={disabled}
                    />
                  }
                  label="SCM Official"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={handleCheckboxChange}
                      checked={checkbox.bsec}
                      name="bsec"
                      // defaultChecked={checkbox.bsec}
                      disabled={disabled}
                    />
                  }
                  label="BSEC Member"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box className={classes.newuserbuttonbox1}>
            {disabled ? (
              <>
                <CustomButtonOutlined
                  className={classes.customcancelbutton}
                  onClick={handleCancel}
                  text="Cancel"
                />
                <CustomButtonSolid
                  onClick={handleDisabled}
                  text="Edit User Details"
                />
              </>
            ) : (
              <>
                <CustomButtonOutlined
                  onClick={handleEditCancel}
                  text="Cancel"
                />
                <CustomButtonSolid
                  onClick={handleOnSubmit}
                  text="Save Changes"
                  disabled={
                    !name ||
                    !surname ||
                    !employeeReference ||
                    !email ||
                    !department ||
                    !designation ||
                    !(checkbox.admin || checkbox.scm || checkbox.bsec)
                  }
                />
                <CustomButtonSolid
                  onClick={handleDeleteDialogOpen}
                  text="Delete User"
                />
                <DeleteUserDialog
                  open={openDelete}
                  handleClose={handleDeleteDialogClose}
                  onDeleteClicked={handleDeleteUser}
                />
              </>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ViewUser;
