import { useState, useEffect, useContext } from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoint } from "../../endpoint/endpoints";
import { ApplicationContext } from "../../context/ApplicationContext";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CustomInput from "../inputFields/Inputfields";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import {
  ValidateEmail,
  ValidatePassword,
} from "../inputValidators/inputValidators";
import CustomButtonSolid from "../layout/buttons/solidButton";

const apiResponse = {
  status: 200,
  data: {
    error: false,
    message: {
      id: 12345,
      email: "younos@aizatron.com",
      first_name: "younos",
      last_name: "mohamed",
      company_phone: "+083915818",
      csd_number: "csd123",
      company_name: "Aizatron",
      reg_number: "12345",
      role: "bidder",
      employeeReference: "SENT0001234",
      department: "Admin",
      designation: "Manager",
      authorization_token: "EYauth",
      access_token: "EYacc",
      refresh_token: "Eyrefresh",
    },
  },
};

// styling
const useStyles = makeStyles((theme) =>
  createStyles({
    login: {
      textAlign: "center",
    },

    form: {
      paddingTop: "5%",
      alignItems: "center",
    },

    buttonbox: {
      paddingTop: "2%",
      paddingBottom: "2%",
    },

    forgotpassword: {
      paddingBottom: "5%",
    },
  })
);

function Login() {
  // material ui
  const classes = useStyles();

  //page title seen on browser
  useEffect(() => {
    document.title = "Login";
  }, []);

  /* application context */
  const { popAuthState, setLoading, setMessage, setSeverity, openSnackbar } =
    useContext(ApplicationContext);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  /* navigate */
  const navigate = useNavigate();

  // State variables
  const [username, setUsername] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });
  const [password, setPassword] = useState({
    value: "",
    error: false,
    errorMessage: "",
  });

  /* functions */
  const handleInputChange = (event) => {
    let error = { error: "", errorMessage: "" };
    switch (event.target.name) {
      case "username":
        error = ValidateEmail(event.target.value);
        setUsername((prevState) => {
          return {
            value: event.target.value,
            error: error.error,
            errorMessage: error.errorMessage,
          };
        });
        break;

      case "password":
        error = ValidatePassword(event.target.value);
        setPassword((prevState) => {
          return {
            value: event.target.value,
            error: false,
            errorMessage: "",
          };
        });
        break;

      default:
        break;
    }
  };

  /* backend code */
  const onResendOTP = async () => {
    const response = await axios.post(
      endpoint.RESEND_OTP_ENDPOINT, //endpoint
      { email: username.value }
      // {
      //   headers: {
      //     "user-token": localStorage.getItem("user_token"),
      //     authorization: localStorage.getItem("authorization"),
      //   },
      // }
    );
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(
        endpoint.SIGN_IN_ENDPOINT, //endpoint
        { email: username.value, password: password.value } //body
      );
      // const response = apiResponse;
      if (response.status === 200) {
        if (response.data.error === false) {
          const message = response.data.message;
          localStorage.setItem("authorization", message.authorization_token);
          localStorage.setItem("user_token", message.access_token);
          localStorage.setItem("refresh_token", message.refresh_token);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("id", message.id);

          // bidder case
          if (message.role[0] === "bidder") {
            popAuthState({
              isLoggedIn: true,
              id: message.id,
              email: message.email,
              firstName: message.first_name,
              lastName: message.last_name,
              companyPhone: message.company_phone,
              csd: message.csd_number,
              companyName: message.company_name,
              reg: message.reg_number,
              companyMobile: message.company_mobile_phone,
              role: "bidder", //message.role,
            });
            localStorage.setItem("bidderRole", true);
            navigate("/OPENTENDERS");
          } else {
            popAuthState({
              isLoggedIn: true,
              id: message.id,
              email: message.email,
              firstName: message.first_name,
              lastName: message.last_name,
              employeeRef: message.employee_ref,
              designation: message.designaion,
              department: message.department,
              role: message.role,
              //fil other sentech user details
            });

            //scm case
            if (message.role.includes("scm")) {
              localStorage.setItem("scmRole", true);
              navigate("/AVAILABLETENDERS");
            }

            //bsec case
            if (message.role.includes("bsec")) {
              localStorage.setItem("bsecRole", true);
              navigate("/AVAILABLETENDERS");
            }

            //sysadmin case
            if (message.role.includes("admin")) {
              localStorage.setItem("adminRole", true);
              navigate("/DASHBOARD");
            }
          }
          setLoading(false);
        } else {
          switch (response.data.errorCode) {
            //missing input fields
            case 0:
              setMessage("Missing input fields");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Invalid Email Password combination
            case 1:
              setMessage("Email or Password incorrect");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Invalid Username Password combination
            case 2:
              setMessage("Invalid Username or Password combination");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to query user details table
            case 3:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            //Unable to sign user tokens
            case 4:
              setMessage("Email not confirmed, please confirm now");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              popAuthState({
                email: username.value,
                password: password.value,
                //fil other sentech user details
              });
              onResendOTP();
              navigate("/OTP");
              break;
            //Unable to structure output
            case 5:
              setMessage("An error occurred. Please try again later");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
            default:
              setMessage("Unexpected error occured");
              setSeverity("error");
              openSnackbar();
              setLoading(false);
              break;
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setMessage("Unexpected error occured");
      setSeverity("error");
      openSnackbar();
      setLoading(false);
    }
  };

  return (
    <Box className={classes.login}>
      <Box className={classes.form}>
        <form onSubmit={onSubmit}>
          <CustomInput
            width={"150px"}
            focused={true}
            value={username.value}
            onChange={handleInputChange}
            label="Email/Username:"
            required
            name="username"
            type="text"
            inputProps={{
              autoComplete: "email",
            }}
            error={username.error}
            errorMessage={username.errorMessage}
          />
          <CustomInput
            width={"150px"}
            label="Password:"
            value={password.value}
            required
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={handleInputChange}
            inputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <Visibility fontSize="small" />
                    ) : (
                      <VisibilityOff fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={password.error}
            errorMessage={password.errorMessage}
          />
          <Box className={classes.buttonbox}>
            <CustomButtonSolid
              type="submit"
              text="LOGIN"
              disabled={
                password.error ||
                username.error ||
                !username.value ||
                !password.value
              }
            />
          </Box>
        </form>
      </Box>
      <Box>
        <Typography>
          Don't have an account?{" "}
          <Link
            onClick={() => {
              navigate("/signUp");
            }}
          >
            Sign up
          </Link>
        </Typography>

        <Typography className={classes.forgotpassword}>
          {" "}
          <Link
            onClick={() => {
              navigate("/forgotPassword");
            }}
          >
            Forgot password
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}

export default Login;
